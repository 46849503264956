import urls from "@/api/config/urls";
import ApiCertificate from "@/api/types/signature/apiCertificate";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class CryptoproController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	/**
	 * @param signature Подпись в base64
	 */
	getSignerCertificate = async (signature: string): Promise<ApiCertificate[]> => {
		return await this.client.post<any>(urls.cryptopro.getSignerCertificates, {
			signature
		});
	};
}

<template>
	<div class="flex-grow-1">
		<div v-if="label" class="text-subtitle-2 primary--text text--darken-1">{{ label }}</div>
		<v-row>
			<v-progress-circular v-if="loading" class="mx-auto" indeterminate></v-progress-circular>
			<template v-else>
				<v-col v-for="(items, i) in chunkedItems"
					   class="frp-checkbox-group d-flex flex-column py-2"
					   :style="`gap: ${gap}px`"
					   :key="i">
					<v-checkbox v-for="(item, j) in items"
								:key="j"
								:disabled="disabled"
								:input-value="checkItemSelected(item)"
								@change="update($event, item)"
								hide-details
								:style="itemWrapperStyle"
								class="ml-n1 align-center"
								:color="color">
						<template #label>
						<span class="text-body-1 pl-2"
							  :class="[{ 'text-decoration-underline': underline }, textColor]"
							  :style="itemStyle">
							<slot name="item" :item="item">{{ item.text }}</slot>
						</span>
						</template>
					</v-checkbox>
				</v-col>
			</template>
		</v-row>
	</div>
</template>

<script>
import { balancedChunk } from "Utils/array";

export default {
	model: {
		prop: "values",
		event: "update:values"
	},
	props: {
		items: Array,
		disabled: Boolean,
		underline: Boolean,
		values: Array,
		label: String,
		itemStyle: String,
		itemWrapperStyle: String,
		textColor: {
			type: String,
			default: "primary--text text--darken-1"
		},
		color: {
			type: String,
			default: "secondary"
		},
		columns: {
			type: [Number, String],
			default: 1
		},
		gap: {
			type: [Number, String],
			default: 24
		},
		loading: Boolean
	},
	computed: {
		chunkedItems() {
			return balancedChunk(this.items, this.chunksCount);
		},
		chunksCount() {
			if(this.$vuetify.breakpoint.lgAndUp)
				return 5 > this.columns ? this.columns : 5;
			if(this.$vuetify.breakpoint.mdOnly)
				return 4 > this.columns ? this.columns : 4;
			if(this.$vuetify.breakpoint.smOnly)
				return 2 > this.columns ? this.columns : 2;
			if(this.$vuetify.breakpoint.xsOnly)
				return 1;
		}
	},
	methods: {
		checkItemSelected({ value }) {
			return this.values.includes(value);
		},
		update(event, { value }) {
			this.$emit("update:values", event ? [...this.values, value] : this.values.filter(x => x !== value));
		}
	}
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.frp-checkbox-group {
	.v-input--is-disabled .v-input__slot {
		background-color: transparent !important;
	}

	.v-input--checkbox {
		margin-top: 0;
	}

	.v-icon {
		color: var(--v-primary-darken1);
	}

	.v-input__slot {
		padding: 0 !important;
	}

	@media #{map-get($display-breakpoints, 'sm-and-up')} {
		label {
			white-space: nowrap;
		}
	}
}
</style>

<template>
	<v-skeleton-loader :height="height" :max-width="width" :type="type" :class="classes"/>
</template>

<script>
export default {
	props: {
		height: {
			type: [String, Number],
			default: 18
		},
		width: {
			type: [String, Number],
			default: 250
		},
		type: {
			type: String,
			default: "image"
		},
		classes: {
			type: String,
			default: "rounded-lg"
		}
	}
};
</script>

<style scoped>

</style>

import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "treasury-statement";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	payments: "payments",
	borrowers: "borrowers"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries",
	fetchProjectStatus: "fetchProjectStatus",
	handleUploadTreasuryStatement: "handleUploadTreasuryStatement",
	handleTreasuryStatement: "handleTreasuryStatement",
	createItem: "createItem",
	updateItem: "updateItem",
	deleteItem: "deleteItem",
	updateRecordStatus: "updateRecordStatus",
	cancelTreasuryStatementImport: "cancelTreasuryStatementImport",
	updateRecordProject: "updateRecordProject"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	SET_PAYMENT_EXPENSES: "SET_PAYMENT_EXPENSES",
	SET_ALL_FINANCE_SOURCES: "SET_ALL_FINANCE_SOURCES",
	SET_TREASURY_STATEMENT_OPERATION_TYPES: "SET_TREASURY_STATEMENT_OPERATION_TYPES",
	SET_PAYMENTS_FILTER_TYPE: "SET_PAYMENTS_FILTER_TYPE",
	SET_FILE_DATA: "SET_FILE_DATA",
	DELETE_FILE_DATA: "DELETE_FILE_DATA",
	SET_FILE_IS_LOADING: "SET_FILE_IS_LOADING",
	SET_FILE_ID: "SET_FILE_ID",
	SET_IS_TREASURY_STATEMENT_PROCESSING: "SET_IS_TREASURY_STATEMENT_PROCESSING",
	SET_IS_PROJECT_STATUS_FETCHING: "SET_IS_PROJECT_STATUS_FETCHING",
	SET_EDITABLE_ITEMS: "SET_EDITABLE_ITEMS",
	ADD_SAVING_ITEM: "ADD_SAVING_ITEM",
	REMOVE_SAVING_ITEM: "REMOVE_SAVING_ITEM",
	ADD_EDITABLE_ITEM: "ADD_EDITABLE_ITEM",
	REMOVE_EDITABLE_ITEM: "REMOVE_EDITABLE_ITEM",
	SET_RECORD_PAYMENTS: "SET_RECORD_PAYMENTS",
	SET_RECORD_PROJECT: "SET_RECORD_PROJECT",
	SET_RECORD_PROJECT_STATUS: "SET_RECORD_PROJECT_STATUS",
	SET_RECORD_IS_FINAL: "SET_RECORD_IS_FINAL",
	RESET_NEW_ITEM: "RESET_NEW_ITEM",
	SET_NEW_ITEM_RECORD_ID: "SET_NEW_ITEM_RECORD_ID",
	SET_NEW_ITEM_FINANCING_SOURCE_ID: "SET_NEW_ITEM_FINANCING_SOURCE_ID",
	SET_NEW_ITEM_QUARTER_ID: "SET_NEW_ITEM_QUARTER_ID",
	SET_NEW_ITEM_PAYMENT_EXPENSE_ID: "SET_NEW_ITEM_PAYMENT_EXPENSE_ID",
	SET_NEW_ITEM_AMOUNT: "SET_NEW_ITEM_AMOUNT",
	SET_IS_ITEM_DELETING: "SET_IS_ITEM_DELETING",
	SET_EDITABLE_PROJECT_RECORDS: "SET_EDITABLE_PROJECT_RECORDS",
	ADD_EDITABLE_PROJECT_RECORD: "ADD_EDITABLE_PROJECT_RECORD",
	REMOVE_EDITABLE_PROJECT_RECORD: "REMOVE_EDITABLE_PROJECT_RECORD",
	SET_BORROWERS: "SET_BORROWERS",
	ADD_SAVING_PROJECT_RECORD: "ADD_SAVING_PROJECT_RECORD",
	REMOVE_SAVING_PROJECT_RECORD: "REMOVE_SAVING_PROJECT_RECORD"
};

const treasuryStatementTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default treasuryStatementTypes;

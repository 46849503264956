export default class ApiBodAcceptorBase {
	title: string;
	type: string;
	acceptedAt: string;
	declinedAt: string;
	declineReason: string;

	constructor(
		title: string = "",
		type: string = "",
		acceptedAt: string = "",
		declinedAt: string = "",
		declineReason: string = ""
	)
	{
		this.title = title;
		this.type = type;
		this.acceptedAt = acceptedAt;
		this.declinedAt = declinedAt;
		this.declineReason = declineReason;
	}
}

import NotDefinedException from "@/exceptions/notDefinedException";
import { baseActionTypes } from "@/store/shared/base/types";
import { mapInstanceActions, mapInstanceState } from "@/utils/vuexMapInstanse";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import { RouteNames } from "@/router/routes";

const beforeRouteLeave = async function (to: Route, from: Route, next: NavigationGuardNext) {
	// Видимо, осталось после переноса кода из другого хука. В BeforRouteLeave это и ток невозможно
	let shouldStoreBeDestroyed = to.name !== from.name;

	// На Root настроен редирект на дефолтные страницы, которые зависят от типа и роли пользователя
	// Может возникнуть сценарий А-Root-A, в таком случае будет вызван destroy, а инициализация нет
	if(to.name === RouteNames.ROOT) {
		shouldStoreBeDestroyed = false;
	}

	if(shouldStoreBeDestroyed) {
		// @ts-ignore
		await this.destroyStore();
	}

	return next();
};

const storeModuleBasedPage = {
	computed: {
		...mapInstanceState({
			isStateInitializedInternal: (state: { isInitialized: boolean }) => state.isInitialized
		})
	},
	methods: {
		...mapInstanceActions({
			initializeStoreInternal: baseActionTypes.initialize,
			destroyStore: baseActionTypes.destroy
		}),
		async initializeStore() {
			if(this.isStateInitializedInternal)
				return;

			await this.initializeStoreInternal.apply(this, arguments);
		},
		beforeRouteLeave
	},
	beforeRouteLeave,
	async beforeDestroy() {
		// @ts-ignore
		if(this.isStateInitializedInternal) {
			// @ts-ignore
			await this.destroyStore();
		}

	},
	created() {
		// @ts-ignore
		if(!this.namespace)
			throw new NotDefinedException("namespace");
	}
};

export default storeModuleBasedPage;

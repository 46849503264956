export default class ApiBodPublicFile {
	link: string;
	title: string;
	contentType: string;
	size: number;

	constructor(
		link: string = "",
		title: string = "",
		contentType: string = "",
		size: number = 0
	)
	{
		this.link = link;
		this.title = title;
		this.contentType = contentType;
		this.size = size;
	}
}

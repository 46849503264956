<template>
	<frp-dialog :title="title" v-model="internalValue" persistent>
		<template v-slot:activator="{ on, attrs }">
			<template v-if="(mode === DigitalSignatureDialogModeType.SIGN && !payload) && !$scopedSlots['activator']">
				<frp-btn v-if="signed"
						 outlined
						 color="primary"
						 disabled>
					<v-icon
						small
						left>
						mdi-usb-flash-drive-outline
					</v-icon>
					{{ $t("buttons.signed") }}>
				</frp-btn>
				<frp-btn v-else
						 color="primary"
						 v-on="on"
						 v-bind="attrs"
						 outlined>
					<v-icon
						small
						left>
						mdi-usb-flash-drive-outline
					</v-icon>
					{{ $t("buttons.sign") }}
				</frp-btn>
			</template>
			<slot name="activator" v-bind="{attrs, on}" v-else></slot>
		</template>
		<template v-slot:content="{ onIntersect }">
			<template v-if="isCertificatesInitializing">
				<frp-digital-signature-certificate-loader :color="color"></frp-digital-signature-certificate-loader>
			</template>
			<template v-else-if="certificates.length">
				<v-radio-group v-model="selected" hide-details v-intersect="onIntersect" class="mt-0">
					<frp-digital-signature-certificate v-for="(certificate, i) of certificates"
													   :disabled="isSigning || isCertificateSelecting"
													   :certificate="certificate"
													   :value="certificate.thumbprint"
													   :key="i"
													   :color="color"
													   @select="selectCertificate">
					</frp-digital-signature-certificate>
				</v-radio-group>
			</template>
			<template v-else>
				<v-responsive height="220">
					<v-container class="fill-height">
						<v-row align="center">
							<v-col cols="12" class="d-flex justify-center align-center flex-column">
								<frp-icon src="ico_empty-data"></frp-icon>
								<span class="text-caption mt-3">{{ $t("alerts.info.noData") }}</span>
							</v-col>
						</v-row>
					</v-container>
				</v-responsive>
			</template>
		</template>

		<template #footer>
			<frp-btn v-if="!isCertificatesInitializing"
					 :disabled="isSigning || isCertificateSelecting"
					 outlined
					 @click="cancel"
					 color="primary">
				{{ cancelBtn || $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn v-if="!isCertificatesInitializing"
					 :loading="isSigning || isCertificateSelecting"
					 :disabled="!selectedCertificate"
					 @click="submit"
					 :color="color">
				<span class="white--text">
					{{
						submitBtn || (mode === DigitalSignatureDialogModeType.SIGN ?
							$t("buttons.sign") :
							$t("buttons.choose"))
					}}
				</span>
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import * as ls from "local-storage";
import { LAST_SELECTED_SIGNATURES } from "@/constants/localStorage";
import DigitalSignatureSuggestionService from "@/services/digitalSignatureSuggestion/digitalSignatureSuggestionService";
import { CryptoproController } from "Api/cryptopro";
import FrpDigitalSignatureCertificate from "Components/digitalSignature/FrpDigitalSignatureCertificate";
import FrpDigitalSignatureCertificateLoader from "Components/digitalSignature/FrpDigitalSignatureCertificateLoader";
import FrpIcon from "Components/icon/FrpIcon";
import FrpDialog from "Components/dialogs/FrpDialog";
import { getUserCertificates } from "crypto-pro";
import { parseISO } from "date-fns";
import SignException from "Exceptions/signException";
import colorsMixin from "Mixins/colorsMixin";
import AbortService from "Services/abortService";
import alertService from "Store/modules/alerts/services/alertService";
import FileMeta from "Store/shared/storage/types/fileMeta";
import { formatDateInterval } from "Utils/dates";
import { checkDigitalSignatureAlgorithm } from "Utils/digitalSignature";
import { prepareSignatureName, signFileWithDetachedSignature, signWithDetachedSignature } from "Utils/signature";
import { requiredRule } from "Utils/validation";

const abortService = new AbortService();
const cryptoproController = new CryptoproController(abortService);

const digitalSignatureSuggestionService = new DigitalSignatureSuggestionService();

export const DigitalSignatureDialogModeType = {
	SIGN: "SIGN",
	SELECT_CERTIFICATE: "SELECT_CERTIFICATE"
};

export default {
	mixins: [colorsMixin],
	props: {
		dataUrl: {
			type: String
		},
		color: {
			type: String,
			default: "secondary"
		},
		buttonElevation: {
			type: [String, Number],
			default: "undefined"
		},
		file: {
			type: File
		},
		blob: Blob,
		mode: {
			default: DigitalSignatureDialogModeType.SIGN
		},
		signed: {
			type: Boolean,
			default: false
		},
		signature: String,
		description: String,
		cancelBtn: String,
		submitBtn: String,
		meta: {
			type: FileMeta
		},
		value: {
			type: Boolean,
			default: false
		},
		payload: {
			type: [Object, String],
			default: null
		},
		//TODO: убрать хардкод c файлом ниже
		pdf: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			DigitalSignatureDialogModeType,
			validation: {
				certificates: [requiredRule()]
			},
			certificates: [],
			isSigning: false,
			isCertificateSelecting: false,
			isCertificatesInitializing: false,
			selected: undefined
		};
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			}
		},
		title() {
			if(this.isCertificatesInitializing)
				return this.$t("titles.appealToDigitalSignature");

			return this.$t("titles.chooseDigitalSignature");
		},
		selectedCertificate() {
			return this.certificates.find(x => x.isSelected);
		},
		isSignEnabled() {
			return !!this.dataUrl;
		}
	},
	methods: {
		async submit() {
			if(this.mode === DigitalSignatureDialogModeType.SIGN) {
				await this.sign();
			} else if(this.mode === DigitalSignatureDialogModeType.SELECT_CERTIFICATE) {

				try {
					this.isCertificateSelecting = true;

					let { signature } = await signWithDetachedSignature({ signedAt: Date.now() },
						this.selectedCertificate.thumbprint);

					let certificates;
					try {
						certificates = await cryptoproController.getSignerCertificate(signature);
					} catch (e) {
						throw new SignException(e.message);
					}

					if(certificates.length !== 1) {
						console.error(`${this.$t("common.certificatesLength")}: ${certificates.length}`);
					} else {
						let [certificate] = certificates;

						this.$emit("certificate:selected", certificate);
					}

					this.closeDialog();
				} catch (e) {
					console.error(e);
					if(e instanceof SignException)
						alertService.addCustomError(e.message);
				} finally {
					this.isCertificateSelecting = false;
				}
			}
		},
		async sign() {
			this.isSigning = true;

			try {
				if(this.payload) {
					let result = await signWithDetachedSignature(this.payload, this.selectedCertificate.thumbprint);

					this.$emit("signed", result);
				} else if(this.blob) {
					let { signature } = await signFileWithDetachedSignature(this.blob, this.selectedCertificate.thumbprint);

					this.$emit("signed", signature);
				} else if(this.file) {
					const { thumbprint } = this.selectedCertificate;

					let { data } = await signFileWithDetachedSignature(this.file, thumbprint);

					let blob = new Blob([data]);

					//TODO: убрать хардкод c файлом ниже
					let file;

					if(this.pdf) {
						file = new File([blob], prepareSignatureName(this.file.name), { type: "pdf" });
					} else {
						file = new File([blob], prepareSignatureName(this.file.name));
					}

					this.$emit("signed", file);
				}
			} catch (e) {
				console.error(e);
				if(e instanceof SignException)
					alertService.addCustomError(e.message);
			} finally {
				this.isSigning = false;
				this.closeDialog();
			}
		},
		selectCertificate(thumbprint) {
			digitalSignatureSuggestionService.add(LAST_SELECTED_SIGNATURES, thumbprint);

			for (let certificate of this.certificates) {
				certificate.isSelected = certificate.thumbprint === thumbprint;
			}
		},
		cancel() {
			this.$emit("cancel");
			this.closeDialog();
		},
		closeDialog() {
			this.$emit("input", false);
		},
		async initializeCertificates() {
			this.isCertificatesInitializing = true;

			try {
				let certificates = [];

				for await (let certificate of await getUserCertificates()) {
					if(!await checkDigitalSignatureAlgorithm(certificate))
						continue;

					// TODO Будут ошибки, решить после публикации собственной версии crypto-pro
					const DS_CERTIFICATE_ISSUER = this.$t("content.verificationCenter");
					const DS_CERTIFICATE_OWNER = this.$t("content.owner");

					let issuer = (await certificate.getIssuerInfo()).find(x => x.title === DS_CERTIFICATE_ISSUER);
					let owner = (await certificate.getOwnerInfo()).find(x => x.title === DS_CERTIFICATE_OWNER);

					certificates.push({
						thumbprint: certificate.thumbprint,
						isSelected: false,
						issuer: issuer.description,
						owner: owner.description,
						title: certificate.name,
						validPeriod: formatDateInterval(parseISO(certificate.validFrom), parseISO(certificate.validTo))
					});
				}


				const digitalSignatureSuggestions = ls.get(LAST_SELECTED_SIGNATURES) || [];
				if(digitalSignatureSuggestions) {
					this.certificates = certificates.sort((a, b) => {
						if(digitalSignatureSuggestions.indexOf(a.thumbprint) === -1)
							return 1;

						if(digitalSignatureSuggestions.indexOf(b.thumbprint) === -1)
							return -1;

						return digitalSignatureSuggestions.indexOf(a.thumbprint) <
						digitalSignatureSuggestions.indexOf(b.thumbprint) ? -1 : 1;
					});
				} else {
					this.certificates = certificates;
				}

			} catch (error) {
				console.error(error);
			} finally {
				this.isCertificatesInitializing = false;
			}
		}
	},
	watch: {
		value: {
			async handler(value) {
				if(value) {
					abortService.initialize();
					await this.initializeCertificates();
				}
			},
			immediate: true
		}
	},
	components: {
		FrpBtn,
		FrpDigitalSignatureCertificate,
		FrpDigitalSignatureCertificateLoader,
		FrpIcon,
		FrpDialog
	}
};
</script>

export const namespace = "tranches-breadcrumbs";

export const getterTypes = {};

export const actionTypes = {
	processRoute: "processRoute",
	resolveBreadcrumb: "resolveBreadcrumb"
};

export const mutationTypes = {
	SET_ITEMS: "SET_ITEMS",
	SET_IS_LOADING: "SET_IS_LOADING"
};

const barBreadcrumbsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default barBreadcrumbsTypes;

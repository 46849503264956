import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "accrued-interest";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries",
	massUpdate: "massUpdate",
	updateItem: "updateItem",
	addItems: "addItems",
	deleteItem: "deleteItem",
	submitExtraRateParameters: "submitExtraRateParameters"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_ITEM_IS_ACTIVE: "SET_ITEM_IS_ACTIVE",
	RESET_ADD_ITEMS_PARAMETERS: "RESET_ADD_ITEMS_PARAMETERS",
	RESET_EDITABLE_ITEMS: "RESET_EDITABLE_ITEMS",
	ADD_EDITABLE_ITEM: "ADD_EDITABLE_ITEM",
	REMOVE_EDITABLE_ITEM: "REMOVE_EDITABLE_ITEM",
	SET_ADD_ITEMS_PARAMETERS_ACCRUAL_TYPE_ID: "SET_ADD_ITEMS_PARAMETERS_ACCRUAL_TYPE_ID",
	SET_ADD_ITEMS_PARAMETERS_FINANCING_SOURCE_ID: "SET_ADD_ITEMS_PARAMETERS_FINANCING_SOURCE_ID",
	SET_ADD_ITEMS_PARAMETERS_QUARTER_ID: "SET_ADD_ITEMS_PARAMETERS_QUARTER_ID",
	SET_ADD_ITEMS_PARAMETERS_ACCRUAL_START_DATE: "SET_ADD_ITEMS_PARAMETERS_ACCRUAL_START_DATE",
	SET_ADD_ITEMS_PARAMETERS_ACCRUAL_END_DATE: "SET_ADD_ITEMS_PARAMETERS_ACCRUAL_END_DATE",
	SET_ADD_ITEMS_PARAMETERS_PAYMENT_DATE: "SET_ADD_ITEMS_PARAMETERS_PAYMENT_DATE",
	SET_ADD_ITEMS_PARAMETERS_ACCRUED_AMOUNT: "SET_ADD_ITEMS_PARAMETERS_ACCRUED_AMOUNT",
	RESET_MASS_UPDATE_PARAMETERS: "RESET_MASS_UPDATE_PARAMETERS",
	SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_ID: "SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_ID",
	SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_FILTER_ENABLED: "SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_FILTER_ENABLED",
	SET_MASS_UPDATE_PARAMETERS_FINANCE_SOURCE_ID: "SET_MASS_UPDATE_PARAMETERS_FINANCE_SOURCE_ID",
	SET_MASS_UPDATE_PARAMETERS_SOURCE_FILTER_ENABLED: "SET_MASS_UPDATE_PARAMETERS_SOURCE_FILTER_ENABLED",
	SET_MASS_UPDATE_PARAMETERS_QUARTER_ID: "SET_MASS_UPDATE_PARAMETERS_QUARTER_ID",
	SET_MASS_UPDATE_PARAMETERS_QUARTER_FILTER_ENABLED: "SET_MASS_UPDATE_PARAMETERS_QUARTER_FILTER_ENABLED",
	SET_MASS_UPDATE_PARAMETERS_ACCRUAL_START_DATE: "SET_MASS_UPDATE_PARAMETERS_ACCRUAL_START_DATE",
	SET_MASS_UPDATE_PARAMETERS_ACCRUAL_END_DATE: "SET_MASS_UPDATE_PARAMETERS_ACCRUAL_END_DATE",
	SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE: "SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE",
	SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE_SETTING_ENABLED: "SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE_SETTING_ENABLED",
	SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT: "SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT",
	SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT_SETTING_ENABLED: "SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT_SETTING_ENABLED",
	SET_QUARTERS: "SET_QUARTERS",
	SET_FINANCE_SOURCES: "SET_FINANCE_SOURCES",
	SET_ACCRUAL_TYPES: "SET_ACCRUAL_TYPES",
	ADD_SAVING_ITEM: "ADD_SAVING_ITEM",
	REMOVE_SAVING_ITEM: "REMOVE_SAVING_ITEM",
	SET_IS_ITEM_DELETING: "SET_IS_ITEM_DELETING",
	RESET_FILTER: "RESET_FILTER",
	SET_FILTER_ACCRUAL_START_DATE: "SET_ACCRUAL_START_DATE",
	SET_FILTER_ACCRUAL_END_DATE: "SET_ACCRUAL_END_DATE",
	SET_FILTER_ACCRUAL_TYPE: "SET_ACCRUAL_TYPE",
	SET_FILTER_PAYMENT_START_DATE: "SET_PAYMENT_START_DATE",
	SET_FILTER_PAYMENT_END_DATE: "SET_PAYMENT_END_DATE",
	SET_FILTER_ACCOUNTED_START_DATE: "SET_FILTER_ACCOUNTED_START_DATE",
	SET_FILTER_ACCOUNTED_END_DATE: "SET_FILTER_ACCOUNTED_END_DATE",
	SET_FILTER_FINANCING_SOURCE_ID: "SET_FINANCING_SOURCE_ID",
	SET_FILTER_QUARTER_ID: "SET_FILTER_QUARTER_ID",
	RESET_EXTRA_RATE_PARAMETERS: "RESET_EXTRA_RATE_PARAMETERS",
	SET_EXTRA_RATE_PARAMETERS_ACCRUAL_START_DATE: "SET_EXTRA_RATE_PARAMETERS_ACCRUAL_START_DATE",
	SET_EXTRA_RATE_PARAMETERS_ACCRUAL_END_DATE: "SET_EXTRA_RATE_PARAMETERS_ACCRUAL_END_DATE",
	SET_EXTRA_RATE_PARAMETERS_EXTRA_INTEREST_RATE: "SET_EXTRA_RATE_PARAMETERS_EXTRA_INTEREST_RATE",
	SET_EXTRA_RATE_PARAMETERS_PAYMENT_DATE: "SET_EXTRA_RATE_PARAMETERS_PAYMENT_DATE",
	SET_AGREEMENT: "SET_AGREEMENT",
	SET_TOTAL_AMOUNT: "SET_TOTAL_AMOUNT",
	SET_BORROWERS: "SET_BORROWERS"
};

const accruedInterestTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default accruedInterestTypes;

<template>
	<frp-card v-if="isInitialized" :title="$t('titles.generation')" class="pb-4">
		<template #content>
			<tranches-card-content-layout>
				<v-row>
					<v-col class="pt-0">
						<span class="text-body-2 font-weight-medium primary--text">
							{{ $t("common.trancheScheduleGenerateInfo.part1") }}
						</span>
						<frp-link-btn target="_blank" :href="lkProjectLink" :color="colors.blue.base" classes="d-inline-block">
							{{ $t("common.trancheScheduleGenerateInfo.part2") }}
						</frp-link-btn>
						<span>.</span>
					</v-col>
					
					<v-col cols="auto" class="pt-0">
						<frp-btn class="ml-2"
								 elevation="0"
								 dark
								 :loading="isTrancheScheduleGenerating"
								 @click="handleGenerateTrancheScheduleFile"
								 :color="colors.blue.base">
							{{ $t("buttons.generate") }}
						</frp-btn>
					</v-col>
				</v-row>
			</tranches-card-content-layout>
			
			<frp-dialog v-model="isTrancheScheduleGeneratingDialogOpened" :title="$t('dialogs.trancheScheduleGenerating.title')"
						max-width="568">
				<template #content="{ onIntersect }">
					<v-container v-intersect="onIntersect" class="pt-0">
						<v-row>
							<v-col class="px-0">
								<span class="text-body-2 font-weight-medium grey--text">
									{{ $t("common.trancheScheduleGenerateInfoPersonalAccount") }}
								</span>
							</v-col>
						</v-row>
						<v-row class="d-flex justify-space-between">
							<v-col cols="9" class="px-0">
								<div class="d-flex align-center">
									<frp-icon src="ico_file_bar" :color="colors.primary.darken1" class="mr-3"></frp-icon>
									<span class="text-body-2 py-2" style="color: var(--v-primary-darken1)">
										{{ $t("common.trancheScheduleGeneratedFileName") }}
									</span>
								</div>
							</v-col>
							<v-col cols="auto" justify="end" class="px-0">
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 :disabled="!trancheScheduleFile && !trancheScheduleFile?.name"
										 @click="saveGeneratedTrancheScheduleFile">
									{{ $t("buttons.download") }}
								</frp-btn>
							</v-col>
						</v-row>
					</v-container>
				</template>
				
				<template #footer>
					<frp-btn :href="lkProjectLink"
							 target="_blank"
							 outlined
							 color="blue">
						{{ $t("buttons.goToUpload") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 @click="isTrancheScheduleGeneratingDialogOpened = false">
						{{ $t("buttons.close") }}
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</frp-card>
	<tranches-tranche-application-generation-loader v-else/>
</template>

<script>
import { TranchesController } from "@/api/tranches";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import TranchesCardContentLayout from "@/components/layouts/TranchesCardContentLayout.vue";
import { FileTypes } from "@/constants/fileTypes";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import AbortService from "@/services/abortService";
import { namespace, getterTypes } from "@/store/tranches/modules/application/types";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import TranchesTrancheApplicationGenerationLoader
	from "@/views/tranches/sections/generation/TranchesTrancheApplicationGenerationLoader.vue";
import { createNamespacedHelpers } from "vuex";
import { saveAs } from "file-saver";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const tranchesController = new TranchesController(abortService);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			FileTypes,
			trancheScheduleFile: null,
			isTrancheScheduleGenerating: false,
			isTrancheScheduleGeneratingDialogOpened: false
		};
	},
	computed: {
		...mapGetters({
			lkProjectLink: getterTypes.lkProjectLink
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			applicationNumber: state => state.applicationNumber,
			projectInfo: state => state.projectInfo
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({}),
		async handleGenerateTrancheScheduleFile() {
			this.isTrancheScheduleGenerating = true;
			
			try {
				const { data, filename } = await tranchesController.generateTrancheSchedule(this.applicationNumber);
				
				let additionTrancheScheduleGeneratedFileName = `${this.$t("common.trancheScheduleGeneratedFileName")}`;
				
				this.trancheScheduleFile = new File([data], filename || additionTrancheScheduleGeneratedFileName, { type: data.type });
				this.isTrancheScheduleGeneratingDialogOpened = true;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isTrancheScheduleGenerating = false;
			}
		},
		saveGeneratedTrancheScheduleFile() {
			saveAs(this.trancheScheduleFile, this.trancheScheduleFile?.name);
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		isTrancheScheduleGeneratingDialogOpened(value) {
			if(!value) {
				this.trancheScheduleFile = null;
			}
		}
	},
	components: {
		FrpLinkBtn,
		FrpCard,
		TranchesCardContentLayout,
		TranchesTrancheApplicationGenerationLoader,
		FrpIcon,
		FrpDialog,
		FrpTextBtn,
		FrpBtn
	}
};
</script>

<style scoped>

</style>

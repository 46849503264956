import TreeModel from "tree-model";

class Route {
	name: string;
	children: Route[];

	constructor(name: string, children: Route[] = []) {
		this.name = name;
		this.children = children;
	}
}

export enum RouteNames {
	ROOT = "ROOT",
	APPS = "APPS",
	CALLBACK = "CALLBACK",
	SILENT_RENEW = "SILENT_RENEW",
	MASTER_ACCOUNT_ACCESS = "MASTER_ACCOUNT_ACCESS",
	APPLICATIONS = "APPLICATIONS",
	APPLICATIONS_MASTER_ACCOUNT_ACCESSES = "APPLICATIONS_MASTER_ACCOUNT_ACCESSES",
	APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS = "APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS",
	APPLICATIONS_EMPLOYEE_JOIN_LIST = "APPLICATIONS_EMPLOYEE_JOIN_LIST",
	APPLICATIONS_EMPLOYEE_JOIN_DETAILS = "APPLICATIONS_EMPLOYEE_JOIN_DETAILS",

	COUNTERPARTIES = "COUNTERPARTIES",

	COUNTERPARTY = "COUNTERPARTY",
	COUNTERPARTY_CREATE = "COUNTERPARTY_CREATE",
	COUNTERPARTY_GENERAL = "COUNTERPARTY_GENERAL",
	COUNTERPARTY_EMPLOYEES = "COUNTERPARTY_EMPLOYEES",
	COUNTERPARTY_MASTER_ACCOUNT = "COUNTERPARTY_MASTER_ACCOUNT",

	COUNTERPARTY_EMPLOYEE = "COUNTERPARTY_EMPLOYEE",
	COUNTERPARTY_EMPLOYEE_CREATE = "COUNTERPARTY_EMPLOYEE_CREATE",
	COUNTERPARTY_EMPLOYEE_GENERAL = "COUNTERPARTY_EMPLOYEE_GENERAL",
	COUNTERPARTY_EMPLOYEE_TRUSTS = "COUNTERPARTY_EMPLOYEE_TRUSTS",
	COUNTERPARTY_EMPLOYEE_PROFILE = "COUNTERPARTY_EMPLOYEE_PROFILE",
	COUNTERPARTY_EMPLOYEE_TRUST = "COUNTERPARTY_EMPLOYEE_TRUST",
	COUNTERPARTY_EMPLOYEE_TRUST_CREATE = "COUNTERPARTY_EMPLOYEE_TRUST_CREATE",

	OFFICE = "OFFICE",
	OFFICE_GENERAL = "OFFICE_GENERAL",
	OFFICE_EMPLOYEES = "OFFICE_EMPLOYEES",

	OFFICE_EMPLOYEE = "OFFICE_EMPLOYEE",
	OFFICE_EMPLOYEE_CREATE = "OFFICE_EMPLOYEE_CREATE",
	OFFICE_EMPLOYEE_GENERAL = "OFFICE_EMPLOYEE_GENERAL",
	OFFICE_EMPLOYEE_PROFILE = "OFFICE_EMPLOYEE_PROFILE",

	PROFILE_ASSIGNMENT = "PROFILE_ASSIGNMENT",

	ACCOUNTS = "ACCOUNTS",
	ACCOUNT = "ACCOUNT",
	ACCOUNT_GENERAL = "ACCOUNT_GENERAL",
	ACCOUNT_PROFILES = "ACCOUNT_PROFILES",

	CONFIRM_EMAIL = "CONFIRM_EMAIL",
	CONFIRM_PHONE = "CONFIRM_PHONE",
	EMPLOYEE_JOIN = "EMPLOYEE_JOIN",

	ERROR_PAGE_NOT_FOUND = "ERROR_PAGE_NOT_FOUND",
	ERROR_ACCESS_DENIED = "ERROR_ACCESS_DENIED",
	ERROR_INTERNAL_SERVER_ERROR = "ERROR_INTERNAL_SERVER_ERROR",

	REX= "REX"
}


const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesThreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const findAllByAncestor = (ancestorRouteName: string) => {
	let searchNode: any = findRoute(ancestorRouteName);
	return routesThreeRoot.all(x => x.getPath().includes(searchNode));
};


export const routesThreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.APPS),
		new Route(RouteNames.CALLBACK),
		new Route(RouteNames.SILENT_RENEW),
		new Route(RouteNames.MASTER_ACCOUNT_ACCESS),
		new Route(RouteNames.APPLICATIONS),
		new Route(RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES, [
			new Route(RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS)
		]),
		new Route(RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST, [
			new Route(RouteNames.APPLICATIONS_EMPLOYEE_JOIN_DETAILS)
		]),
		new Route(RouteNames.COUNTERPARTIES, [
			new Route(RouteNames.COUNTERPARTY_CREATE),
			new Route(RouteNames.COUNTERPARTY, [
				new Route(RouteNames.COUNTERPARTY_GENERAL),
				new Route(RouteNames.COUNTERPARTY_EMPLOYEES, [
					new Route(RouteNames.COUNTERPARTY_EMPLOYEE_CREATE),
					new Route(RouteNames.COUNTERPARTY_EMPLOYEE, [
						new Route(RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL),
						new Route(RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE),
						new Route(RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS, [
							new Route(RouteNames.COUNTERPARTY_EMPLOYEE_TRUST),
							new Route(RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE)
						])
					])
				]),
				new Route(RouteNames.COUNTERPARTY_MASTER_ACCOUNT)
			])
		]),

		new Route(RouteNames.OFFICE, [
			new Route(RouteNames.OFFICE_GENERAL),
			new Route(RouteNames.OFFICE_EMPLOYEES, [
				new Route(RouteNames.OFFICE_EMPLOYEE_CREATE),
				new Route(RouteNames.OFFICE_EMPLOYEE, [
					new Route(RouteNames.OFFICE_EMPLOYEE_GENERAL),
					new Route(RouteNames.OFFICE_EMPLOYEE_PROFILE)
				])
			])
		]),
		new Route(RouteNames.PROFILE_ASSIGNMENT),
		new Route(RouteNames.ACCOUNTS, [
			new Route(RouteNames.ACCOUNT, [
				new Route(RouteNames.ACCOUNT_GENERAL),
				new Route(RouteNames.ACCOUNT_PROFILES)
			])
		]),
		new Route(RouteNames.CONFIRM_EMAIL),
		new Route(RouteNames.CONFIRM_PHONE),
		new Route(RouteNames.ERROR_PAGE_NOT_FOUND),
		new Route(RouteNames.ERROR_ACCESS_DENIED),
		new Route(RouteNames.ERROR_INTERNAL_SERVER_ERROR),

		new Route(RouteNames.REX),
	]));

import { FileTypes } from "@/constants/fileTypes";

export const checkSnilsFormat = (value: any) => {
	switch (value.type || value.mimeType) {
		case FileTypes.JPG:
		case FileTypes.PNG:
		case FileTypes.PDF:
			return true;

		default:
			return false;
	}
}

import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import { ApiAddKpiRequest } from "@/api/kpi/types/apiAddKpiRequest";
import { ApiUpdateStaffCriteriasItem } from "@/api/kpi/types/apiUpdateStaffCriteriasItem";
import { ApiKpi } from "@/api/kpi/types/apiKpi";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiKpiReport } from "@/api/kpi/types/apiKpiReport";
import { ApiSetStaffCriteriaResultRequest } from "@/api/kpi/types/apiSetStaffCriteriaResultRequest";
import { ApiSetStaffLaborRateRequest } from "@/api/kpi/types/apiSetStaffLaborRateRequest";
import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";
import { ApiKpiDepartment } from "@/api/kpi/types/apiKpiDepartment";
import { ApiKpis } from "@/api/kpi/types/apiKpis";
import { ApiUpdateKpiRequest } from "@/api/kpi/types/apiUpdateKpiRequest";
import { ApiReportingPermissions } from "@/api/kpi/types/apiReportingPermissions";
import { ApiGetFileResponse } from "@/api/types/storage/apiGetFileResponse";
import { ApiDownloadRegistryParameters } from "@/api/kpi/types/apiDownloadRegistryParameters";
import { ApiDelegacy } from "@/api/kpi/types/apiDelegacy";
import { ApiGetDelegacyParameters } from "@/api/kpi/types/apiGetDelegacyParameters";
import { ApiCreateDelegacyRequest } from "@/api/kpi/types/apiCreateDelegacyRequest";
import { ApiGetFileByPostResponse } from "@/api/types/storage/apiGetFileByPostResponse";
import { ApiKpiMatrixItem } from "@/api/kpi/types/apiKpiMatrixItem";
import { ApiGetKpiMatrixRequest } from "@/api/kpi/types/apiGetKpiMatrixRequest";

export class KpiController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		return await this.client.get<string[]>(urls.kpi.permissions.get);
	};
	
	getKpiPermissions = async (kpiId: string) => {
		return await this.client.get<string[]>(urls.kpi.kpi.getPermissions.replace(urlTemplateParts.id, kpiId));
	};
	
	getReportingPermissionsByYear = async (year: number) => {
		return await this.client.get<ApiReportingPermissions>(urls.kpi.result.getPermissionsByYear
																  .replace(urlTemplateParts.id, String(year)));
	};
	
	getReportingPermissionsByQuarter = async (year: number, quarter: string) => {
		return await this.client.get<ApiReportingPermissions>(urls.kpi.result.getPermissionsByQuarter
																  .replace(urlTemplateParts.id, String(year))
																  .replace(urlTemplateParts.subId, quarter));
	};
	
	createKpi = async (payload: ApiAddKpiRequest) => {
		const { id } = await this.client.post<{ id: string }>(urls.kpi.kpi.createKpi, payload);
		return id;
	};
	
	updateKpi = async (id: string, payload: ApiUpdateKpiRequest): Promise<void> => {
		return await this.client.put(urls.kpi.kpi.updateKpi.replace(urlTemplateParts.id, id), payload);
	};
	
	deleteKpi = async (id: string): Promise<void> => {
		return await this.client.delete(urls.kpi.kpi.deleteKpi.replace(urlTemplateParts.id, id));
	};
	
	updateStaffWeights = async (kpiId: string, payload: ApiUpdateStaffCriteriasItem[]) => {
		return await this.client.post<void>(urls.kpi.kpi.updateStaffWeights.replace(urlTemplateParts.id, kpiId), payload);
	};
	
	getKpi = async (id: string) => {
		return await this.client.get<ApiKpi>(urls.kpi.kpi.getKpi.replace(urlTemplateParts.id, id));
	};
	
	getKpisByYear = async (year: number) => {
		return await this.client.get<ApiKpis>(urls.kpi.kpi.getKpisByYear.replace(urlTemplateParts.id, String(year)));
	};
	
	getCurrentUser = async () => {
		return await this.client.get<ApiKpiUser>(urls.kpi.user.getCurrentUser);
	};
	
	getReportingByYear = async (year: number) => {
		return await this.client.get<ApiKpiReport>(urls.kpi.result.getReportingByYear.replace(urlTemplateParts.id, year + ""));
	};
	
	getReportingByQuarter = async (year: number, quarter: number) => {
		return await this.client.get<ApiKpiReport>(urls.kpi.result.getReportingByQuarter
													   .replace(urlTemplateParts.id, String(year))
													   .replace(urlTemplateParts.subId, String(quarter)));
	};
	
	
	getEmployeeReportingByQuarter = async (year: number, quarter: number, employeeId: string) => {
		return await this.client.get<ApiKpiReport>(urls.kpi.result.getEmployeeReportingByQuarter
													   .replace(urlTemplateParts.id, String(year))
													   .replace(urlTemplateParts.subId, String(quarter))
													   .replace(urlTemplateParts.thirdId, String(employeeId)));
	};
	
	setStaffCriteriaResult = async (staffId: string,
		departmentId: string,
		positionId: string,
		payload: ApiSetStaffCriteriaResultRequest) => {
		return await this.client.post<void>(urls.kpi.result.setStaffCriteriaResult
												.replace(urlTemplateParts.id, staffId)
												.replace(urlTemplateParts.subId, departmentId)
												.replace(urlTemplateParts.thirdId, positionId), payload);
	};
	
	setStaffLaborRate = async (staffId: string, departmentId: string, positionId: string, payload: ApiSetStaffLaborRateRequest) => {
		return await this.client.post<void>(urls.kpi.staff.setStaffLaborRate
												.replace(urlTemplateParts.id, staffId)
												.replace(urlTemplateParts.subId, departmentId)
												.replace(urlTemplateParts.thirdId, positionId), payload);
	};
	
	getStaffList = async () => {
		return await this.client.get<ApiKpiUser[]>(urls.kpi.staff.getStaffList);
	};
	
	getDepartments = async () => {
		return await this.client.get<ApiKpiDepartment[]>(urls.kpi.staff.getDepartments);
	};
	
	getPeriods = async (hasKpiOnly?: boolean) => {
		return await this.client.get<ApiKpiPeriod[]>(prepareUrl(urls.kpi.kpi.getPeriods), { hasKpiOnly });
	};
	
	downloadRegistry = async (year: number, parameters: ApiDownloadRegistryParameters) => {
		return await this.client.get<ApiGetFileResponse>(prepareUrl(urls.kpi.reportGenerator.downloadRegistry.replace(
				urlTemplateParts.id,
				String(year)),
			{ isPdf: true, ...parameters }), { responseType: "blob" }, true);
		
	};
	
	getDelegacyRegistry = async (parameters: ApiGetDelegacyParameters) => {
		return await this.client.get<ApiDelegacy>(prepareUrl(urls.kpi.delegacy.getDelegacy, parameters));
	};
	
	getDelegacyAppointers = async () => {
		return await this.client.get<ApiKpiUser[]>(urls.kpi.delegacy.getAppointers);
	};
	
	getDelegacyAttorneys = async (appointerId: string) => {
		return await this.client.get<ApiKpiUser[]>(urls.kpi.delegacy.getAttorneys.replace(urlTemplateParts.id, appointerId));
	};
	
	createDelegacy = async (payload: ApiCreateDelegacyRequest) => {
		return await this.client.post<void>(urls.kpi.delegacy.createDelegacy, payload);
	};
	
	deleteDelegacy = async (id: string) => {
		return await this.client.delete(urls.kpi.delegacy.deleteDelegacy.replace(urlTemplateParts.id, id));
	};
	
	signScoreCardByYear = async (staffId: string, departmentId: string, positionId: string, year: number, signature: string) => {
		return await this.client.post(urls.kpi.result.signScoreCardByYear
										  .replace(urlTemplateParts.id, staffId)
										  .replace(urlTemplateParts.subId, departmentId)
										  .replace(urlTemplateParts.thirdId, positionId)
										  .replace(urlTemplateParts.fourthId, String(year)), { signature });
	};
	
	signScoreCardByQuarter = async (staffId: string,
		departmentId: string,
		positionId: string,
		year: number,
		quarter: string,
		signature: string) => {
		return await this.client.post(urls.kpi.result.signScoreCardByQuarter.replace(urlTemplateParts.id, staffId)
										  .replace(urlTemplateParts.subId, departmentId)
										  .replace(urlTemplateParts.thirdId, positionId)
										  .replace(urlTemplateParts.fourthId, String(year))
										  .replace(urlTemplateParts.fifthId, String(quarter)), { signature });
	};
	
	getScoreCards = async (periods: ApiKpiPeriod[]) => {
		return await this.client.post<ApiGetFileByPostResponse>(urls.kpi.result.archive, { periods }, { responseType: "blob" }, true);
	};
	
	getStaffListByPeriods = async (periods: ApiKpiPeriod[]) => {
		return await this.client.post<ApiKpiUser[]>(urls.kpi.kpi.getStaffListByPeriods, periods);
	};
	
	getMatrix = async (payload: ApiGetKpiMatrixRequest) => {
		return await this.client.post<{ kpiMatrixItems: ApiKpiMatrixItem[] }>(urls.kpi.kpi.getMatrix, payload);
	};
}

import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "bank-account-application-legal-department";

export const getterTypes = {
	...snapshotMixinTypes.getterTypes,
	selectableDocumentStatuses: "selectableDocumentStatuses",
	sortedLegalDepartmentDocumentFileInfoItems: "sortedLegalDepartmentDocumentFileInfoItems"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	getLegalDepartmentInfo: "getLegalDepartmentInfo",
	sendLegalDepartmentRequest: "sendLegalDepartmentRequest",
	setResponsibleLegalDepartmentStaffEmployee: "setResponsibleLegalDepartmentStaffEmployee",
	acceptLegalDepartment: "acceptLegalDepartment",
	declineLegalDepartment: "declineLegalDepartment",
	resetStatusesForLegalDepartment: "resetStatusesForLegalDepartment",
	fetchLegalDepartmentDocuments: "fetchLegalDepartmentDocuments",
	uploadLegalDepartmentDocumentFileInfo: "uploadLegalDepartmentDocumentFileInfo",
	deleteLegalDepartmentDocument: "deleteLegalDepartmentDocument"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTED: "SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTED",
	SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING: "SET_IS_LEGAL_DEPARTMENT_REQUEST_LOADING",
	SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTING: "SET_IS_RESPONSIBLE_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_APPOINTING",
	SET_LEGAL_DEPARTMENT_INFO: "SET_LEGAL_DEPARTMENT_INFO",
	RESET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID: "RESET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID",
	SET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID: "SET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID",
	SET_LEGAL_DEPARTMENT_INFO_IS_ACCEPTED_VALUE: "SET_LEGAL_DEPARTMENT_INFO_IS_ACCEPTED_VALUE",
	SET_LEGAL_DEPARTMENT_INFO_IS_DECLINED_VALUE: "SET_LEGAL_DEPARTMENT_INFO_IS_DECLINED_VALUE",
	SET_IS_LEGAL_DEPARTMENT_ACCEPTING: "SET_IS_LEGAL_DEPARTMENT_ACCEPTING",
	SET_IS_LEGAL_DEPARTMENT_DECLINING: "SET_IS_LEGAL_DEPARTMENT_DECLINING",
	SET_IS_LEGAL_DEPARTMENT_STATUSES_RESETTING: "SET_IS_LEGAL_DEPARTMENT_STATUSES_RESETTING",
	SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_FETCHING: "SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_FETCHING",
	RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS: "RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS",
	SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS: "SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS",
	REMOVE_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM: "REMOVE_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM",
	SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM_DELETING: "SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_ITEMS_ITEM_DELETING",
	SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_UPLOADING: "SET_IS_LEGAL_DEPARTMENT_DOCUMENT_FILE_INFO_UPLOADING",
	RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META: "RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META",
	SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META_IS_LOADING: "SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META_IS_LOADING",
	SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META: "SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META",
	RESET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID: "RESET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID",
	SET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID: "SET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID"
};

const bankAccountApplicationLegalDepartmentTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountApplicationLegalDepartmentTypes;

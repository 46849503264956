import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "counterparty";

export const getterTypes = {};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_TITLE: "SET_TITLE",
	SET_SHORT_TITLE: "SET_SHORT_TITLE",
	SET_COUNTERPARTY_TYPE: "SET_COUNTERPARTY_TYPE",
	SET_ID: "SET_ID",
	SET_IS_COUNTERPARTY_LOADING: "SET_IS_COUNTERPARTY_LOADING"
};

const counterpartyTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default counterpartyTypes;

import { actionTypes, mutationTypes } from "@/store/shared/embeddedPasswordForm/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import EmbeddedFormMixinBuilder from "@/store/shared/embeddedForm";
import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "@/store/shared/embeddedForm/types/embeddedFormModeType";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import EmbeddedPasswordFormState from "@/store/shared/embeddedPasswordForm/types/embeddedPasswordFormState";

export const abortService = new AbortService();

const formMixin = (new EmbeddedFormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new EmbeddedPasswordFormState(
			formMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmbeddedPasswordFormState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<EmbeddedPasswordFormState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.UPDATE);
		commit(mutationTypes.SET_FORM_MODE, EmbeddedFormModeType.DETAILS);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.save]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await dispatch(actionTypes.update);

			commit(mutationTypes.SET_FORM_MODE, EmbeddedFormModeType.SUCCESS);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<EmbeddedPasswordFormState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_PASSWORD](state, value) {
		state.password = value;
	},
	[mutationTypes.SET_CONFIRMED_PASSWORD](state, value) {
		state.confirmedPassword = value;
	}
};

export {
	state, getters, actions, mutations
};

const embeddedPasswordModule = {
	state, getters, actions, mutations, namespaced: true
};

export default embeddedPasswordModule;

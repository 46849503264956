import { render, staticRenderFns } from "./FrpLinkBtn.vue?vue&type=template&id=530bfb16&scoped=true"
import script from "./FrpLinkBtn.vue?vue&type=script&lang=js"
export * from "./FrpLinkBtn.vue?vue&type=script&lang=js"
import style0 from "./FrpLinkBtn.vue?vue&type=style&index=0&id=530bfb16&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530bfb16",
  null
  
)

export default component.exports
import TreeModel from "tree-model";

class Route {
	name: string;
	children: Route[];

	constructor(name: string, children: Route[] = []) {
		this.name = name;
		this.children = children;
	}
}

export enum RouteNames {
	ROOT = "ROOT",
	CALLBACK = "CALLBACK",
	SILENT_RENEW = "SILENT_RENEW",

	BANK_ACCOUNT_APPLICATIONS_LIST = "BANK_ACCOUNT_APPLICATIONS_LIST",
	BANK_ACCOUNT_APPLICATION = "BANK_ACCOUNT_APPLICATION",
	BANK_ACCOUNT_APPLICATION_CREATE = "BANK_ACCOUNT_APPLICATION_CREATE",
	BANK_ACCOUNT_APPLICATION_UPDATE = "BANK_ACCOUNT_APPLICATION_UPDATE",
	BANK_ACCOUNTS_LIST = "BANK_ACCOUNTS_LIST",
	BANK_ACCOUNT = "BANK_ACCOUNT",
	BANK_ACCOUNT_CREATE = "BANK_ACCOUNT_CREATE",
	ERROR_PAGE_NOT_FOUND = "ERROR_PAGE_NOT_FOUND",
	ERROR_ACCESS_DENIED = "ERROR_ACCESS_DENIED",
	ERROR_INTERNAL_SERVER_ERROR = "ERROR_INTERNAL_SERVER_ERROR"
}


const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesThreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const routesThreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.CALLBACK),
		new Route(RouteNames.SILENT_RENEW),

		new Route(RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST, [
			new Route(RouteNames.BANK_ACCOUNT_APPLICATION),
			new Route(RouteNames.BANK_ACCOUNT_APPLICATION_CREATE),
			new Route(RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE)
		]),

		new Route(RouteNames.BANK_ACCOUNTS_LIST, [
			new Route(RouteNames.BANK_ACCOUNT),
			new Route(RouteNames.BANK_ACCOUNT_CREATE),
		]),

		new Route(RouteNames.ERROR_PAGE_NOT_FOUND),
		new Route(RouteNames.ERROR_ACCESS_DENIED),
		new Route(RouteNames.ERROR_INTERNAL_SERVER_ERROR)
	]));

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "vacation-plan-holidays";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	yearValues: "yearValues"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...routeTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetchYears: "fetchYears",
	updateHolidays: "updateHolidays",
	createYear: "createYear"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_FILTER_YEAR: "SET_FILTER_YEAR",
	RESET_FILTER: "RESET_FILTER",
	SET_IS_YEARS_LOADING: "SET_IS_YEARS_LOADING",
	SET_IS_YEAR_CREATING: "SET_IS_YEAR_CREATING",
	SET_YEARS: "SET_YEARS",
	SET_HOLIDAYS: "SET_HOLIDAYS",
	SET_YEAR_HOLIDAYS: "SET_YEAR_HOLIDAYS"
};

const vacationPlanHolidaysTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default vacationPlanHolidaysTypes;

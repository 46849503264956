import { Type } from "class-transformer";
import ApiDocumentListItem from "@/api/bod/types/apiDocumentListItem";

export default class ApiDocumentsListResponse {
	@Type(() => ApiDocumentListItem)
	items: ApiDocumentListItem[];
	totalCount: number;

	constructor(
		items: ApiDocumentListItem[] = [],
		totalCount: number = 0
	)
	{
		this.items = items;
		this.totalCount = totalCount;
	}
}

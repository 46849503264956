<template>
	<v-btn class="frp-text-btn text-body-2 d-inline-block"
		   :class="wrap ? 'frp-text-btn--wrap-text' : ''"
		   retain-focus-on-click
		   plain
		   v-on="$listeners"
		   text
		   :to="to"
		   :href="href"
		   :color="color"
		   data-cy="submit"
		   :loading="loading"
		   :disabled="disabled">
		<slot>{{ text }}</slot>
	</v-btn>
</template>

<script>
export default {
	props: {
		disabled: Boolean,
		loading: Boolean,
		text: {
			type: [String, Number]
		},
		color: {
			type: String,
			default: "secondary"
		},
		to: {
			type: Object,
			default: undefined
		},
		href: {
			type: String,
			default: undefined
		},
		wrap: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss">
.frp-text-btn {
	text-transform: none;
	height: unset !important;

	& .v-btn__content {
		opacity: 1 !important;
		line-height: unset !important;
	}

	&--wrap-text {
		.v-btn__content {
			white-space: normal;
			text-align: start;
		}
	}
}
</style>

import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "office-employees";

export const getterTypes = {
	...listingGetterTypes,
	filterRolesItems: "filterRolesItems"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchRoles: "fetchRoles"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_ROLES: "SET_FILTER_ROLES",
	SET_ROLES: "SET_ROLES",
	SET_ROLES_IS_LOADING: "SET_ROLES_IS_LOADING"
};

const officeEmployeesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default officeEmployeesTypes;

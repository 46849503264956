import baseMixinTypes from "@/store/shared/base/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "office-employee";

export const getterTypes = {
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	fetch: "fetch",
	activateEmployee: "activateEmployee",
	deactivateEmployee: "deactivateEmployee"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_EMPLOYEE: "SET_EMPLOYEE",
	SET_OFFICE_ID: "SET_OFFICE_ID",
	SET_OFFICE: "SET_OFFICE",
	SET_ID: "SET_ID",
	SET_PROFILE: "SET_PROFILE",
	SET_EMPLOYEE_IS_ACTIVE: "SET_EMPLOYEE_IS_ACTIVE",
	SET_IS_STATUS_SAVING: "SET_IS_STATUS_SAVING"
};

const officeEmployeeTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default officeEmployeeTypes;

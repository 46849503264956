import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "employee-expectations";

export const getterTypes = {
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes
};

const employeeExpectationsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default employeeExpectationsTypes;

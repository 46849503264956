import baseMixinTypes from "@/store/shared/base/types";

export const namespace = "loan-user";

export const getterTypes = {
	isLoanUserAdministrator: "isLoanUserAdministrator",
	isLoanUserBorrower: "isLoanUserBorrower",
	isLoanUserAssignee: "isLoanUserAssignee"
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	fetchUser: "fetchUser"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	SET_IS_USER_LOADING: "SET_IS_USER_LOADING",
	SET_USER: "SET_USER"
};

const userTypes = {
	namespace,
	actionTypes,
	getterTypes
};

export default userTypes;

import urls, { urlTemplateParts } from "@/api/config/urls";
import ApiFile from "@/api/types/storage/apiFile";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import { prepareFormData } from "@/utils/prepareFormData";
import { getFormDataHeaders } from "@/utils/getFormDataHeaders";

export class StorageController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	createTemperFile = async (file: ApiFile): Promise<ApiFileMeta> => {
		const url = file.signature ? urls.storage.file.createTemperSigned : urls.storage.file.createTemper;

		let result = await this.client.post<object>(url, prepareFormData(file), getFormDataHeaders());

		return plainToInstance(ApiFileMeta, result);
	};

	getFile = async (id: string) => {
		return this.client.get<Blob>(prepareUrl(urls.storage.file.download.replace(urlTemplateParts.id, id)), { responseType: "blob" });
	};

	getFileMeta = async (id: string) => {
		let result = await this.client.get<object>(prepareUrl(urls.storage.file.getMeta.replace(urlTemplateParts.id, id)));

		return plainToInstance(ApiFileMeta, result);
	};
}



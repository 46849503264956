import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "cash-flow";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	paymentTypes: "paymentTypes",
	borrowerRolePaymentTypes: "borrowerRolePaymentTypes",
	borrowers: "borrowers"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries",
	createItem: "createItem",
	updateItem: "updateItem",
	deleteItem: "deleteItem",
	updateDynamicDictionaries: "updateDynamicDictionaries"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	RESET_EDITABLE_ITEM: "RESET_EDITABLE_ITEM",
	SET_EDITABLE_ITEM: "SET_EDITABLE_ITEM",
	SET_IS_ITEM_DELETING: "SET_IS_ITEM_DELETING",
	SET_EDITABLE_ITEM_NUMBER_PAYMENT_ORDER: "SET_EDITABLE_ITEM_NUMBER_PAYMENT_ORDER",
	SET_EDITABLE_ITEM_PAYMENT_DATE: "SET_EDITABLE_ITEM_PAYMENT_DATE",
	SET_EDITABLE_ITEM_QUARTER: "SET_EDITABLE_ITEM_QUARTER",
	SET_EDITABLE_ITEM_SOURCE: "SET_EDITABLE_ITEM_SOURCE",
	SET_EDITABLE_ITEM_PAYMENT_EXPENSE: "SET_EDITABLE_ITEM_PAYMENT_EXPENSE",
	SET_EDITABLE_ITEM_AMOUNT: "SET_EDITABLE_ITEM_AMOUNT",
	SET_EDITABLE_ITEM_DESCRIPTION: "SET_EDITABLE_ITEM_DESCRIPTION",
	SET_EDITABLE_ITEM_IS_FORCED: "SET_EDITABLE_ITEM_IS_FORCED",
	SET_EDITABLE_ITEM_IS_CESSION: "SET_EDITABLE_ITEM_IS_CESSION",
	SET_EDITABLE_ITEM_IS_RESERVE: "SET_EDITABLE_ITEM_IS_RESERVE",
	SET_EDITABLE_ITEM_ACTIVE_DATE: "SET_EDITABLE_ITEM_ACTIVE_DATE",
	SET_EDITABLE_ITEM_PROJECT_ID: "SET_EDITABLE_ITEM_PROJECT_ID",
	SET_QUARTERS: "SET_QUARTERS",
	SET_FINANCE_SOURCES: "SET_FINANCE_SOURCES",
	SET_PAYMENT_EXPANSES: "SET_PAYMENT_EXPANSES",
	SET_BORROWERS: "SET_BORROWERS",
	RESET_FILTER: "RESET_FILTER",
	SET_FILTER_START_PAYMENT_DATE: "SET_FILTER_START_PAYMENT_DATE",
	SET_FILTER_END_PAYMENT_DATE: "SET_FILTER_END_PAYMENT_DATE",
	SET_FILTER_QUARTER_CODE_IDS: "SET_FILTER_QUARTER_CODE_IDS",
	SET_FILTER_PAYMENT_EXPENSE_IDS: "SET_FILTER_PAYMENT_EXPENSE_IDS",
	SET_FILTER_PAYMENT_TYPE: "SET_FILTER_PAYMENT_TYPE",
	SET_DYNAMIC_FINANCE_SOURCES: "SET_DYNAMIC_FINANCE_SOURCES",
	SET_DYNAMIC_QUARTERS: "SET_DYNAMIC_QUARTERS",
	SET_PAYMENT_EXPENSE_TYPES: "SET_PAYMENT_EXPENSE_TYPES",
	SET_ALL_FINANCE_SOURCES: "SET_ALL_FINANCE_SOURCES",
	SET_IS_FULL_MODE: "SET_IS_FULL_MODE",
	SET_RESERVED_TOTAL_AMOUNT: "SET_RESERVED_TOTAL_AMOUNT"
};

const cashFlowTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default cashFlowTypes;

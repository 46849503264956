<template>
	<v-menu
		open-on-hover
		offset-y
		:top="top"
		:left="left"
		:right="right"
		:bottom="bottom"
		content-class="frp-information-menu"
		nudge-top="6">
		<template v-slot:activator="{ on, attrs }">
			<v-icon
				:color="iconColor"
				v-bind="attrs"
				v-on="on"
				:class="targetClass"
				style="height: 16px;"
				size="21"
				@mouseover="isHovered = true"
				@mouseleave="isHovered = false">
				mdi-information-outline
			</v-icon>
		</template>
		<span class="text-caption primary--text text--darken-1">{{ text }}</span>
	</v-menu>
</template>

<script>
import colorsMixin from "@/mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	data() {
		return {
			isHovered: false
		};
	},
	props: {
		top: {
			type: Boolean,
			default: true
		},
		left: {
			type: Boolean,
			default: false
		},
		right: {
			type: Boolean,
			default: false
		},
		bottom: {
			type: Boolean,
			default: false
		},
		text: {
			type: String,
			required: true
		},
		customColor: String,
		customHoveredColor: String,
		targetClass: String
	},
	computed: {
		iconColor() {
			return !this.isHovered ? this.customColor || this.colors.primary.base : this.customHoveredColor || this.colors.secondary.base;
		}
	}
};
</script>
<style scoped lang="scss">
.frp-information-menu {
	line-height: 10px !important;

	&.v-menu__content {
		background: var(--v-white-base) !important;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
		max-width: 235px;
		border-radius: 4px;
		padding: 8px;
	}
}
</style>

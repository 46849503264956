<template>
	<v-dialog value="true" persistent width="537">
		<v-card class="pt-3">
			<v-card-title class="headline text-h6 loan-confirm-dialog-card-title">{{ title }}</v-card-title>
			<v-card-text class="text-justify">{{ description }}</v-card-text>
			<v-card-actions class="pa-6">
				<v-spacer></v-spacer>
				<frp-btn color="primary"
						 outlined
						 :color="cancelBtnColor"
						 :dark="cancelBtnDark"
						 :disabled="loading"
						 @click="cancel">
					{{ cancelBtn || $t("buttons.back") }}
				</frp-btn>
				<frp-btn :loading="loading"
						 elevation="0"
						 :color="submitBtnColor"
						 :dark="submitBtnDark"
						 @click="submit">
					{{ submitBtn || $t("buttons.ok") }}
				</frp-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";

export default {
	components: { FrpBtn },
	props: {
		title: String,
		description: String,
		cancelBtn: String,
		submitBtn: String,
		submitBtnColor: {
			type: String,
			default: "blue"
		},
		submitBtnDark: {
			type: Boolean,
			default: true
		},
		cancelBtnColor: {
			type: String,
			default: "blue"
		},
		cancelBtnDark: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		submit() {
			this.$emit("submit");
		},
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>

<style scoped lang="scss">
.loan-confirm-dialog-card-title {
  line-height: 27.7px !important;
}

.v-card__text, .v-card__title {
  word-break: normal;
}
</style>

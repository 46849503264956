import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "loan-schedule";

export const getterTypes = {};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	fetchVersion: "fetchVersion",
	fetchVersionHeaders: "fetchVersionHeaders",
	fetchDictionaries: "fetchDictionaries"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_VERSION: "SET_VERSION",
	SET_VERSION_HEADERS: "SET_VERSION_HEADERS",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_QUARTERS: "SET_QUARTERS",
	SET_FINANCE_SOURCES: "SET_FINANCE_SOURCES",
	SET_TITLE_DOCUMENTS: "SET_TITLE_DOCUMENTS",
	SET_CHANGE_REASONS: "SET_CHANGE_REASONS",
	SET_WRONG_AMOUNT_DIALOG: "SET_WRONG_AMOUNT_DIALOG"
};

const loanScheduleTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default loanScheduleTypes;

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import embeddedFormTypes from "@/store/shared/embeddedForm/types";

const namespace = "snils-embedded-form";

export const getterTypes = {
	...embeddedFormTypes.getterTypes,
	isMetaChanged: "isMetaChanged"
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...embeddedFormTypes.actionTypes,
	fetch: "fetch",
	handleSnilsUpload: "handleSnilsUpload",
	download: "download"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...embeddedFormTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	UPDATE_LAST_SAVED_META: "UPDATE_LAST_SAVED_META",
	SET_SNILS_FILE_META: "SET_SNILS_FILE_META",
	SET_SNILS_FILE_ID: "SET_SNILS_FILE_ID",
	SET_OWNER_ID: "SET_OWNER_ID",
	SET_SNILS_FILE_META_IS_LOADING: "SET_SNILS_FILE_META_IS_LOADING"
};

const embeddedSnilsFormTypes = {
	actionTypes, getterTypes, mutationTypes, namespace
};

export default embeddedSnilsFormTypes;

export const STORAGE_SNILS_NAMESPACE = "snils";
export const STORAGE_INN_NAMESPACE = "inn";
export const STORAGE_ACCRUED_INTEREST_NAMESPACE = "accrued-interest";
export const STORAGE_TRUST_NAMESPACE = "counterparty-employee-trust";
export const STORAGE_APPOINTMENT_HEAD_ORDER = "appointment-head-order";
export const STORAGE_SIGNED_REQUEST = "master-application/request";
export const STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE = "bank-account-application-document";
export const STORAGE_BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DOCUMENT_NAMESPACE = "bank-account-application-legal-department";
export const STORAGE_TRANCHE_DOCUMENT_NAMESPACE = "tranche-document";
export const HR_VACATION_APPLICATION = "hr-vacation-application";

import { IMiddleware } from "@/types/core/middleware";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import { RouteNames } from "@/router/tranches/routes";
import routeToPermissionsMap from "@/router/tranches/routeToPermissionsMap";
import PermissionsService from "@/services/permissionsService";

const permissionsService = new PermissionsService();

export default class PermissionsMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		let routeName = to.name as RouteNames;

		if(routeName) {
			let permissions = routeToPermissionsMap.get(routeName);

			if(permissions && permissions.length) {
				if(!await permissionsService.check(permissions)) {
					return { name: RouteNames.ERROR_ACCESS_DENIED };
				}
			}
		}
	}
}

import * as ls from "local-storage";

export default class DigitalSignatureSuggestionService {

	constructor() {
	}

	add(key: string, thumbprint: string) {
		if(!ls.get(key)) {
			const suggestions = [];
			suggestions.push(thumbprint);
			ls.set(key, suggestions);
		} else {
			const suggestions: Array<string> = ls.get(key);
			const filteredSuggestions = suggestions.filter(item => item !== thumbprint);
			filteredSuggestions.unshift(thumbprint);

			ls.set(key, filteredSuggestions);
		}
	}
}

import { ApiHrPeriod } from "@/api/hr/types/apiHrPeriod";
import { ApiHrFeedback } from "@/api/hr/types/apiHrFeedback";
import { ApiHrFeedbackGroup } from "@/api/hr/types/apiHrFeedbackGroup";
import { ApiHrDepartmentsItem } from "@/api/hr/types/apiHrDepartmentsItem";
import ApiUpcomingPaymentsInterestDetailsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsInterestDetailsItem";
import ApiUpcomingPaymentsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsItem";
import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";

export const mocks = {
	feedback: {
		period: { year: 2023, quarter: 3 } as ApiHrPeriod,
		groups: [
			{
				title: "Самооценка",
				color: "#F3923E44",
				values: [
					{ label: "Желание развиваться", value: 64 },
					{ label: "Целеустремленность", value: 87 },
					{ label: "Интерес к работе", value: 87 },
					{ label: "Инициативность", value: 65 },
					{ label: "Межличностное понимание", value: 87 },
					{ label: "Самоорганизация", value: 77 },
					{ label: "Командная работа", value: 87 }
				]
			},
			{
				title: "Коллеги",
				color: "#3E83f344",
				values: [
					{ label: "Желание развиваться", value: 77 },
					{ label: "Целеустремленность", value: 77 },
					{ label: "Интерес к работе", value: 77 },
					{ label: "Инициативность", value: 77 },
					{ label: "Межличностное понимание", value: 77 },
					{ label: "Самоорганизация", value: 77 },
					{ label: "Командная работа", value: 77 }
				]
			},
			{
				title: "Подчиненные",
				color: "#0A8F1C44",
				values: [
					{ label: "Желание развиваться", value: 99 },
					{ label: "Целеустремленность", value: 99 },
					{ label: "Интерес к работе", value: 76 },
					{ label: "Инициативность", value: 99 },
					{ label: "Межличностное понимание", value: 86 },
					{ label: "Самоорганизация", value: 99 },
					{ label: "Командная работа", value: 52 }
				]
			}
		] as ApiHrFeedbackGroup[]
	} as ApiHrFeedback,
	departments: [
		{
			id: "1",
			name: "Отдел 1",
			departments: [
				{
					id: "2",
					name: "Отдел 2",
					parentId: "1",
					departments: [
						{
							id: "7",
							name: "Отдел 7",
							parentId: "2",
							departments: [
								{
									id: "9",
									name: "Отдел 9",
									parentId: "7"
								},
								{
									id: "10",
									name: "Отдел 10",
									parentId: "7"
								}
							]
						},
						{
							id: "8",
							name: "Отдел 8",
							parentId: "2"
						}
					]
				},
				{
					id: "3",
					name: "Отдел 3",
					parentId: "1"
				}
			]
		},
		{
			id: "4",
			name: "Отдел 4",
			departments: [
				{
					id: "5",
					name: "Отдел 5",
					parentId: "4"
				},
				{
					id: "6",
					name: "Отдел 6",
					parentId: "4"
				}
			]
		}
	] as ApiHrDepartmentsItem[],
	getUpcomingPayments: [
		{
			projectId: 1,
			financingSourceId: 2,
			dateMainDebt: "2039-04-01T00:00:00",
			paymentMainDebt: 2,
			balanceMainDebt: 3,
			balancePrevPercent: 8,
			datePaymentPrev: "2039-04-01T00:00:00",
			datePayment: "2039-04-01T00:00:00",
			accruedPercent: 35,
			paymentPrevPercent: 36,
			payment: 37,
			dayOverMainDebt: 28,
			overMainDebt: 29,
			mathPenaltyMainDebt: 20,
			dayOverPercent: 21,
			overPercent: 26,
			mathPenaltyPercent: 25,
			paymentPenaltyMainDebt: 24,
			paymentPenaltyPercent: 23,
			paymentPenaltyOther: 22,
			maxDateDds: "2039-04-01T00:00:00"
		},
		{
			projectId: 1,
			financingSourceId: 9,
			dateMainDebt: "2039-04-05T00:00:00",
			paymentMainDebt: 20,
			balanceMainDebt: 5,
			balancePrevPercent: 4,
			datePaymentPrev: "2039-04-01T00:00:00",
			datePayment: "2039-04-01T00:00:00",
			accruedPercent: 5,
			paymentPrevPercent: 6,
			payment: 7,
			dayOverMainDebt: 8,
			overMainDebt: 9,
			mathPenaltyMainDebt: 10,
			dayOverPercent: 11,
			overPercent: 16,
			mathPenaltyPercent: 15,
			paymentPenaltyMainDebt: 14,
			paymentPenaltyPercent: 13,
			paymentPenaltyOther: 12,
			maxDateDds: "2039-04-01T00:00:00"
		}
	] as ApiUpcomingPaymentsItem[],
	getUpcomingPaymentsInterestDetails: [
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 21.09.2022 по 30.09.2022",
			periodDays: 17,
			accrualDays: 18,
			accruedAmount: 19,
			financingSourceId: 9
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 01.10.2022 по 31.10.2022",
			periodDays: 17,
			accrualDays: 18,
			accruedAmount: 19,
			financingSourceId: 9
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 01.11.2022 по 30.11.2022",
			periodDays: 17,
			accrualDays: 18,
			accruedAmount: 19,
			financingSourceId: 9
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 01.12.2022 по 20.12.2022",
			periodDays: 17,
			accrualDays: 18,
			accruedAmount: 19,
			financingSourceId: 9
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 21.09.2022 по 30.09.2022",
			periodDays: 7,
			accrualDays: 8,
			accruedAmount: 9,
			financingSourceId: 2
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 01.10.2022 по 31.10.2022",
			periodDays: 7,
			accrualDays: 8,
			accruedAmount: 9,
			financingSourceId: 2
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 01.11.2022 по 30.11.2022",
			periodDays: 7,
			accrualDays: 8,
			accruedAmount: 9,
			financingSourceId: 2
		},
		{
			minDate: "2039-04-01T00:00:00",
			maxDate: "2039-04-01T00:00:00",
			periodName: "с 01.12.2022 по 20.12.2022",
			periodDays: 7,
			accrualDays: 8,
			accruedAmount: 9,
			financingSourceId: 2
		}
	] as ApiUpcomingPaymentsInterestDetailsItem[],
	getYears: [
		2023,
		2024
	],
	getYear: {
		value: 2024,
		// holidays: []
		holidays: ["2024-03-08", "2024-02-23"]
	},
	saveYear: {
		value: 2024,
		holidays: ["2024-03-08", "2024-02-23"]
	},
	importVacationPlanEmployees: {
		totalCount: 12,
		importedCount: 34
	},
	// getAdministrationEmployeesItems: [
	// 	{
	// 		id: "1",
	// 		email: "a@a.ru",
	// 		firstName: "Иван",
	// 		lastName: "Иванов",
	// 		middleName: "Иванович",
	// 		position: "Руководитель",
	// 		birthday: "2024-03-05",
	// 		photoUrl: "",
	// 		mobilePhone: "",
	// 		extensionPhone: "",
	// 		department: {
	// 			id: "1",
	// 			name: "Отдел разработки и сопровождения ИС",
	// 			parentId: ""
	// 		} as ApiHrDepartment,
	// 		personnelNumber: "00000000000",
	// 		remainingVacationDays: 12,
	// 		plannedVacationDays: 28,
	// 		plannedVacationDaysComment: "Комментарий",
	// 		hiredDate: "2024-01-01"
	// 	}
	// ] as ApiHrAdministrationEmployeesItem[],
	getVacationPlanDepartments: [
		{
			id: "1",
			name: "Отдел разработки и сопровождения ИС",
			parentId: ""
		},
		{
			id: "2",
			name: "Управление разработки и сопровождения аналитической отчетности",
			parentId: "1"
		},
		{
			id: "3",
			name: "Подразделение 3",
			parentId: "1"
		}
	] as ApiHrDepartment[],
	getVacationPlanEmployees: [
		{
			id: "1",
			firstName: "Иван",
			lastName: "Иванов",
			middleName: "Иванович",
			position: "Руководитель",
			department: {
				id: "1",
				name: "Подразделение 1",
				parentId: ""
			} as ApiHrDepartment,
			mobilePhone: "",
			extensionPhone: "",
			email: "a@a.ru"
		}
	] as ApiHrEmployeesItem[],
	getTasks: [
		{
			"id": "3fa85f64-1717-4562-b3fc-2c963f66afa6",
			"title": "string",
			"type": "Signing",
			"dueDate": "2024-05-23",
			"state": "ToDo",
			"assignee": {
				"id": "3fa85f64-2717-4562-b3fc-2c963f66afa6",
				"email": "string",
				"firstName": "string",
				"lastName": "string",
				"middleName": "string",
				"position": "string",
				"birthday": "2024-05-23",
				"photoUrl": "string",
				"mobilePhone": "string",
				"extensionPhone": "string",
				"department": {
					"id": "string",
					"name": "string",
					"head": "string",
					"parentId": "string"
				}
			},
			"initiator": {
				"id": "3fa85f64-3717-4562-b3fc-2c963f66afa6",
				"email": "string",
				"firstName": "string",
				"lastName": "string",
				"middleName": "string",
				"position": "string",
				"birthday": "2024-05-23",
				"photoUrl": "string",
				"mobilePhone": "string",
				"extensionPhone": "string",
				"department": {
					"id": "string",
					"name": "string",
					"head": "string",
					"parentId": "string"
				}
			},
			"document": {
				"id": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"title": "string",
				"fileId": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"certificatesStampFileId": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"mimeType": "string",
				"hasSignatures": true
			}
		},
		{
			"id": "3f385f64-1717-4562-b3fc-2c963f66afa6",
			"title": "string",
			"type": "Approval",
			"dueDate": "2024-05-23",
			"state": "Done",
			"assignee": {
				"id": "0fbab271-4ab5-42c8-821a-11573d52fc18",
				"email": "string",
				"firstName": "string",
				"lastName": "string",
				"middleName": "string",
				"position": "string",
				"birthday": "2024-05-23",
				"photoUrl": "string",
				"mobilePhone": "string",
				"extensionPhone": "string",
				"department": {
					"id": "string",
					"name": "string",
					"head": "string",
					"parentId": "string"
				}
			},
			"initiator": {
				"id": "3fa65f64-3717-4562-b3fc-2c963f66afa6",
				"email": "string",
				"firstName": "string",
				"lastName": "string",
				"middleName": "string",
				"position": "string",
				"birthday": "2024-05-23",
				"photoUrl": "string",
				"mobilePhone": "string",
				"extensionPhone": "string",
				"department": {
					"id": "string",
					"name": "string",
					"head": "string",
					"parentId": "string"
				}
			},
			"document": {
				"id": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"title": "string",
				"fileId": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"certificatesStampFileId": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"mimeType": "string",
				"hasSignatures": true
			}
		},
		{
			"id": "3fa85f64-5727-4562-b3fc-2c963f66afa6",
			"title": "string",
			"type": "Information",
			"dueDate": "2024-05-23",
			"state": "Declined",
			"assignee": {
				"id": "32fa85f64-5717-4562-b3fc-2c963f66afa6",
				"email": "string",
				"firstName": "string",
				"lastName": "string",
				"middleName": "string",
				"position": "string",
				"birthday": "2024-05-23",
				"photoUrl": "string",
				"mobilePhone": "string",
				"extensionPhone": "string",
				"department": {
					"id": "string",
					"name": "string",
					"head": "string",
					"parentId": "string"
				}
			},
			"initiator": {
				"id": "3fa853f64-5717-4562-b3fc-2c963f66afa6",
				"email": "string",
				"firstName": "string",
				"lastName": "string",
				"middleName": "string",
				"position": "string",
				"birthday": "2024-05-23",
				"photoUrl": "string",
				"mobilePhone": "string",
				"extensionPhone": "string",
				"department": {
					"id": "string",
					"name": "string",
					"head": "string",
					"parentId": "string"
				}
			},
			"document": {
				"id": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"title": "string",
				"fileId": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"certificatesStampFileId": "e198046a-aace-45c8-97ad-da9c858a94ac",
				"mimeType": "string",
				"hasSignatures": true
			}
		}
	]
};

export const getMocks = <T = void>(mocks?: any): Promise<T> => new Promise(resolve => setTimeout(() => resolve(mocks), 200));

export const roundDownToDecimalPlace = (value: number, fractionDigits: number = 1) => {
	return Math.floor(value * (10 * fractionDigits)) / (10 * fractionDigits);
};

export const sumFloat = (a: number, b: number) => {
	return parseFloat((a + b).toFixed(2));
};

export const inRangeInclusive = (value: number, start: number, end: number): boolean => {
	return value >= start && value <= end;
};

export const isRangesOverlapping = (start1: number, end1: number, start2: number, end2: number): boolean => {
	return inRangeInclusive(start1, start2, end2) || inRangeInclusive(end1, start2, end2);
};

export default class ApiBodFile {
	id: string;
	title: string;
	contentType: string;
	size: number;

	constructor(
		id: string = "",
		title: string = "",
		contentType: string = "",
		size: number = 0
	)
	{
		this.id = id;
		this.title = title;
		this.contentType = contentType;
		this.size = size;
	}
}

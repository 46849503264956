import ApiBodFile from "@/api/bod/types/apiBodFile";
import ApiSection from "@/api/bod/types/apiSection";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiBodUser from "@/api/bod/types/apiBodUser";

export default class ApiChangeRequestsListItem {
	id: string;
	createdAt: string;
	title: string;
	section: ApiSection;
	financeSchemes: ApiFinanceScheme[];
	responsibleDepartment: ApiDepartment;
	fileModel: ApiBodFile;
	description: string;
	changeWarrant: string;
	changeWarrantFile: ApiBodFile;
	type: string;
	status: string;
	initiator: ApiBodUser;

	constructor(
		id: string = "",
		createdAt: string = "",
		title: string = "",
		section: ApiSection = new ApiSection(),
		financeSchemes: ApiFinanceScheme[] = [],
		responsibleDepartment: ApiDepartment = new ApiDepartment(),
		fileModel: ApiBodFile = new ApiBodFile(),
		description: string = "",
		changeWarrant: string = "",
		changeWarrantFile: ApiBodFile = new ApiBodFile(),
		type: string = "",
		status: string = "",
		initiator: ApiBodUser = new ApiBodUser()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.title = title;
		this.section = section;
		this.financeSchemes = financeSchemes;
		this.responsibleDepartment = responsibleDepartment;
		this.fileModel = fileModel;
		this.description = description;
		this.changeWarrant = changeWarrant;
		this.changeWarrantFile = changeWarrantFile;
		this.type = type;
		this.status = status;
		this.initiator = initiator;
	}
}

<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: 16px">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<vue-dropzone :name="type"
					  ref="barDropzone"
					  id="dropzone"
					  v-if="!fileData"
					  :options="dropzoneOptions"
					  :useCustomSlot="true"
					  :disabled="disabled"
					  :class="{'bar-dropzone--disabled': disabled}"
					  @vdropzone-file-added="handleFileAdded"
					  class="white rounded-lg pa-2 d-flex align-center flex-wrap justify-center frp-dropzone bar-dropzone"
					  :style="`min-height: ${height}px !important; cursor: ${disabled ? 'default' : 'pointer'}`">
			<div class="d-flex align-center">
				<span class="bar-dropzone-title grey--text" :class="{'bar-dropzone--text-disabled': disabled}">
					{{ title }} {{ $t("fields.barDropzone.title.maxFileWeight", { weight: maxSize }) }}
				</span>
			</div>
		</vue-dropzone>
		<div v-else>
			<v-row justify="space-between"
				   class="bar-file align-center px-0 mx-0"
				   style="width:100%"
				   :class="label ? 'bar-file--label' : ''">
				<v-col cols="auto" md="6" lg="6">
					<frp-file class="text-break"
							  :name="filename"
							  :uploading="isLoading"
							  bold-text>
					</frp-file>
				</v-col>
				<v-col cols="auto">
					<template v-if="$slots['file-action']">
						<slot name="file-action"></slot>
					</template>
					
					<frp-btn :outlined="!!$slots['file-action']"
							 elevation="0"
							 :disabled="isUploading"
							 :color="$slots['file-action'] ? colors.primary.base : colors.blue.base"
							 @click="onDelete(filename)">
						<span :class="$slots['file-action'] ? 'primary--text' : 'white--text'">{{ $t("buttons.remove") }}</span>
					</frp-btn>
				</v-col>
			</v-row>
		</div>
		<div v-if="subtitle" class="mt-7">{{ subtitle }}</div>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import { FileTypes } from "@/constants/fileTypes";
import { i18n } from "@/plugins";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";
import VueDropzone from "vue2-dropzone";
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";

const DEFAULT_URL = "http://localhost";

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		maxSize: {
			type: [Number, String],
			default: 25
		},
		formats: Array,
		file: [File, Object],
		label: String,
		title: {
			type: String,
			default: i18n.t("fields.barDropzone.title.uploadSinglePdfFile")
		},
		isUploading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		height: {
			type: [String, Number],
			default: 44
		},
		subtitle: String
	},
	data() {
		return {
			FileTypes,
			dropzoneOptions: {
				autoProcessQueue: false,
				url: DEFAULT_URL,
				maxFilesize: this.maxSize,
				acceptedFiles: "",
				maxFiles: 1
			},
			allowedFormats: [],
			noMimeFormats: [],
			fileData: null,
			isLoading: false,
			isValidFormat: false
		};
	},
	computed: {
		filename() {
			return this.fileData.name;
		},
		isDisabled() {
			return this.disabled;
		}
	},
	methods: {
		onDelete(name) {
			this.fileData = null;
			this.$emit("file:delete", name);
		},
		async handleFileAdded(file) {
			this.isLoading = true;

			try {
				const preparedFile = new File([file], file.name, {
				  lastModified: file.lastModified,
				  type: file.type || FileTypes.DEFAULT
				});

				const { type } = preparedFile;

				this.fileData = preparedFile;
				this.$emit("file:add", preparedFile, type);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isLoading = false;
			}
		},
		setAcceptedFiles() {
			if(!this.formats)
				return;
			
			const res = [];
			
			this.formats.forEach(format => res.push(`.${format}`));
			
			this.allowedFormats = res;
			this.dropzoneOptions.acceptedFiles = res.join(",");
		}
	},
	watch: {
		formats: {
			handler(formats) {
				this.setAcceptedFiles(formats);
			},
			immediate: true
		},
		fileData: {
			handler(file) {
				if(!file) return;
				
				const isValid = [FileTypes.DEFAULT, ...this.allowedFormats].some(x => file.name.endsWith(x));
				
				this.$emit("format:is-valid", isValid);
			},
			immediate: true
		},
		file: {
			handler(file) {
				if(!file)
					this.fileData = null;
			},
			immediate: true
		},
		isDisabled: {
			handler(value) {
				if(value) {
					this.$nextTick(() => {
						this.$refs.barDropzone.disable();
					});
				} else {
					this.$nextTick(() => {
						this.$refs.barDropzone.enable();
					});
				}
			},
			immediate: true
		}
	},
	components: {
		FrpBtn,
		FrpFile,
		VueDropzone,
		FrpIcon
	}
};
</script>
<style lang="scss" scoped>
.bar-file {
  border: 2px dashed var(--v-grey-base) !important;
  min-height: 72px !important;
  border-radius: 4px !important;

  &--label {
	margin-top: 4px;
  }
}

.bar-dropzone-title {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0.0125em !important;
  line-height: 100% !important;
}
</style>

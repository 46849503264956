import { isEqualWith, isEqual, isNil, isString } from "lodash";
import StateSnapshotService from "@/store/shared/snapshot/stateSnapshotService";
import { SnapshotStateBuilder } from "@/store/shared/snapshot/state";
import { getterTypes, mutationTypes, actionTypes } from "@/store/shared/snapshot/types";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import pick from "lodash/pick";
import { MutationTree, GetterTree, ActionTree } from "vuex";
// @ts-ignore
import assign from "assign-deep";

export default class SnapshotMixinBuilder {
	options: SnapshotOptions[];

	constructor({ options }: { options: SnapshotOptions[] }) {
		this.options = options;
	}

	getOptions() {
		return Object.assign({}, ...this.options.map(item => ({
			[item.key]: {
				fields: item.fields,
				updateDateTime: Date.now()
			}
		})));
	}

	build() {
		const stateSnapshotService = new StateSnapshotService();
		let options: any = {};

		return {
			stateSnapshotService,
			state: () => {
				options = this.getOptions();
				return (new SnapshotStateBuilder({
					options
				})).build();
			},
			getters: <GetterTree<{ snapshot: any }, any>>{
				[getterTypes.stateContainsUnsavedChanges]: state => (key = stateSnapshotKeys.LAST_SAVED) => {
					JSON.stringify(state.snapshot[key]); // Необходимо для пересчета значения геттера
					let { fields } = options[key];
					const currentState = stateSnapshotService.prepareSnapshot(state, fields);
					const lastSavedState = stateSnapshotService.get(key);

					return !isEqual(currentState, lastSavedState);
				}
			},
			mutations: <MutationTree<{ snapshot: any }>>{
				[mutationTypes.SET_STATE_SNAPSHOT](state, key) {
					let { fields } = options[key];
					state.snapshot[key].updateDateTime = Date.now();

					const snapshot = pick(state, fields);

					stateSnapshotService.set(key, snapshot);
				},
				[mutationTypes.ADD_FIELD_TO_SNAPSHOT_OPTION](state, { optionKey, field }) {
					state.snapshot[optionKey].fields.push(field);
				},
				[mutationTypes.REMOVE_FIELD_FROM_SNAPSHOT_OPTION](state, { optionKey, field }) {
					// @ts-ignore
					const index = state.snapshot[optionKey].fields.findIndex(x => x === field);
					state.snapshot[optionKey].fields.splice(index, 1);
				},
				[mutationTypes.ROLLBACK_STATE](state, key) {
					assign(state, stateSnapshotService.get(key));
				}
			},
			actions: <ActionTree<{ snapshot: any }, any>>{
				[actionTypes.cancelChanges]({ commit, dispatch }, key = stateSnapshotKeys.LAST_SAVED) {
					dispatch(actionTypes.cancelChangesBase, key);
				},
				[actionTypes.cancelChangesBase]({ commit, dispatch }, key) {
					commit(mutationTypes.ROLLBACK_STATE, key);
				}
			}
		};
	}
}

import { RouteNames } from "@/router/tranches/routes";
import { Permissions } from "@/constants/permissions";

const routeToPermissionsMap = new Map<RouteNames, Permissions[]>([
		[RouteNames.ROOT, []],
		[RouteNames.TRANCHES_TRANCHE_APPLICATION, [Permissions.TRANCHES_TRANCHE_SCHEDULE_READ]],
		[RouteNames.CALLBACK, []],
		[RouteNames.SILENT_RENEW, []],
		[RouteNames.ERROR_PAGE_NOT_FOUND, []],
		[RouteNames.ERROR_ACCESS_DENIED, []],
		[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],
	]
);

export default routeToPermissionsMap;

import "reflect-metadata";
import Vue from "vue";
import App from "@/TranchesApp.vue";
import router from "@/router/tranches";
import store from "@/store/tranches";
import { i18n, vuetify } from "@/plugins";
import { sync } from "vuex-router-sync";
import "@/assets/main.css";
import configureApi from "@/api/index";
import { setEnvironmentVariablesInWindowEnv } from "@/utils/development/setEnvironmentVariablesInWindowEnvironment";
import PermissionsResolver from "@/api/authorization/permissionsResolver";

const permissionsResolver = new PermissionsResolver();
permissionsResolver.resetPermissionsStorage();

configureApi();

sync(store, router);
Vue.config.productionTip = false;

setEnvironmentVariablesInWindowEnv();

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import embeddedFormTypes from "@/store/shared/embeddedForm/types";

export const getterTypes = {
	...embeddedFormTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...embeddedFormTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...embeddedFormTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_PASSWORD: "SET_PASSWORD",
	SET_CONFIRMED_PASSWORD: "SET_CONFIRMED_PASSWORD"
};

const embeddedPasswordFormTypes = {
	actionTypes, getterTypes, mutationTypes
};

export default embeddedPasswordFormTypes;

import ApiBodUser from "@/api/bod/types/apiBodUser";
import ApiBodAcceptorBase from "@/api/bod/types/apiBodAcceptorBase";
import { Type } from "class-transformer";

export default class ApiBodPersonAcceptor extends ApiBodAcceptorBase {
	@Type(() => ApiBodUser)
	user: ApiBodUser;

	constructor(
		title: string = "",
		type: string = "",
		acceptedAt: string = "",
		declinedAt: string = "",
		declineReason: string = "",
		user: ApiBodUser = new ApiBodUser()
	)
	{
		super(title, type, acceptedAt, declinedAt, declineReason);

		this.user = user;
	}
}

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "requests";

export const getterTypes = {
	...listingGetterTypes,
	users: "users"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchInitiatorUsers: "fetchInitiatorUsers",
	fetchSections: "fetchSections"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_TEXT: "SET_FILTER_TEXT",
	SET_FILTER_SECTION_IDS: "SET_FILTER_SECTION_IDS",
	SET_FILTER_INITIATOR_IDS: "SET_FILTER_INITIATOR_IDS",
	SET_FILTER_STATUSES: "SET_FILTER_STATUSES",
	RESET_FILTER: "RESET_FILTER",
	SET_IS_USERS_LOADING: "SET_IS_USERS_LOADING",
	SET_IS_SECTIONS_LOADING: "SET_IS_SECTIONS_LOADING",
	SET_SECTIONS: "SET_SECTIONS",
	SET_USERS: "SET_USERS"
};

const requestsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default requestsTypes;

import baseStepTypes from "@/store/shared/baseStep/types";
import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "master-account-access-final-step";

export const getterTypes = {
	...baseStepTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseStepTypes.actionTypes,
	...baseMixinTypes.actionTypes
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...baseStepTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes
};

const masterAccountAccessFinalStepTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default masterAccountAccessFinalStepTypes;

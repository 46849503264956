import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "trust";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	details: "details",
	isSnilsEmpty: "isSnilsEmpty"
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	handleTrustFileUpload: "handleTrustFileUpload",
	acceptTrust: "acceptTrust",
	declineTrust: "declineTrust",
	cancelTrustAccept: "cancelTrustAccept",
	fetchChangeStatusEmployeeInfo: "fetchChangeStatusEmployeeInfo",
	fetchCertificateInfo: "fetchCertificateInfo",
	removeTrustFile: "removeTrustFile",
	findCurrentActiveTrust: "findCurrentActiveTrust"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_EMPLOYEE_ID: "SET_EMPLOYEE_ID",
	SET_COUNTERPARTY_ID: "SET_COUNTERPARTY_ID",
	SET_TRUST: "SET_TRUST",
	SET_TRUST_EXPIRE_AT: "SET_TRUST_EXPIRE_AT",
	SET_TRUST_TEMP_FILE_ID: "SET_TRUST_TEMP_FILE_ID",
	SET_TRUST_FILE_ID: "SET_TRUST_FILE_ID",
	SET_TRUST_IS_SIGNED: "SET_TRUST_IS_SIGNED",
	SET_TRUST_ACCEPTED_AT: "SET_TRUST_ACCEPTED_AT",
	SET_TRUST_ACCEPTOR_OFFICE_EMPLOYEE_ID: "SET_TRUST_ACCEPTOR_OFFICE_EMPLOYEE_ID",
	SET_TRUST_DECLINED_AT: "SET_TRUST_DECLINED_AT",
	SET_TRUST_DECLINER_OFFICE_EMPLOYEE_ID: "SET_TRUST_DECLINER_OFFICE_EMPLOYEE_ID",
	SET_TRUST_STATUS: "SET_TRUST_STATUS",
	SET_TRUST_FILE_META: "SET_TRUST_FILE_META",
	SET_TRUST_FILE_META_IS_LOADING: "SET_TRUST_FILE_META_IS_LOADING",
	SET_TRUST_CANCELED_AT: "SET_TRUST_CANCELED_AT",
	SET_TRUST_CANCELLER_OFFICE_EMPLOYEE_ID: "SET_TRUST_CANCELLER_OFFICE_EMPLOYEE_ID",
	SET_IS_DECLINING: "SET_IS_DECLINING",
	SET_IS_ACCEPTING: "SET_IS_ACCEPTING",
	SET_IS_CANCELLING: "SET_IS_CANCELLING",
	SET_ACCEPTED_EMPLOYEE: "SET_ACCEPTED_EMPLOYEE",
	SET_DECLINED_EMPLOYEE: "SET_DECLINED_EMPLOYEE",
	SET_CANCELED_EMPLOYEE: "SET_CANCELED_EMPLOYEE",
	SET_CURRENT_ACTIVE_TRUST_ID: "SET_CURRENT_ACTIVE_TRUST_ID",
	SET_CERTIFICATE_INFO: "SET_CERTIFICATE_INFO",
	RESET_DECLINE_REASON: "RESET_DECLINE_REASON",
	SET_DECLINE_REASON: "SET_DECLINE_REASON",
	RESET_TRUST_ACTIONERS: "RESET_TRUST_ACTIONERS",
	SET_TRUST_ACCEPTOR_ACTIONER: "SET_TRUST_ACCEPTOR_ACTIONER",
	SET_TRUST_CANCELLER_ACTIONER: "SET_TRUST_CANCELLER_ACTIONER",
	SET_TRUST_DECLINER_ACTIONER: "SET_TRUST_DECLINER_ACTIONER"
};

const counterpartyEmployeeTrustTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default counterpartyEmployeeTrustTypes;

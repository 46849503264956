import ApiAccount from "@/api/types/account/apiAccount";
import { Type } from "class-transformer";
import "reflect-metadata";

export default class ApiAccountPersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;
	@Type(() => ApiAccount)
	account: ApiAccount;

	constructor(id = "", createdAt = "", updatedAt = "", account = new ApiAccount()) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.account = account;
	}
}

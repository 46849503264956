<template>
</template>

<script>
import userManager from "Services/auth/userManager";

export default {
	async beforeCreate() {
		try {
			await userManager.signinSilentCallback();
		} catch (e) {
			console.error(e);
			await userManager.signoutRedirect();
		}
	}
};
</script>

import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";

export default class ApiLegalEntityCounterpartyPersisted extends ApiCounterpartyPersistedBase {
	counterparty: ApiLegalEntityCounterparty;

	constructor(id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		counterparty: ApiLegalEntityCounterparty = new ApiLegalEntityCounterparty())
	{
		super(id, createdAt, updatedAt, counterparty);

		this.counterparty = counterparty;
	}
}

export default class ApiLegalEntityHead {
	position: string;
	fullName: string;
	inn: string;
	snils: string;
	description: string;

	constructor(position: string, fullName: string, inn: string, snils: string, description: string) {
		this.position = position;
		this.fullName = fullName;
		this.inn = inn;
		this.snils = snils;
		this.description = description;
	}
}

<template>
	<v-footer fixed height="64" color="primary lighten-1">
		<router-link :to="{ name: RouteNames.ROOT }">
			<frp-icon class="mr-8" src="ico_tranches-logo" :color="colors.white.base"></frp-icon>
		</router-link>

		<v-spacer></v-spacer>

		<slot></slot>
	</v-footer>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { RouteNames } from "Router/tranches/routes";

export default {
	mixins: [colorsMixin],
	data() {
		return {
			RouteNames
		};
	},
	components: { FrpIcon }
};
</script>

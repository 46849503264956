import Breadcrumb from "@/store/tranches/modules/breadcrumbs/types/breadcrumb";

export default class BreadcrumbsState {
	items: Breadcrumb[];
	isLoading: boolean;

	constructor(items = [],
		isLoading = false)
	{
		this.items = items;
		this.isLoading = isLoading;
	}
}

import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import baseMixinTypes from "@/store/shared/base/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "counterparties";

export const getterTypes = {
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_TYPE: "SET_FILTER_TYPE"
};

const counterpartiesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default counterpartiesTypes;

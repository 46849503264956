import AlertsStateModel from "@/store/modules/alerts/models/alertsStateModel";
import { mutationTypes, namespace, actionTypes } from "@/store/modules/alerts/types";
import { MutationTree } from "vuex";
import AlertModel from "@/store/modules/alerts/models/alertModel";

const state = new AlertsStateModel();

const getters = {};

const actions = {};

const mutations = <MutationTree<AlertsStateModel>>{
	[mutationTypes.ADD_ERROR_ALERT](state, value) {
		state.errorAlerts.push(new AlertModel(state.nextAlertId++, value, 8000));
	},
	[mutationTypes.REMOVE_ERROR_ALERT](state: AlertsStateModel, id) {
		state.errorAlerts = state.errorAlerts.filter(x => x.id !== id);
	},
	[mutationTypes.ADD_INFO_ALERT](state, value) {
		state.infoAlerts.push(new AlertModel(state.nextAlertId++, value, 3000));
	},
	[mutationTypes.REMOVE_INFO_ALERT](state, id) {
		state.infoAlerts = state.infoAlerts.filter(x => x.id !== id);
	}
};

export {
	namespace, state, getters, actions, mutations
};

export default {
	namespace, state, getters, actions, mutations, namespaced: true
};

import UserState from "@/store/tranches/modules/user/types/userState";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/tranches/modules/user/types";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SecurityService from "@/services/auth/securityService";
import AbortService from "@/services/abortService";
import PermissionsResolver from "@/api/authorization/permissionsResolver";
import { ProfileType } from "@/types/ProfileType";
import { plainToInstance } from "class-transformer";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@/api/types/account/profile/apiEntrepreneurProfile";
import ApiForeignOrganizationProfile from "@/api/types/account/profile/apiForeignOrganizationProfile";
import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";

const abortService = new AbortService();

const permissionsResolver = new PermissionsResolver();
const securityService = new SecurityService();

const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = new UserState();

const getters = <GetterTree<UserState, any>>{};

const actions = <ActionTree<UserState, any>>{
	...baseMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchUser);
		await dispatch(actionTypes.fetchPermissions);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchUser]({ commit }) {
		commit(mutationTypes.SET_IS_USER_LOADING, true);
		
		try {
			let { account, profile } = await securityService.getUser();
			
			commit(mutationTypes.SET_ACCOUNT, account);
			
			if(profile) {
				switch (profile.type) {
					case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiLegalEntityProfile, profile));
						break;
					case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiEntrepreneurProfile, profile));
						break;
					case ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiForeignOrganizationProfile, profile));
						break;
					default:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiAccountProfileBase, profile));
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USER_LOADING, false);
		}
	},
	async [actionTypes.fetchPermissions]({ commit }) {
		commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);

		try {
			let permissions = await permissionsResolver.resolve();
			commit(mutationTypes.SET_PERMISSIONS, permissions);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);
		}
	}
};

const mutations = <MutationTree<UserState>>{
	...baseMixin.mutations,
	[mutationTypes.SET_IS_USER_LOADING](state, value) {
		state.isUserLoading = value;
	},
	[mutationTypes.SET_IS_PERMISSIONS_LOADING](state, value) {
		state.isPermissionsLoading = value;
	},
	[mutationTypes.SET_PROFILE](state, value) {
		state.user.profile = value;
	},
	[mutationTypes.SET_ACCOUNT](state, value) {
		state.user.account = value;
	},
	[mutationTypes.SET_PERMISSIONS](state, value) {
		state.user.permissions = value;
	}
};

export {
	namespace, state, actions, mutations, getters
};

const userModule = {
	namespace, state, actions, mutations, namespaced: true, getters
};

export default userModule;

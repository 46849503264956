import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "upcoming-payments";

export const getterTypes = {};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	updateListingItems: "updateListingItems",
	fetchAgreement: "fetchAgreement",
	downloadPaymentOrder: "downloadPaymentOrder"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_IS_AGREEMENT_LOADING: "SET_IS_AGREEMENT_LOADING",
	SET_AGREEMENT: "SET_AGREEMENT",
	SET_FINANCE_SOURCES: "SET_FINANCE_SOURCES",
	SET_INTEREST_DETAILS_ITEMS: "SET_INTEREST_DETAILS_ITEMS",
	SET_PAYMENTS: "SET_PAYMENTS",
	SET_IS_ITEMS_LOADING: "SET_IS_ITEMS_LOADING",
	SET_PAYMENT_IS_EXPANDED: "SET_PAYMENT_IS_EXPANDED",
	ADD_LOADING_FILE: "ADD_LOADING_FILE",
	SET_LOADING_FILES: "SET_LOADING_FILES",
	SET_ISSUED_SUM: "SET_ISSUED_SUM",
};

const upcomingPaymentsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default upcomingPaymentsTypes;

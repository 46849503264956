import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "payments";

export const getterTypes = {
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes
};

const paymentsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default paymentsTypes;

import { ApiExpertiseDictionary } from "@/api/expertise/types/apiExpertiseDictionary";

export interface ExpertiseDictionary {
	id: number;
	name: string;
	code: string;
}

export class ExpertiseDictionaryHelper {
	static map(source: ApiExpertiseDictionary): ExpertiseDictionary {
		return {
			id: source.ID,
			name: source.NAME,
			code: source.CODE
		};
	}
}

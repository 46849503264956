<template>
	<frp-card v-if="isInitialized" :title="$t('titles.trancheDisbursement')" class="tranche-disbursement">
		<template #content v-if="trancheSchedule.status === TrancheScheduleStatusTypeEnum.PENDING">
			<v-row class="my-2">
				<v-col class="pt-0 px-6">
						<span class="d-flex">
							<frp-icon class="mr-2"
									  :color="colors.warning.base"
									  src="ico_notification">
							</frp-icon>
							<span class="text-body-2 font-weight-medium warning--text">
								{{ $t("alerts.info.trancheDisbursementAlert") }}
							</span>
						</span>
				</v-col>
			</v-row>
			
			<v-row class="mt-0">
				<v-col class="pt-0">
					<v-expansion-panels accordion>
						<v-expansion-panel v-for="(tranche, i) in trancheSchedule.tranches" :key="i" class="primary--text">
							<v-expansion-panel-header class="pa-4 font-weight-bold" style="background-color: var(--v-primary-lighten4)">
								<div class="d-flex align-center ml-4" style="order: 2">
									<span>{{ tranche.title }}</span>
									
									<v-spacer></v-spacer>
									
									<template v-if="closestConfirmedTranche?.number === tranche.number">
										<template v-if="tranche.status === ApiTrancheStatusTypeEnum.PENDING_APPROVAL">
											<frp-btn v-if="can(Permissions.TRANCHES_TRANCHE_ACCEPT)"
													 @click.stop="isAcceptDialogOpened = true"
													 dense class="mr-2" color="green" elevation="0" outlined>
												{{ $t("buttons.acceptTranche") }}
											</frp-btn>
											<frp-btn v-if="can(Permissions.TRANCHES_TRANCHE_REJECT)"
													 @click.stop="isDeclineReasonDialogOpened = true"
													 dense no-margin color="secondary lighten-2" elevation="0" outlined>
												{{ $t("buttons.declineTranche") }}
											</frp-btn>
										</template>
										<frp-btn
											v-if="readMode && canEditConfirmedTranche && can(Permissions.TRANCHES_TRANCHE_SCHEDULE_MANAGE_FILES)"
											@click.stop="handleOpenUploadDocumentDialog(tranche)"
											dense color="blue" elevation="0" dark>
											{{ $t("buttons.uploadFile") }}
										</frp-btn>
									</template>
									
									<template v-if="tranche.status === ApiTrancheStatusTypeEnum.ACCEPTED">
										<frp-btn v-if="can(Permissions.TRANCHES_TRANCHE_UNACCEPT) && !tranche.orderDate"
												 @click.stop="handleOpenUnacceptDialog(tranche.number)"
												 dense class="mr-2" :color="colors.primary.darken1" elevation="0" outlined>
											{{ $t("buttons.unaccept") }}
										</frp-btn>
										
										<tranches-tranche-state-label :value="tranche.status"></tranches-tranche-state-label>
									</template>
								</div>
							</v-expansion-panel-header>
							
							<v-expansion-panel-content style="font-size: 14px">
								<div class="d-flex align-center px-4 py-3">
									<span class="">{{ $t("content.status") }}</span>
									<v-spacer></v-spacer>
									<div class="d-flex align-center" v-if="tranche.status !== ApiTrancheStatusTypeEnum.DRAFT">
										<span>{{ $t(`aliases.trancheStatus.${tranche.status}`) }}</span>
										
										<frp-btn @click="handleOpenHistoryDialog(tranche)"
												 :loading="isHistoryLoading"
												 x-small height="32" class="ml-2" icon color="primary">
											<frp-icon width="20px" height="20px" src="ico_history"></frp-icon>
										</frp-btn>
									</div>
								</div>
								
								<v-divider></v-divider>
								
								<div class="pa-4">{{ $t("content.documents") }}</div>
								
								<v-divider></v-divider>
								
								<div class="py-1" v-if="readMode && can(Permissions.TRANCHES_TRANCHE_SCHEDULE_MANAGE_AGREEMENTS) &&
																	closestConfirmedTranche?.number === tranche.number &&
																	agreements.length &&
																	canEditConfirmedTranche">
									<frp-checkbox-group :values="tranche.linkedCounterpartyAgreements"
														@update:values="updateTrancheAgreements({ trancheNumber: tranche.number, ids: $event })"
														:disabled="isTrancheAgreementsSaving"
														textColor="blue--text"
														item-style="font-size: 12px !important"
														item-wrapper-style="border-bottom: 1px solid var(--v-black-lighten5); min-height: 39px; padding: 0 12px"
														underline
														gap="0" color="blue" class="ml-1 mr-n1"
														:items="agreements">
										<template #item="{ item }">
											<a target="_blank" :href="getContractLink(item.id)" @click.stop style="color: inherit">
												{{ item.text }}
											</a>
										</template>
									</frp-checkbox-group>
								</div>
								
								<ol v-else-if="tranche.linkedCounterpartyAgreements.length"
									class="px-8 blue--text text-decoration-underline" style="font-size: 12px">
									<li class="py-2"
										v-for="agreement in tranche.linkedCounterpartyAgreements" :key="agreement.id"
										style="border-bottom: 1px solid var(--v-black-lighten5); min-height: 39px; line-height: 22px;">
										<a target="_blank" :href="getContractLink(agreement)" @click.stop style="color: inherit">
											{{
												$t("common.agreement", {
														number: getAgreement(agreement).number,
														date: formatDate(getAgreement(agreement).date, dateFormat)
													}
												)
											}}
										</a>
									</li>
								</ol>
								
								<div>
									<div v-for="(document, i) in tranche.documents" :key="i"
										 class="d-flex align-center"
										 style="border-bottom: 1px solid var(--v-black-lighten5); min-height: 39px; padding: 0 12px">
										<frp-file underline icon-size="24" :id="document.fileId" :name="document.fileName"></frp-file>
										<v-spacer></v-spacer>
										<frp-btn without-padding height="28" small icon color="blue" dark
												 v-if="readMode &&
												 			can(Permissions.TRANCHES_TRANCHE_SCHEDULE_MANAGE_FILES) &&
												 			closestConfirmedTranche?.number === tranche.number &&
												 			canEditConfirmedTranche"
												 :loading="deletingDocumentIds.includes(document.fileId)"
												 @click="deleteTrancheDocument({ trancheNumber: tranche.number, document })">
											<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
										</frp-btn>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
			
			<tranches-tranche-history-dialog v-model="isHistoryDialogOpen"/>
			<tranches-upload-tranche-document-dialog v-model="isUploadDocumentDialogOpened"/>
			<tranches-decline-reason-dialog v-model="isDeclineReasonDialogOpened"/>
			<tranches-confirm-dialog @submit="handleAcceptTranche"
									 :loading="isTrancheAccepting"
									 :type="TrancheConfirmDialogTypeEnum.TRANCHE_CONFIRM_ACCEPT"
									 v-model="isAcceptDialogOpened"/>
			<tranches-confirm-dialog @submit="handleUnacceptTranche"
									 :loading="isUnaccepting"
									 :type="TrancheConfirmDialogTypeEnum.TRANCHE_CONFIRM_UNACCEPT"
									 v-model="isUnacceptDialogOpened"/>
		</template>
	</frp-card>
	
	<tranches-tranche-application-tranche-schedule-loader v-else/>
</template>

<script>
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import LoanConfirmDialog from "@/components/dialogs/LoanConfirmDialog.vue";
import FrpCheckboxGroup from "@/components/fields/FrpCheckboxGroup.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import TranchesTrancheStateLabel from "@/components/labels/TranchesTrancheStateLabel.vue";
import { FileTypes } from "@/constants/fileTypes";
import { externalUrls } from "@/constants/tranches/externalUrls";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import {
	TrancheScheduleOrderConditionModeTypeEnum
} from "@/store/tranches/modules/application/types/TrancheScheduleOrderConditionModeTypeEnum";
import { TrancheScheduleSnapshotKeysEnum } from "@/store/tranches/modules/application/types/TrancheScheduleSnapshotKeysEnum";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import { TrancheConfirmDialogTypeEnum } from "@/types/tranches/TrancheConfirmDialogTypeEnum";
import { sumFloat } from "@/utils/number";
import TranchesConfirmDialog from "@/views/tranches/dialogs/TranchesConfirmDialog.vue";
import TranchesDeclineReasonDialog from "@/views/tranches/dialogs/TranchesDeclineReasonDialog.vue";
import TranchesTrancheHistoryDialog from "@/views/tranches/dialogs/TranchesTrancheHistoryDialog.vue";
import TranchesUploadTrancheDocumentDialog from "@/views/tranches/dialogs/TranchesUploadTrancheDocumentDialog.vue";
import TranchesTrancheApplicationTrancheScheduleAcceptTable
	from "@/views/tranches/sections/trancheSchedule/acceptTable/TranchesTrancheApplicationTrancheScheduleAcceptTable.vue";
import TranchesTrancheApplicationTrancheScheduleOrderApplicationTableItem
	from "@/views/tranches/sections/trancheSchedule/orderApplicationTableItem/TranchesTrancheApplicationTrancheScheduleOrderApplicationTableItem.vue";
import TranchesTrancheApplicationTrancheScheduleLoader
	from "@/views/tranches/sections/trancheSchedule/TranchesTrancheApplicationTrancheScheduleLoader.vue";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import { cloneDeep } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import { listMixin } from "Mixins/listMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage, listMixin, authorizationMixin],
	data() {
		return {
			namespace,
			FileTypes,
			TrancheScheduleStatusTypeEnum,
			TrancheConfirmDialogTypeEnum,
			ApiTrancheStatusTypeEnum,
			TrancheScheduleModeTypeEnum,
			TrancheScheduleOrderConditionModeTypeEnum,
			TrancheScheduleSnapshotKeysEnum,
			formatCurrency,
			formatDate,
			cloneDeep,
			dateFormat,
			isHistoryDialogOpen: false,
			isUploadDocumentDialogOpened: false,
			isAcceptDialogOpened: false,
			isDeclineReasonDialogOpened: false,
			isUnacceptDialogOpened: false,
			isHistoryLoading: false,
			toUnacceptTrancheNumber: undefined
		};
	},
	computed: {
		...mapGetters({
			closestConfirmedTranche: getterTypes.closestConfirmedTranche,
			canEditConfirmedTranche: getterTypes.canEditConfirmedTranche
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			mode: state => state.mode,
			isUnaccepting: state => state.isUnaccepting,
			deletingDocumentIds: state => state.deletingDocumentIds,
			orderConditionMode: state => state.orderConditionMode,
			isTrancheAccepting: state => state.isTrancheAccepting,
			editableItems: state => state.editableItems,
			editableOrderConditionItems: state => state.editableOrderConditionItems,
			isTrancheScheduleSaving: state => state.isTrancheScheduleSaving,
			trancheSchedule: state => state.trancheSchedule,
			projectInfo: state => state.projectInfo,
			isTrancheAgreementsSaving: state => state.isTrancheAgreementsSaving,
			isTrancheScheduleForApprovalByManagerSending: state => state.isTrancheScheduleForApprovalByManagerSending,
			isTrancheScheduleUnsavedChangesDialogEnabled: state => state.isTrancheScheduleUnsavedChangesDialogEnabled
		}),
		agreements() {
			return this.projectInfo.counterpartyAgreements.map(x => ({
				...x,
				text: this.$t("common.agreement", { number: x.number, date: formatDate(x.date, dateFormat) }),
				value: x.id
			}));
		},
		readMode() {
			return this.mode === TrancheScheduleModeTypeEnum.READ;
		}
	},
	methods: {
		...mapActions({
			rollback: actionTypes.cancelChanges,
			addEditableItemTranche: actionTypes.addEditableItemTranche,
			deleteEditableItemTranche: actionTypes.deleteEditableItemTranche,
			fetchHistory: actionTypes.fetchHistory,
			deleteTrancheDocument: actionTypes.deleteTrancheDocument,
			updateTrancheAgreements: actionTypes.updateTrancheAgreements,
			unacceptTranche: actionTypes.unacceptTranche,
			acceptTranche: actionTypes.acceptTranche,
			rejectTranche: actionTypes.rejectTranche
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			setEditableItems: mutationTypes.SET_EDITABLE_ITEMS,
			setTrancheScheduleSum: mutationTypes.SET_TRANCHE_SCHEDULE_SUM,
			setIsTrancheScheduleUnsavedChangesDialogEnabled: mutationTypes.SET_IS_TRANCHE_SCHEDULE_UNSAVED_CHANGES_DIALOG_ENABLED,
			setCurrentDialogTrancheNumber: mutationTypes.SET_CURRENT_DIALOG_TRANCHE_NUMBER
		}),
		getContractLink(id) {
			return externalUrls.agreementInfo.replace("{projectId}", this.projectInfo.id).replace("{agreementId}", id);
		},
		getSum(items) {
			const sum = items.map(x => x.amount).reduce((acc, x) => sumFloat(acc, +x), 0);
			
			this.setTrancheScheduleSum(sum);
			return sum;
		},
		async handleOpenHistoryDialog(item) {
			this.setCurrentDialogTrancheNumber(item.number);
			this.isHistoryLoading = true;
			
			try {
				await this.fetchHistory();
				this.isHistoryDialogOpen = true;
			} catch (e) {
			} finally {
				this.isHistoryLoading = false;
			}
		},
		async handleOpenUploadDocumentDialog(item) {
			this.setCurrentDialogTrancheNumber(item.number);
			this.isUploadDocumentDialogOpened = true;
		},
		async handleAcceptTranche() {
			await this.acceptTranche();
			this.isAcceptDialogOpened = false;
		},
		async handleUnacceptTranche() {
			await this.unacceptTranche(this.toUnacceptTrancheNumber);
			this.toUnacceptTrancheNumber = undefined;
			this.isUnacceptDialogOpened = false;
		},
		getAgreement(id) {
			return this.projectInfo.counterpartyAgreements.find(x => x.id === id);
		},
		handleOpenUnacceptDialog(trancheNumber) {
			this.toUnacceptTrancheNumber = trancheNumber;
			this.isUnacceptDialogOpened = true;
		}
	},
	components: {
		TranchesDeclineReasonDialog,
		TranchesConfirmDialog,
		TranchesUploadTrancheDocumentDialog,
		TranchesTrancheHistoryDialog,
		TranchesTrancheStateLabel,
		FrpFile,
		FrpCheckboxGroup,
		TranchesTrancheApplicationTrancheScheduleOrderApplicationTableItem,
		TranchesTrancheApplicationTrancheScheduleAcceptTable,
		LoanConfirmDialog,
		FrpIcon,
		TranchesTrancheApplicationTrancheScheduleLoader,
		FrpCard,
		FrpAutocomplete,
		FrpDateField,
		FrpTextField,
		FrpBtn
	}
};
</script>
<style lang="scss">
.tranche-disbursement {
  .v-expansion-panel-content__wrap {
	padding: 0;
  }
}
</style>
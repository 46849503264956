import UserState from "@/store/modules/user/types/userState";
import { actionTypes, getterTypes, mutationTypes, namespace, relativeNamespace } from "@/store/modules/user/types";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SecurityService from "@/services/auth/securityService";
import { AuthorizationController } from "@/api/authorization";
import { AccountController } from "@/api/account";
import AbortService from "@/services/abortService";
import { ProfileType } from "@/types/ProfileType";
import { plainToInstance } from "class-transformer";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@/api/types/account/profile/apiEntrepreneurProfile";
import ApiForeignOrganizationProfile from "@/api/types/account/profile/apiForeignOrganizationProfile";
import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import PermissionsResolver from "@/api/authorization/permissionsResolver";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const permissionsResolver = new PermissionsResolver();

const securityService = new SecurityService();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = new UserState();

const getters = <GetterTree<UserState, any>>{
	[getterTypes.profile]: state => {
		return state.user.profile;
	},
	[getterTypes.isMasterAccount]: state => {
		if(state.user.profile) {
			switch (state.user.profile.type) {
				case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
				case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
				case ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION:
				case ProfileType.MASTER_ACCOUNT_OFFICE:
					return true;
			}
		}
	},
	[getterTypes.isCounterpartyEmployee]: state => {
		if(state.user.profile) {
			switch (state.user.profile.type) {
				case ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE:
				case ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE:
				case ProfileType.COUNTERPARTY_PHYSICAL_PERSON_EMPLOYEE:
				case ProfileType.COUNTERPARTY_FOREIGN_ORGANIZATION_EMPLOYEE:
					return true;
				default:
					return false;
			}
		}
	}
};

const actions = <ActionTree<UserState, any>>{
	...baseMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);

		try {
			await dispatch(actionTypes.fetchUserinfo);
			await dispatch(actionTypes.fetchPermissions);
			await dispatch(actionTypes.fetchProfiles);

			commit(mutationTypes.SET_IS_INITIALIZED, true);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	},
	async [actionTypes.fetchPermissions]({ commit }) {
		try {
			let permissions = await permissionsResolver.resolve();

			commit(mutationTypes.SET_PERMISSIONS, permissions);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	},
	async [actionTypes.fetchProfiles]({ commit }) {
		commit(mutationTypes.SET_IS_PROFILES_LOADING, true);

		try {
			let profiles = await accountController.getAccountProfiles();
			profiles = profiles.map((x) => {
				return {
					...x,
					...x.profile
				};
			});

			commit(mutationTypes.SET_PROFILES, profiles);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_PROFILES_LOADING, false);
		}
	},
	async [actionTypes.fetchUserinfo]({ commit }) {
		commit(mutationTypes.SET_IS_USERINFO_LOADING, true);

		try {
			let { account, profile } = await securityService.getUser();

			commit(mutationTypes.SET_ACCOUNT, account);

			if(profile) {
				switch (profile.type) {
					case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiLegalEntityProfile, profile));
						break;
					case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiEntrepreneurProfile, profile));
						break;
					case ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiForeignOrganizationProfile, profile));
						break;
					default:
						commit(mutationTypes.SET_PROFILE, plainToInstance(ApiAccountProfileBase, profile));
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USERINFO_LOADING, false);
		}
	}
};

const mutations = <MutationTree<UserState>>{
	...baseMixin.mutations,
	[mutationTypes.SET_IS_USERINFO_LOADING](state, value) {
		state.user.isUserinfoLoading = value;
	},
	[mutationTypes.SET_IS_PROFILES_LOADING](state, value) {
		state.user.isProfilesLoading = value;
	},
	[mutationTypes.SET_PROFILE](state, value) {
		state.user.profile = value;
	},
	[mutationTypes.SET_ACCOUNT](state, value) {
		state.user.account = value;
	},
	[mutationTypes.SET_PROFILES](state, value) {
		state.user.profiles = value;
	},
	[mutationTypes.SET_PERMISSIONS](state, value) {
		state.user.permissions = value;
	}
};

export {
	namespace, state, actions, mutations, relativeNamespace, getters
};

const userModule = {
	namespace, state, actions, mutations, namespaced: true, relativeNamespace, getters
};

export default userModule;

import embeddedEmailModule from "@/store/shared/embeddedEmailForm";
import embeddedEmailFormTypes, { actionTypes, mutationTypes } from "@/store/shared/embeddedEmailForm/types";
import { ActionTree } from "vuex";
import EmbeddedEmailFormState from "@/store/shared/embeddedEmailForm/types/embeddedEmailFormState";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import CounterpartyMasterAccountState
	from "@/store/modules/counterparty/modules/counterpartyMasterAccount/types/counterpartyMasterAccountState";
import { validateEmail } from "@/utils/validator";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import CounterpartyState from "@/store/modules/counterparty/types/counterpartyState";
import { CounterpartyType } from "@/types/CounterpartyType";
import BatchService from "@/services/batchService";
import { LegalEntityController } from "@/api/counterparty/legalEntity";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import { AccountController } from "@/api/account";
import { abortService } from "@/store/shared/embeddedEmailForm";

export const namespace = "email";

const accountController = new AccountController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);

const checkUniquenessBatchService = new BatchService(({ interval: 500 }));

const actions = <ActionTree<EmbeddedEmailFormState, any>>{
	async [actionTypes.create]({ dispatch, commit, state, rootState }) {
		let { counterparty } = resolveNestedState<CounterpartyState>(rootState, storeManager.counterparty.namespace);

		switch (counterparty.type) {
			case CounterpartyType.LEGAL_ENTITY:
				await legalEntityController.createLegalEntityProfile(counterparty.id, state.email);
				break;
			case CounterpartyType.LEGAL_PERSON:
				await legalPersonController.createEntrepreneurMasterAccountProfile(counterparty.id, state.email);
				break;
		}
	},
	async [actionTypes.update]({ dispatch, commit, state, rootState }) {
		let { counterparty } = resolveNestedState<CounterpartyState>(rootState, storeManager.counterparty.namespace);

		switch (counterparty.type) {
			case CounterpartyType.LEGAL_ENTITY:
				await legalEntityController.updateLegalEntityProfile(counterparty.id, state.email);
				break;
			case CounterpartyType.LEGAL_PERSON:
				await legalPersonController.updateEntrepreneurMasterAccountProfile(counterparty.id, state.email);
				break;
		}
	},
	async [actionTypes.checkIsEmailUnique]({ state, commit, rootState }) {
		if(!validateEmail(state.email)) {
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE, true);
			return;
		}

		try {
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS, true);

			let { accountId } = resolveNestedState<CounterpartyMasterAccountState>(rootState,
				storeManager.counterparty.masterAccount.namespace);

			let exists = null;

			if(accountId)
				exists = await accountController.checkEmailById(accountId, state.email);
			else
				exists = await accountController.checkEmail(state.email);

			commit(mutationTypes.SET_IS_EMAIL_UNIQUE, !exists);
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS, false);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE, true);
		}
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.counterparty.masterAccount.email.namespace, embeddedEmailFormTypes.mutationTypes.SET_EMAIL):
			{
				let emailState = resolveNestedState<EmbeddedEmailFormState>(state, storeManager.counterparty.masterAccount.email.namespace);

				if(emailState.email === emailState.confirmedEmail)
					return;

				checkUniquenessBatchService.push(async () => {
					await dispatch(resolveAction(storeManager.counterparty.masterAccount.email.namespace, actionTypes.checkIsEmailUnique));
				});
				break;
			}

			default:
				break;
		}
	});
};

const counterpartyMasterAccountEmailModule = {
	namespace,
	...embeddedEmailModule,
	actions: {
		...embeddedEmailModule.actions,
		...actions
	},
	subscribe
};

export default counterpartyMasterAccountEmailModule;

import { CounterpartyType } from "@/types/CounterpartyType";
import { IsEnum } from "class-validator";

export default class ApiCounterpartyBase {
	@IsEnum(CounterpartyType)
	type: CounterpartyType;
	isRfrp: boolean;
	isLeasingCompany: boolean;
	isPledgeExpertCompany: boolean;
	isVkmExpertCompany: boolean;
	isFrpAssigneeCompany: boolean;

	constructor(
		type: CounterpartyType = CounterpartyType.UNKNOWN,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false
	)
	{
		this.type = type;
		this.isRfrp = isRfrp;
		this.isLeasingCompany = isLeasingCompany;
		this.isPledgeExpertCompany = isPledgeExpertCompany;
		this.isVkmExpertCompany = isVkmExpertCompany;
		this.isFrpAssigneeCompany = isFrpAssigneeCompany;
	}
}

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "foreign-organization-counterparty";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_COUNTERPARTY_LONG_NAME: "SET_COUNTERPARTY_LONG_NAME",
	SET_COUNTERPARTY_SHORT_NAME: "SET_COUNTERPARTY_SHORT_NAME",
	SET_COUNTERPARTY_COUNTRY_CODE: "SET_COUNTERPARTY_COUNTRY_CODE",
	SET_COUNTERPARTY_LEGAL_ADDRESS: "SET_COUNTERPARTY_LEGAL_ADDRESS",
	SET_COUNTERPARTY_IS_RFRP: "SET_COUNTERPARTY_IS_RFRP",
	SET_COUNTERPARTY_IS_LEASING_COMPANY: "SET_COUNTERPARTY_IS_LEASING_COMPANY",
	SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY: "SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY",
	SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY: "SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY",
	SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY: "SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY",
	SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED: "SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENEDs"
};

const foreignOrganizationCounterpartyTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default foreignOrganizationCounterpartyTypes;

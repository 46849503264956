import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import { ApiTrancheSchedule } from "@/api/tranches/types/apiTrancheSchedule";
import { TrancheScheduleTranche, TrancheScheduleTrancheHelper } from "@/store/tranches/modules/application/types/trancheScheduleTranche";
import { ApiTrancheScheduleTranche } from "@/api/tranches/types/apiTrancheScheduleTranche";

export interface TrancheSchedule {
	borrowerOrganizationId: string;
	status: TrancheScheduleStatusTypeEnum;
	tranches: TrancheScheduleTranche[];
}

export class TrancheScheduleHelper {
	static map(source: ApiTrancheSchedule): TrancheSchedule {
		return {
			borrowerOrganizationId: source.borrowerOrganizationId,
			status: source.status || TrancheScheduleStatusTypeEnum.DRAFT,
			tranches: source.tranches.map((x: ApiTrancheScheduleTranche, index: number) => TrancheScheduleTrancheHelper.map(x, index))
		};
	}
	
	static getEmpty(): TrancheSchedule {
		return {
			borrowerOrganizationId: "",
			status: TrancheScheduleStatusTypeEnum.DRAFT,
			tranches: []
		};
	}
}
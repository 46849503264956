export default class ApiFile {
	data: File;
	name: string;
	namespace: string;
	mimeType: string;
	signature: string;

	constructor(data: File, name: string, namespace: string, mimeType: string, signature: string) {
		this.data = data;
		this.name = name;
		this.namespace = namespace;
		this.mimeType = mimeType;
		this.signature = signature;
	}
}

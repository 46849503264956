import { ApiCounterpartyAgreement } from "@/api/tranches/types/apiCounterpartyAgreement";
import { convertToTimestamp } from "@/utils/dates";

export interface CounterpartyAgreement {
	id: number;
	counterpartyName: string;
	number: string;
	date: number;
	isActive: boolean;
}

export class CounterpartyAgreementMapper {
	static map(source: ApiCounterpartyAgreement): CounterpartyAgreement {
		return {
			...source,
			date: convertToTimestamp(source.date) as number
		};
	}
}

import { CommitOptions, Dispatch, DispatchOptions, MutationPayload, Store, SubscribeOptions } from "vuex";

export default class SubscribersManager<S> {
	store: Store<{}>;

	constructor(store: Store<{}>) {
		this.store = store;
	}

	subscribe<P extends MutationPayload>(fn: (mutation: P, state: S | any) => any, options?: SubscribeOptions): () => void {
		return this.store.subscribe(fn);
	}

	async dispatch(type: string, payload?: any, options?: DispatchOptions): Promise<any> {
		await this.store.dispatch(type, payload);
	}

	commit(type: string, payload?: any, options?: CommitOptions): any {
		this.store.commit(type, payload);
	}
}

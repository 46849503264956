export const actionTypes = {
	save: "save",
	create: "create",
	update: "update"
};

export const getterTypes = {
	isCompleted: "isCompleted"
};

export const mutationTypes = {
	SET_IS_FORM_LOADING: "SET_IS_FORM_LOADING",
	SET_IS_FORM_SAVING: "SET_IS_FORM_SAVING",
	SET_IS_FORM_DISABLED: "SET_IS_FORM_DISABLED",
	SET_IS_FORM_INITIALIZED: "SET_IS_FORM_INITIALIZED",
	SET_IS_FORM_VALID: "SET_IS_FORM_VALID",
	SET_IS_FORM_READONLY: "SET_IS_FORM_READONLY",
	SET_FORM_MODE: "SET_FORM_MODE",
	SET_FORM_EDIT_MODE: "SET_FORM_EDIT_MODE"
};

const embeddedFormTypes = {
	actionTypes,
	getterTypes,
	mutationTypes
};

export default embeddedFormTypes;

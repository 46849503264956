<template>
	<frp-card :title="$t('titles.projectInfo')">
		<template #content>
			<tranches-details-group>
				<tranches-details-item :title="$t('details.titles.loanSum')" text>
					{{ `${formatCurrency(projectInfo.agreement.exactFrpSum, 0)} ${$t("common.rub")}` || "-" }}
				</tranches-details-item>
				
				<tranches-details-item :title="$t('details.titles.project')" text>
					<template v-if="projectInfo?.applicationNumber && projectInfo?.borrowerOrganization?.shortName">
						{{ `${projectInfo.applicationNumber} - ${projectInfo.borrowerOrganization.shortName}` }}
					</template>
					
					<template v-else>-</template>
				</tranches-details-item>
				
				<tranches-details-item :title="$t('details.titles.supervisor')">
					<template v-if="supervisor?.name && supervisor?.email">
						<span class="text-subtitle-2 primary--text">
							{{ supervisor.name }}
						</span>
						<span class="ml-1 text-subtitle-2 primary--text text-decoration-underline">
							{{ supervisor.email }}
						</span>
					</template>
					
					<template v-else>-</template>
				</tranches-details-item>
				
				<tranches-details-item :title="$t('details.titles.projectManager')">
					<template v-if="manager?.name && manager?.email">
						<span class="text-subtitle-2 primary--text">
							{{ manager.name }}
						</span>
						<span class="ml-1 text-subtitle-2 primary--text text-decoration-underline">
							{{ manager.email }}
						</span>
					</template>
					
					<template v-else>-</template>
				</tranches-details-item>
				
				<tranches-details-item :title="$t('details.titles.status')">
					<frp-text-loader v-if="isItemsLoading" height="14" width="140"/>
					
					<div v-else class="d-flex align-center">
						<span class="text-subtitle-2 primary--text">
							{{ $t(`aliases.trancheScheduleMainStatus.${trancheSchedule.status}`) }}
						</span>
						
						<frp-btn @click="handleOpenHistoryDialog"
								 v-if="trancheSchedule.tranches.length && trancheSchedule.status === TrancheScheduleStatusTypeEnum.PENDING"
								 :loading="isHistoryLoading"
								 x-small height="32" class="ml-2" icon color="primary">
							<frp-icon width="20px" height="20px" src="ico_history"></frp-icon>
						</frp-btn>
					</div>
				</tranches-details-item>
			</tranches-details-group>
			
			<tranches-schedule-history-dialog v-model="isHistoryDialogOpen"></tranches-schedule-history-dialog>
		</template>
	</frp-card>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import TranchesDetailsGroup from "@/components/details/TranchesDetailsGroup.vue";
import TranchesDetailsItem from "@/components/details/TranchesDetailsItem.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { actionTypes, namespace } from "@/store/tranches/modules/application/types";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import TranchesScheduleHistoryDialog from "@/views/tranches/dialogs/TranchesScheduleHistoryDialog.vue";
import { createNamespacedHelpers } from "vuex";
import { formatCurrency } from "@/utils/formatting";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			TrancheScheduleStatusTypeEnum,
			formatCurrency,
			isHistoryDialogOpen: false,
			isHistoryLoading: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			projectInfo: state => state.projectInfo,
			trancheSchedule: state => state.trancheSchedule
		}),
		supervisor() {
			return this.projectInfo.agreement.supervisor;
		},
		manager() {
			return this.projectInfo.agreement.manager;
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			fetchHistory: actionTypes.fetchHistory
		}),
		async handleOpenHistoryDialog() {
			this.isHistoryLoading = true;
			
			try {
				await this.fetchHistory();
				this.isHistoryDialogOpen = true;
			} catch (e) {
			}
			finally {
				this.isHistoryLoading = false;
			}
		}
	},
	components: {
		TranchesScheduleHistoryDialog,
		FrpBtn,
		FrpIcon,
		FrpTextLoader,
		FrpTextBtn,
		TranchesDetailsItem,
		TranchesDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>

export const baseActionTypes = {
	initialize: "initialize",
	initializeBase: "initializeBase",
	destroyBase: "destroyBase",
	destroy: "destroy"
};

const mutationTypes = {
	SET_IS_INITIALIZED: "SET_IS_INITIALIZED",
	SET_IS_VACATIONS_LOADED: "SET_IS_VACATIONS_LOADED",
	SET_LAST_VISITED_COUNTERPARTIES: "SET_LAST_VISITED_COUNTERPARTIES",
	BEFORE_INITIALIZED: "BEFORE_INITIALIZED",
	IS_DESTROYED: "IS_DESTROYED"
};

const baseMixinTypes = {
	actionTypes: baseActionTypes,
	mutationTypes
};

export default baseMixinTypes;

import baseStepTypes from "@/store/shared/baseStep/types";
import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "contact-information-step";

export const getterTypes = {
	...baseStepTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseStepTypes.actionTypes,
	...baseMixinTypes.actionTypes
};

export const mutationTypes = {
	...baseStepTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_EMAIL: "SET_EMAIL",
	SET_IS_FORM_VALID: "SET_IS_FORM_VALID"
};

const contactInformationStepTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default contactInformationStepTypes;

import baseStepTypes from "@/store/shared/baseStep/types";
import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "final-step";

export const getterTypes = {
	...baseStepTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseStepTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	processFile: "processFile"
};

export const mutationTypes = {
	...baseStepTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_IS_FORM_VALID: "SET_IS_FORM_VALID",
	SET_INN_DOCUMENT: "SET_INN_DOCUMENT",
	SET_ORDER_DOCUMENT: "SET_ORDER_DOCUMENT"

};

const documentsStepTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default documentsStepTypes;

export default class ApiLegalEntityCounterpartyHead {
	inn: string;
	snils: string;
	fullName: string;
	position: string;
	description: string;

	constructor(inn = "", snils = "", fullName = "", position = "", description = "") {
		this.inn = inn;
		this.snils = snils;
		this.fullName = fullName;
		this.position = position;
		this.description = description;
	}
}


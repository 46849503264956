export enum ApplicationModeType {
	OLK = "OLK",
	LOAN = "LOAN",
	BOD = "BOD",
	BAR = "BAR",
	TRANCHES = "TRANCHES",
	REX = "REX",
	KPI = "KPI",
	HR = "HR"
}

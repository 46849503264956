import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "kpi-criterias-info";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	isReadMode: "isReadMode",
	isEditMode: "isEditMode",
	kpiStaffKpisPeriodYears: "kpiStaffKpisPeriodYears"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	resetFrontFilter: "resetFrontFilter",
	groupByStaffKpiItems: "groupByStaffKpiItems"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_MODE: "SET_MODE",
	SET_IS_KPI_CRITERIAS_FORM_SAVING: "SET_IS_KPI_CRITERIAS_FORM_SAVING",
	SET_IS_KPI_CRITERIAS_FORM_VALID: "SET_IS_KPI_CRITERIAS_FORM_VALID",
	SET_EDITABLE_ITEMS: "SET_EDITABLE_ITEMS",
	SET_STAFF_KPIS_PERIODS: "SET_STAFF_KPIS_PERIODS",
	SET_FRONT_FILTER_YEAR: "SET_FRONT_FILTER_YEAR"
};

const kpiCriteriasInfoTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default kpiCriteriasInfoTypes;

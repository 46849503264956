import baseMixin from "@/store/shared/base/types";
import { formMutationTypes, formActionTypes } from "@/store/shared/form/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "master-account-access";

export const getterTypes = {
	steps: "steps",
	availableSteps: "availableSteps",
	orderedAvailableSteps: "orderedAvailableSteps",
	availableIndexedSteps: "availableIndexedSteps",
	isNextStepEnabled: "isNextStepEnabled",
	isFinalStep: "isFinalStep",
	currentStep: "currentStep",
	isPrevStepEnabled: "isPrevStepEnabled",
	isSelectCounterpartyStep: "isSelectCounterpartyStep"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixin.actionTypes,
	...formActionTypes,
	tryCreateSignedApplication: "tryCreateSignedApplication",
	tryCreateApplicationByDocuments: "tryCreateApplicationByDocuments",
	tryCreateApplicationByEmployeeEmail: "tryCreateApplicationByEmployeeEmail",
	handleNextStep: "handleNextStep",
	moveToFinalStep: "moveToFinalStep",
	moveToDocumentsStep: "moveToDocumentsStep",
	moveToNextStep: "moveToNextStep"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formMutationTypes,
	...baseMixin.mutationTypes,
	SET_CURRENT_STEP_INDEX: "SET_CURRENT_STEP_INDEX",
	SET_APPLICATION_METHOD: "SET_APPLICATION_METHOD",
	SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING: "SET_IS_CREATE_MASTER_ACCOUNT_REQUEST_OPERATION_EXECUTING"
};

const masterAccountAccessTypes = {
	namespace, actionTypes, mutationTypes
};

export default masterAccountAccessTypes;

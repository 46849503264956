import baseMixinTypes from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "office-employee-general";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	create: "create"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_EMPLOYEE: "SET_EMPLOYEE",
	SET_EMPLOYEE_EMAIL: "SET_EMPLOYEE_EMAIL",
	SET_EMPLOYEE_FIRST_NAME: "SET_EMPLOYEE_FIRST_NAME",
	SET_EMPLOYEE_LAST_NAME: "SET_EMPLOYEE_LAST_NAME",
	SET_EMPLOYEE_MIDDLE_NAME: "SET_EMPLOYEE_MIDDLE_NAME",
	SET_EMPLOYEE_SNILS: "SET_EMPLOYEE_SNILS",
	SET_EMPLOYEE_POSITION: "SET_EMPLOYEE_POSITION",
	SET_EMPLOYEE_DESCRIPTION: "SET_EMPLOYEE_DESCRIPTION",
	SET_EMPLOYEE_PHONE: "SET_EMPLOYEE_PHONE",
	SET_EMPLOYEE_EXTENSION_PHONE_NUMBER: "SET_EMPLOYEE_EXTENSION_PHONE_NUMBER",
	SET_EMPLOYEE_IS_ACTIVE: "SET_EMPLOYEE_IS_ACTIVE",
	SET_EMPLOYEE_ROLES: "SET_EMPLOYEE_ROLES",
	SET_ROLES: "SET_ROLES",
	SET_ID: "SET_ID",
	SET_PROFILE: "SET_PROFILE"
};

const officeEmployeeGeneralTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default officeEmployeeGeneralTypes;

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "reporting";

export const getterTypes = {
	...listingGetterTypes,
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	filteredAndSortedItems: "filteredAndSortedItems",
	availableUsers: "availableUsers",
	availableKpis: "availableKpis",
	evaluationReportFileUrl: "evaluationReportFileUrl"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetchUsers: "fetchUsers",
	fetchKpis: "fetchKpis",
	fetchPeriods: "fetchPeriods",
	save: "save",
	updateEditableItems: "updateEditableItems",
	fetchPermissions: "fetchPermissions",
	downloadScoreCards: "downloadScoreCards",
	signScoreCard: "signScoreCard",
	fetchSigningFile: "fetchSigningFile",
	downloadOriginal: "downloadOriginal",
	downloadSigned: "downloadSigned"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_FILTER_YEAR: "SET_FILTER_YEAR",
	SET_FILTER_QUARTER: "SET_FILTER_QUARTER",
	SET_FILTER_KPI_ID: "SET_FILTER_KPI_ID",
	SET_FILTER_STAFF_IDS: "SET_FILTER_STAFF_IDS",
	RESET_FILTER: "RESET_FILTER",
	RESET_FRONT_FILTER: "RESET_FRONT_FILTER",
	SET_IS_USERS_LOADING: "SET_IS_USERS_LOADING",
	SET_IS_KPIS_LOADING: "SET_IS_KPIS_LOADING",
	SET_IS_PERIODS_LOADING: "SET_IS_PERIODS_LOADING",
	SET_KPIS: "SET_KPIS",
	SET_PERIODS: "SET_PERIODS",
	SET_USERS: "SET_USERS",
	SET_EDITABLE_ITEMS: "SET_EDITABLE_ITEMS",
	RESET_EDITABLE_ITEMS: "RESET_EDITABLE_ITEMS",
	SET_EDITABLE_ITEM_SELECTED_CRITERIA_ID: "SET_EDITABLE_ITEM_SELECTED_CRITERIA_ID",
	SET_EDITABLE_ITEM_COMPLETION: "SET_EDITABLE_ITEM_COMPLETION",
	SET_EDITABLE_ITEM_JUSTIFICATION: "SET_EDITABLE_ITEM_JUSTIFICATION",
	SET_IS_PERMISSIONS_LOADING: "SET_IS_PERMISSIONS_LOADING",
	SET_PERMISSIONS: "SET_PERMISSIONS",
	SET_IS_SCORE_CARDS_DOWNLOADING: "SET_IS_SCORE_CARDS_DOWNLOADING",
	SET_DOWNLOAD_SCORE_CARDS_REQUEST_YEAR: "SET_DOWNLOAD_SCORE_CARDS_REQUEST_YEAR",
	SET_DOWNLOAD_SCORE_CARDS_REQUEST_PERIOD: "SET_DOWNLOAD_SCORE_CARDS_REQUEST_PERIOD",
	SET_IS_SIGNING: "SET_IS_SIGNING",
	SET_EVALUATION_REPORT_FILE: "SET_EVALUATION_REPORT_FILE",
	SET_EVALUATION_REPORT_FILE_META: "SET_EVALUATION_REPORT_FILE_META",
	SET_IS_FILE_LOADING: "SET_IS_FILE_LOADING",
	SET_IS_SIGNED_FILE_LOADING: "SET_IS_SIGNED_FILE_LOADING"
};

const reportingTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default reportingTypes;

import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "overdue-interest";

export const getterTypes = {};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_QUARTERS: "SET_QUARTERS",
	SET_FINANCE_SOURCES: "SET_FINANCE_SOURCES",
	SET_PENALTY_TYPES: "SET_PENALTY_TYPES"
};

const overdueInterestTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default overdueInterestTypes;

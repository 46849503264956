<template>
	<v-container fluid class="pa-0 pa-md-3 full-height white darken-2 justify-center" fill-height>
		<v-row class="mx-4">
			<v-col class="d-flex align-center flex-column text-center px-12">
				<v-img :src="imgSrc" alt="500"></v-img>
				<span class="text-h5 grey--text" style="margin-top: 78px">{{ $t("alerts.errors.unexpectedError") }}</span>
				<span class="text-subtitle-1 grey--text mt-6">{{ $t("alerts.errors.somethingWrong") }}</span>
				<div>
					<span class="text-subtitle-1 grey--text">{{ $t("alerts.errors.contactSysAdminOrReturnBack.contact") }}</span>
					<a class="text-subtitle-1 secondary--text" @click="$router.go(-1)">
						{{ $t("alerts.errors.contactSysAdminOrReturnBack.returnBack") }}
					</a>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { RouteNames } from "@/router/routes";

export default {
	data() {
		return {
			RouteNames,
			imgSrc: `${process.env.VUE_APP_BASE_URL}/img/images/500-error.svg`
		};
	}
};
</script>

<style scoped>
.text-subtitle-1 {
	line-height: 24px !important;
}
</style>

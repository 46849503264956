export const namespace = "route";

export const getterTypes = {};

export const actionTypes = {
	processRouteQuery: "processRouteQuery",
	processRouteParams: "processRouteParams",
	processRoute: "processRoute",
	pushRoute: "pushRoute",
	reconstituteRoute: "reconstituteRoute"
};

export const mutationTypes = {
	SET_IS_ROUTE_PROCESSING: "SET_IS_ROUTE_PROCESSING",
	SET_IS_ROUTE_PUSHING: "SET_IS_ROUTE_PUSHING",
	ROUTE_CHANGED: "ROUTE_CHANGED",
};

const routeTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default routeTypes;

<template>
	<v-container fluid>
		<v-row class="tranches-breadcrumbs-wrapper px-0">
			<v-col class="pt-3 pb-3 px-8">
				<v-responsive style="min-height: 14px">
						<template v-if="isLoading">
							<div class="d-flex align-center">
							<frp-text-loader width="100" height="14" class="mr-5"/>
							<frp-text-loader width="200" height="14"/>
							</div>
						</template>
						<template v-else>
							<v-breadcrumbs :items="items" class="pa-0 tranches-breadcrumbs text-subtitle-2"></v-breadcrumbs>
						</template>
				</v-responsive>
			</v-col>
		</v-row>
	</v-container>

</template>

<script>
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import FrpBreadcrumbsLoader from "@/components/loaders/common/FrpBreadcrumbsLoader.vue";
import FrpTitleLoader from "@/components/loaders/common/FrpTitleLoader.vue";
import authorizationMixin from "Mixins/authorizationMixin";
import tranchesBreadcrumbsTypes from "Store/tranches/modules/breadcrumbs/types";
import { mapInstanceActions, mapInstanceState } from "Utils/vuexMapInstanse";

export default {
	mixins: [authorizationMixin],
	props: {
		namespace: {
			type: String,
			required: true
		}
	},
	computed: {
		...mapInstanceState({
			isLoading: state => state.isLoading,
			items: state => state.items.map(x => ({
				...x,
				exact: true
			}))
		}),
		isShown() {
			return this.isLoading || this.items.length;
		}
	},
	methods: {
		...mapInstanceActions({
			processRoute: tranchesBreadcrumbsTypes.actionTypes.processRoute
		})
	},
	components: { FrpTextLoader, FrpBreadcrumbsLoader, FrpTitleLoader }
};
</script>

<style lang="scss">
.tranches-breadcrumbs-wrapper {
	background: var(--v-white-base);
}

.tranches-breadcrumbs {
	font-size: 10px !important;

	.v-breadcrumbs__item {
		color: var(--v-blue-base) !important;

		&--disabled {
			color: var(--v-primary-base) !important;
		}
	}

	.v-breadcrumbs__divider {
		color: var(--v-primary-base) !important;
		padding: 0 8px !important;
	}
}
</style>

export const pagingActionTypes = {
	setPagingPage: "setPagingPage",
	setPagingPageSize: "setPagingPageSize",
	resetPagingPage: "resetPagingPage"
};

export const pagingMutationTypes = {
	SET_PAGING_TOTAL: "SET_PAGING_TOTAL",
	SET_PAGING_PAGE_SIZE: "SET_PAGING_PAGE_SIZE",
	SET_PAGING_PAGE: "SET_PAGING_PAGE"
};

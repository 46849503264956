<template>
	<v-snackbar v-model="isShown"
				elevation="0"
				class="frp-snackbar"
				width="520"
				:color="backgroundColor"
				:timeout="timeout"
				style="position: initial; height: fit-content">
		<div class="d-flex white--text">
			<frp-icon width="20" height="20" v-if="icon" :src="icon" :color="colors.white.base" class="mr-3"></frp-icon>
			<span style="white-space: break-spaces">{{ text }}</span>
		</div>
		<template v-slot:action="{ attrs }">
			<frp-btn icon
					 v-if="closable"
					 v-bind="attrs"
					 @click="close">
				<template>
					<v-icon :color="colors.white.base">mdi-close</v-icon>
				</template>
			</frp-btn>
		</template>
	</v-snackbar>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import alertMixin from "Mixins/alertMixin";
import colorsMixin from "Mixins/colorsMixin";


export default {
	mixins: [colorsMixin, alertMixin],
	props: {
		text: String,
		timeout: Number,
		closable: {
			default: true
		},
		absolute: {
			default: false
		},
		type: {
			type: String
		},
		icon: String
	},
	data() {
		return {
			isShown: true
		};
	},
	watch: {
		isShown(newValue) {
			if(!newValue) {
				this.close();
			}
		}
	},
	methods: {
		close() {
			this.$emit("close");
		}
	},
	components: {
		FrpBtn,
		FrpIcon
	}
};
</script>

<style lang="less">
.frp-snackbar {
  .v-snack {
	z-index: 9999 !important;
  }

  .v-snack__wrapper {
	min-width: unset;
	margin: 0;
  }

  .v-snack__action {
	margin-top: 4px;
	align-self: start;
  }

  .v-snack__content {
	padding-right: 8px;
  }
}
</style>

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "counterparty-employee";

export const getterTypes = {
	counterpartyTitle: "counterpartyTitle"
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	fetch: "fetch",
	activateEmployee: "activateEmployee",
	deactivateEmployee: "deactivateEmployee"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	SET_PROFILE: "SET_PROFILE",
	SET_IS_STATUS_SAVING: "SET_IS_STATUS_SAVING",
	SET_COUNTERPARTY_ID: "SET_COUNTERPARTY_ID",
	SET_ID: "SET_ID",
	SET_EMPLOYEE: "SET_EMPLOYEE",
	SET_EMPLOYEE_IS_ACTIVE: "SET_EMPLOYEE_IS_ACTIVE",
	SET_COUNTERPARTY: "SET_COUNTERPARTY"
};

const counterpartyEmployeeTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default counterpartyEmployeeTypes;

<template>
	<v-container fluid class="pa-0 pa-md-3 full-height white darken-2 justify-center" fill-height>
		<v-row class="mx-4">
			<v-col class="d-flex align-center flex-column text-center px-12">
				<v-img :src="imgSrc" alt="403"></v-img>
				<span class="text-h5 grey--text" style="margin-top: 78px">{{ $t("alerts.errors.accessDenied") }}</span>
				<span class="text-subtitle-1 grey--text mt-6">{{ $t("alerts.errors.notRightsToViewPage") }}</span>
				<div v-if="isCanGoBack">
					<span class="text-subtitle-1 grey--text">Вы можете вернуться на</span>
					<a class="text-subtitle-1 secondary--text" @click="$router.go(-1)">
						{{ $t("alerts.errors.contactSysAdminOrReturnBack.returnBack") }}
					</a>
					<span class="text-subtitle-1 grey--text">или</span>
					<a class="text-subtitle-1 secondary--text" @click="signOut">
						сменить аккаунт
					</a>
				</div>
				<div v-else>
					<span class="text-subtitle-1 grey--text">Вы можете</span>
					<a class="text-subtitle-1 secondary--text" @click="signOut">
						сменить аккаунт
					</a>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/routes";

export default {
	mixins: [authorizationMixin],
	data() {
		return {
			RouteNames,
			imgSrc: `${process.env.VUE_APP_BASE_URL}/img/images/403-error.svg`
		};
	},
	computed: {
		isCanGoBack() {
			return history.length > 2;
		}
	}
};
</script>

<style scoped>
.text-subtitle-1 {
	line-height: 24px !important;
}
</style>

<template>
	<a class="frp-link-btn"
	   :class="[underline ? 'text-decoration-underline' : 'text-decoration-none', classes]"
	   :style="{color}"
	   :href="href"
	   :target="target"
	   v-on="$listeners">
		<template v-if="icon">
			<frp-icon :src="icon" :color="color" class="mr-2"/>
		</template>
		<slot></slot>
	</a>
</template>

<script>
import FrpIcon from "@/components/icon/FrpIcon.vue";

export default {
	components: { FrpIcon },
	props: {
		icon: {
			type: String,
			default: undefined
		},
		color: {
			type: String,
		},
		href: {
			type: String,
			default: undefined
		},
		target: {
			type: String,
			default: "_self"
		},
		underline: {
			type: Boolean,
			default: true
		},
		classes: {
			type: String,
			default: "d-flex"
		}
	}
};
</script>

<style scoped lang="less">
.frp-link-btn {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0;
	color: var(--v-primary-base)
}
</style>

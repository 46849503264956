import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "agreements-accrued-penalties";

export const getterTypes = {
	...listingGetterTypes,
	currentItems: "currentItems"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries",
	switchMode: "switchMode"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_QUARTERS: "SET_QUARTERS",
	SET_FINANCE_SOURCES: "SET_FINANCE_SOURCES",
	SET_AGREEMENT_STATUSES: "SET_AGREEMENT_STATUSES",
	SET_MODE: "SET_MODE",
	SET_SELECTED_ITEMS: "SET_SELECTED_ITEMS",
	ADD_SELECTED_ITEM: "ADD_SELECTED_ITEM",
	REMOVE_SELECTED_ITEM: "REMOVE_SELECTED_ITEM",
	SET_IS_UPDATE_SELECTED_ITEMS_REQUIRED: "SET_IS_UPDATE_SELECTED_ITEMS_REQUIRED",
	SET_FILTER_PROJECT_NUMBER: "SET_FILTER_PROJECT_NUMBER",
	SET_FILTER_ORGANIZATION: "SET_FILTER_ORGANIZATION",
	SET_FILTER_CONTROLLER: "SET_FILTER_CONTROLLER",
	SET_FILTER_STATUSES: "SET_FILTER_STATUSES",
	SET_MAIN_DEBT_ITEMS: "SET_MAIN_DEBT_ITEMS",
	SET_INTEREST_ITEMS: "SET_INTEREST_ITEMS"
};

const agreementsAccruedPenaltiesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default agreementsAccruedPenaltiesTypes;

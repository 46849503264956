export const balancedChunk = (array: object[], size: number) => {
	const result = [];
	const items = [...array];
	for(let i = size; i > 0; i--) {
		result.push(items.splice(0, Math.ceil(items.length / i)));
	}
	return result;
};

export const deleteDoublesInArrayByKeyName = (array: object[], keyName: string) => {
	return array.filter((obj: any, index: number, arr: object[]) => {
		return index === arr.map((innerObj: any) => innerObj[keyName]).indexOf(obj[keyName]);
	});
};

export const flatSubsWithLevels = (childrenKey: string, data: any[]) => {
	const stack = data.map((item, index) => ({ ...item, level: 1 }));
	const result = [];

	while (stack.length) {
		const item = stack.shift()!;

		const { [childrenKey]: subs, ...restItem } = item;
		if(subs)
			stack.unshift(...item[childrenKey].map((child: any) => ({ ...child, level: item.level + 1 })));

		result.push(restItem);
	}

	return result;
};

export const findParentIds = (items: { id: string }[], item: any, parents: string[] = []): string[] => {
	if(!item.parentId)
		return parents;
	return [...findParentIds(items, items.find(x => x.id === item.parentId), [...parents, item.parentId])];
};

export const findDescendants = (items: { parentId: string, id: string }[], id: any, descendants: any[] = []): any[] => {
	const currentItemDescendants = items.filter(x => id === x.parentId);
	if(!currentItemDescendants.length)
		return descendants;

	const res: any[] = [...descendants, ...currentItemDescendants];

	currentItemDescendants.forEach(x => {
		return res.push(...findDescendants(items, x.id, [...descendants]));
	});

	return res;
};

import { RouteNames } from "@/router/routes";
import { Permissions } from "@/constants/permissions";

const routeToPermissionsMap = new Map<RouteNames, Permissions[]>([
		[RouteNames.ROOT, []],
		[RouteNames.APPS, []],
		[RouteNames.CALLBACK, []],
		[RouteNames.SILENT_RENEW, []],
		[RouteNames.ERROR_PAGE_NOT_FOUND, []],
		[RouteNames.ERROR_ACCESS_DENIED, []],
		[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],

		[RouteNames.COUNTERPARTIES, [Permissions.GLOBAL_COUNTERPARTIES_READ]],

		[RouteNames.MASTER_ACCOUNT_ACCESS, []],
		[RouteNames.APPLICATIONS, []],
		[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES, [Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATIONS_READ]],
		[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS, [Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATION_READ]],
		[RouteNames.APPLICATIONS_EMPLOYEE_JOIN_LIST,
			[Permissions.GLOBAL_EMPLOYEE_JOIN_APPLICATIONS_READ, Permissions.OWN_EMPLOYEE_JOIN_APPLICATIONS_READ]],
		[RouteNames.APPLICATIONS_EMPLOYEE_JOIN_DETAILS,
			[Permissions.GLOBAL_EMPLOYEE_JOIN_APPLICATION_READ, Permissions.OWN_EMPLOYEE_JOIN_APPLICATION_READ]],
		[RouteNames.EMPLOYEE_JOIN, [Permissions.OWN_EMPLOYEE_JOIN_APPLICATION_CREATE]],

		[RouteNames.COUNTERPARTY, [Permissions.GLOBAL_COUNTERPARTY_READ, Permissions.OWN_COUNTERPARTY_READ]],
		[RouteNames.COUNTERPARTY_CREATE, [Permissions.GLOBAL_COUNTERPARTY_CREATE]],
		[RouteNames.COUNTERPARTY_GENERAL, [Permissions.GLOBAL_COUNTERPARTY_READ, Permissions.OWN_COUNTERPARTY_READ]],
		[RouteNames.COUNTERPARTY_EMPLOYEES, [Permissions.GLOBAL_COUNTERPARTY_EMPLOYEES_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEES_READ]],
		[RouteNames.COUNTERPARTY_MASTER_ACCOUNT, [Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_READ]],

		[RouteNames.PROFILE_ASSIGNMENT, []],
		[RouteNames.ACCOUNTS, [Permissions.GLOBAL_ACCOUNTS_READ]],
		[RouteNames.ACCOUNT, [Permissions.GLOBAL_ACCOUNT_READ]],
		[RouteNames.ACCOUNT_GENERAL, [Permissions.GLOBAL_ACCOUNT_READ]],
		[RouteNames.ACCOUNT_PROFILES, [Permissions.GLOBAL_ACCOUNT_PROFILES_READ]],
		[RouteNames.CONFIRM_EMAIL, []],
		[RouteNames.CONFIRM_PHONE, []],

		[RouteNames.COUNTERPARTY_EMPLOYEE, [Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_READ]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_CREATE,
			[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_CREATE]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, [Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_READ]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS,
			[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUSTS_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUSTS_READ]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE,
			[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST,
			[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ]],
		[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE,
			[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_CREATE]],

		[RouteNames.OFFICE, [Permissions.GLOBAL_OFFICE_READ]],
		[RouteNames.OFFICE_GENERAL, [Permissions.GLOBAL_OFFICE_READ]],
		[RouteNames.OFFICE_EMPLOYEES, [Permissions.GLOBAL_OFFICE_EMPLOYEES_READ]],
		[RouteNames.OFFICE_EMPLOYEE, [Permissions.GLOBAL_OFFICE_EMPLOYEE_READ]],
		[RouteNames.OFFICE_EMPLOYEE_CREATE, [Permissions.GLOBAL_OFFICE_EMPLOYEE_CREATE]],
		[RouteNames.OFFICE_EMPLOYEE_GENERAL, [Permissions.GLOBAL_OFFICE_EMPLOYEE_READ]],
		[RouteNames.OFFICE_EMPLOYEE_PROFILE, [Permissions.GLOBAL_OFFICE_EMPLOYEE_PROFILE_READ]],

		[RouteNames.REX, []],
	]
);

export default routeToPermissionsMap;

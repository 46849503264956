<template>
	<v-card elevation="0" class="loan-card px-3 py-4">
		<v-row class="px-4 mb-5">
			<v-col cols="auto" class="px-0">
				<div class="d-flex flex-column">
					<frp-text-loader height="14" width="160"/>
				</div>
			</v-col>
		</v-row>

		<v-row class="px-1 pb-2" style="border-bottom: 1px solid var(--v-grey-darken2)">
			<v-col cols="1">
				<v-skeleton-loader type="text" max-width="20"/>
			</v-col>
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="120"/>
			</v-col>
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="120"/>
			</v-col>
		</v-row>
		
		<v-row class="px-1 mt-7" v-for="(i, idx) in 3" :key="i + idx">
			<v-col cols="1">
				<v-skeleton-loader type="text" max-width="20"/>
			</v-col>
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="120"/>
			</v-col>
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="120"/>
			</v-col>
		</v-row>
		
		<v-row class="mt-5 px-1 pb-2" style="border-bottom: 1px solid var(--v-grey-darken2)">
			<v-col cols="3">
				<v-skeleton-loader type="text" width="50"/>
			</v-col>
			<v-col cols="1">
			</v-col>
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="200"/>
			</v-col>
		</v-row>
		
		<v-row class="mt-6 mb-3">
			<v-col cols="6" align-self="end">
				<frp-text-loader height="14" width="160"/>
			</v-col>
			<v-col cols="6" class="px-4 d-flex justify-end">
				<v-row>
					<v-col cols="6" class="d-flex justify-end" v-for="(j, indexJ) in 2" :key="`${j}-${indexJ}`">
						<frp-text-loader height="14" width="80"/>
					</v-col>
					<v-col cols="6" class="d-flex justify-end" v-for="(k, indexK) in 2" :key="`${k}+${indexK}`">
						<frp-text-loader height="14" width="80"/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: { FrpCustomTitleLoader, FrpTextLoader, FrpBtnLoader }
};
</script>

import baseMixinTypes from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "office-employee-profile";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	activateProfile: "activateProfile",
	deactivateProfile: "deactivateProfile",
	requestPhoneConfirmation: "requestPhoneConfirmation",
	toggleSignatureRequired: "toggleSignatureRequired"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_PROFILE: "SET_PROFILE",
	SET_PROFILE_IS_ACTIVE: "SET_PROFILE_IS_ACTIVE",
	SET_IS_SIGNATURE_REQUIRED: "SET_IS_SIGNATURE_REQUIRED",
	SET_IS_SIGNATURE_REQUIRED_TOGGLE_LOADING: "SET_IS_SIGNATURE_REQUIRED_TOGGLE_LOADING",
	SET_ID: "SET_ID",
	SET_ACCOUNT_ID: "SET_ACCOUNT_ID",
	SET_IS_STATUS_SAVING: "SET_IS_STATUS_SAVING",
	SET_IS_PROFILE_PHONE_CONFIRMED: "SET_IS_PROFILE_PHONE_CONFIRMED",
	SET_IS_REQUEST_PHONE_CONFIRMATION_OPERATION_EXECUTING: "SET_IS_REQUEST_PHONE_CONFIRMATION_OPERATION_EXECUTING"
};

const officeEmployeeProfileTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default officeEmployeeProfileTypes;

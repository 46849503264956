import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiGetAccountsParameters from "@/api/types/account/apiGetAccountsParameters";
import ApiAccounts from "@/api/types/account/apiAccounts";
import ApiAccountPersistedBase from "@/api/types/account/apiAccountPersisted";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class AccountController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getAccounts = async (parameters: ApiGetAccountsParameters): Promise<ApiAccounts> => {
		let data = await this.client.get<{ accounts: object[], totalCount: number }>(prepareUrl(urls.account.getAccounts, parameters));
		return new ApiAccounts(plainToInstance(ApiAccountPersistedBase, data.accounts), data.totalCount);
	};

	getAccount = async (id: string): Promise<ApiAccountPersistedBase> => {
		let { account } = await this.client.get<{ account: object }>(urls.account.getAccount.replace(urlTemplateParts.id, id));
		return plainToInstance(ApiAccountPersistedBase, account);
	};

	getAccountProfilesById = async (id: string): Promise<ApiAccountProfilePersistedBase[]> => {
		let { profiles } = await this.client.get<{ profiles: [] }>(urls.account.getProfilesById.replace(urlTemplateParts.id, id));

		return plainToInstance(ApiAccountProfilePersistedBase, profiles);
	};

	checkEmail = async (email: string) => {
		const { exists } = await this.client.post<{ exists: boolean }>(urls.account.checkEmail, { email });

		return exists;
	};

	checkEmailById = async (accountId: string, email: string) => {
		const { exists } = await this.client.post<{ exists: boolean }>(urls.account.checkEmailById.replace(urlTemplateParts.id, accountId),
			{ email });

		return exists;
	};

	getAccountByEmail = async (email: string): Promise<ApiAccountPersistedBase | null> => {
		try {
			let result = await this.client.get<{ counterparty: object }>(prepareUrl(urls.account.getAccountByEmail, {
				email
			}), {});
			return plainToInstance(ApiAccountPersistedBase, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	getAccountProfiles = async (): Promise<ApiAccountProfilePersistedBase[]> => {
		let { profiles } = await this.client.get<{ profiles: [] }>(urls.account.getProfiles);

		return plainToInstance(ApiAccountProfilePersistedBase, profiles);
	};

	updateAccountStatus = async (id: string, isActive: boolean) => {
		return await this.client.put<{ isActive: string }>(urls.account.updateStatus.replace(urlTemplateParts.id, id), { isActive });
	};

	updateAccountEmail = async (id: string, email: string) => {
		return await this.client.put(urls.account.updateEmail.replace(urlTemplateParts.id, id), { email });
	};

	updateAccountPassword = async (id: string, password: string) => {
		return await this.client.put(urls.account.updatePassword.replace(urlTemplateParts.id, id), { password });
	};

	activateProfile = async (accountId: string, profileId: string) => {
		return await this.client.post(urls.profile.activate
										  .replace(urlTemplateParts.id, accountId)
										  .replace(urlTemplateParts.subId, profileId), {});
	};

	deactivateProfile = async (accountId: string, profileId: string) => {
		return await this.client.post(urls.profile.deactivate
										  .replace(urlTemplateParts.id, accountId)
										  .replace(urlTemplateParts.subId, profileId), {});
	};

	toggleSignatureRequired = async (profileId: string, isSignatureRequired: boolean) => {
		return await this.client.post(urls.profile.toggleSignatureRequired.replace(urlTemplateParts.id, profileId), {isSignatureRequired});
	};

	assignProfile = async (id: string) => {
		return await this.client.post(urls.profile.assign.replace(urlTemplateParts.id, id), {});
	};

	getUnassignedProfile = async <T extends ApiAccountProfilePersistedBase>(id: string, Type: { new(): T }): Promise<T> => {
		let profile = await this.client.get(urls.profile.getUnassigned.replace(urlTemplateParts.id, id));
		return plainToInstance(Type, profile);
	};

	generateAndSendCredentials = async (id: string, notificationEmail: string, newEmail: string) => {
		return await this.client.post(urls.account.generateAndSendCredentials.replace(urlTemplateParts.id, id),
			{ notificationEmail, newEmail });
	};

	resetAccountPassword = async (id: string) => {
		return await this.client.post(urls.account.resetPassword.replace(urlTemplateParts.id, id), {});
	};
}

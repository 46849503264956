import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import { Type } from "class-transformer";
import ApiEntrepreneurEmployeeProfile from "@/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiLegalEntityEmployeeProfile from "@/api/types/account/profile/apiLegalEntityEmployeeProfile";
import ApiOfficeEmployeeProfile from "@/api/types/account/profile/apiOfficeEmployeeProfile";
import { ProfileType } from "@/types/ProfileType";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@/api/types/account/profile/apiEntrepreneurProfile";

type Profile =
	| ApiLegalEntityEmployeeProfile
	| ApiOfficeEmployeeProfile
	| ApiEntrepreneurEmployeeProfile
	| ApiAccountProfileBase


export default class ApiAccountProfilePersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;

	@Type(() => ApiAccountProfileBase, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: ApiLegalEntityEmployeeProfile, name: ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE },
				{ value: ApiEntrepreneurEmployeeProfile, name: ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE },
				{ value: ApiOfficeEmployeeProfile, name: ProfileType.OFFICE_EMPLOYEE },
				{ value: ApiLegalEntityProfile, name: ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY },
				{ value: ApiEntrepreneurProfile, name: ProfileType.MASTER_ACCOUNT_ENTREPRENEUR }
			]
		}
	})
	profile: Profile;

	constructor(id = "", createdAt = "", updatedAt = "", profile: Profile = new ApiAccountProfileBase()) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.profile = profile;
	}
}

import { ApiTrancheHistoryItemAuthor } from "@/api/tranches/types/apiTrancheHistoryItemAuthor";
import { ApiTrancheScheduleHistoryItem } from "@/api/tranches/types/apiTrancheScheduleHistoryItem";
import { convertToTimestamp } from "@/utils/dates";

export interface TrancheScheduleHistoryItem {
	changeAt: number;
	author: ApiTrancheHistoryItemAuthor;
	version: number;
}

export class TrancheScheduleHistoryItemMapper {
	static map(source: ApiTrancheScheduleHistoryItem): TrancheScheduleHistoryItem {
		return {
			changeAt: convertToTimestamp(source.changeAt) as number,
			author: source.author,
			version: source.version
		}
	}
}

export const getterTypes = {
	isStepCompleted: "isStepCompleted"
};

export const actionTypes = {};

export const mutationTypes = {
};

const baseStepTypes = {
	actionTypes,
	mutationTypes,
	getterTypes
};

export default baseStepTypes;

<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$t('dialogs.declineReason.title')"
				max-width="490"
				persistent
				@update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="isFormValid">
				<frp-textarea class="kpi-field"
							  v-model="reason"
							  required
							  color="blue"
							  :label="$t('fields.reason.label')"
							  :placeholder="$t('fields.reason.placeholder')">
				</frp-textarea>
			</v-form>
		</template>

		<template #footer>
			<frp-btn outlined color="primary"
					 @click="handleClose">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="red" dark
					 :disabled="!isFormValid"
					 :loading="isTrancheRejecting"
					 @click="handleDecline">
				{{ $t("buttons.decline") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { RouteNames } from "@/router/tranches/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			isFormValid: false,
			reason: ""
		};
	},
	computed: {
		...mapState({
			isTrancheRejecting: state => state.isTrancheRejecting
		}),
		...mapGetters({
			closestConfirmedTranche: getterTypes.closestConfirmedTranche
		})
	},
	methods: {
		...mapActions({
			rejectTranche: actionTypes.rejectTranche
		}),
		async handleDecline() {
			await this.rejectTranche(this.reason);
			
			this.handleClose();
		},
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		},
		handleClose() {
			this.reason = "";
			
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpTextarea,
		FrpDialog,
		FrpBtn
	}
};
</script>

import { IMiddleware } from "@/types/core/middleware";
import { Route } from "vue-router/types/router";
import { RouteNames } from "@/router/tranches/routes";

export default class RedirectMiddleware implements IMiddleware {
	constructor() {
		
	}
	
	async invoke(to: Route, from: Route) {
		try {
			switch (to.name) {
				case RouteNames.ROOT:
					return { name: RouteNames.ERROR_PAGE_NOT_FOUND };
				case RouteNames.TRANCHES_TRANCHE_APPLICATION:
				{
					if(!to.params.applicationNumber)
						return { name: RouteNames.ERROR_PAGE_NOT_FOUND };
					
					break;
				}
				default:
					break;
			}
		} catch (e) {
			console.error(e);
		}
	}
}

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "delegacy";

export const getterTypes = {
	...listingGetterTypes,
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetchAppointers: "fetchAppointers",
	fetchAttorneys: "fetchAttorneys",
	fetchDepartments: "fetchDepartments",
	addDelegacy: "addDelegacy",
	deleteDelegacy: "deleteDelegacy"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_FILTER_DEPARTMENT_ID: "SET_FILTER_DEPARTMENT_ID",
	SET_FILTER_APPOINTER_ID: "SET_FILTER_APPOINTER_ID",
	SET_FILTER_STATUS: "SET_FILTER_STATUS",
	RESET_FILTER: "RESET_FILTER",
	SET_IS_APPOINTERS_LOADING: "SET_IS_APPOINTERS_LOADING",
	SET_IS_ATTORNEYS_LOADING: "SET_IS_ATTORNEYS_LOADING",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_DELEGACY_DELETING: "SET_IS_DELEGACY_DELETING",
	SET_APPOINTERS: "SET_APPOINTERS",
	SET_ATTORNEYS: "SET_ATTORNEYS",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_CREATE_DELEGACY_REQUEST_APPOINTER_ID: "SET_CREATE_DELEGACY_REQUEST_APPOINTER_ID",
	SET_CREATE_DELEGACY_REQUEST_ATTORNEY_ID: "SET_CREATE_DELEGACY_REQUEST_ATTORNEY_ID"
};

const delegacyTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default delegacyTypes;

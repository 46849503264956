import { ApiExpertiseType } from "@/api/expertise/types/apiExpertiseType";

export interface ExpertiseType {
	id: number;
	name: string;
	code: string;
	sort: number;
	isOnlySetDirector: 0 | 1;
	isSetTypeProvision: 0 | 1;
	isSetDurationExpertise: 0 | 1;
	isSetVariantDopExpertise: 0 | 1;
	isDownloadDocuments: 0 | 1;
	isSetResultExpertises: 0 | 1;
	isSetComment: 0 | 1;
	isSetRiskClass: 0 | 1;
	isExpertiseEndButton: 0 | 1;
	isNotSetExpert: 0 | 1;
	isSetTypeFieDz: 0 | 1;
	isSetTypePreDz: 0 | 1;
	isDownloadDocumentsNotReq: 0 | 1;
	shortName: string;
	dativeCase: string;
	genitiveCase: string;
	codeName: string;
	externalId: number;
}

export class ExpertiseTypeHelper {
	static map(source: ApiExpertiseType): ExpertiseType {
		return {
			id: source.ID,
			name: source.NAME,
			code: source.CODE,
			sort: source.SORT,
			isOnlySetDirector: source.IS_ONLY_SET_DIRECTOR,
			isSetTypeProvision: source.IS_SET_TYPE_PROVISION,
			isSetDurationExpertise: source.IS_SET_DURATION_EXPERTISE,
			isSetVariantDopExpertise: source.IS_SET_VARIANT_DOP_EXPERTISE,
			isDownloadDocuments: source.IS_DOWNLOAD_DOCUMENTS,
			isSetResultExpertises: source.IS_SET_RESULT_EXPERTISES,
			isSetComment: source.IS_SET_COMMENT,
			isSetRiskClass: source.IS_SET_RISK_CLASS,
			isExpertiseEndButton: source.IS_EXPERTISE_END_BUTTON,
			isNotSetExpert: source.IS_NOT_SET_EXPERT,
			isSetTypeFieDz: source.IS_SET_TYPE_FIE_DZ,
			isSetTypePreDz: source.IS_SET_TYPE_PRE_DZ,
			isDownloadDocumentsNotReq: source.IS_DOWNLOAD_DOCUMENTS_NOT_REQ,
			shortName: source.SHORT_NAME,
			dativeCase: source.DATIVE_CASE,
			genitiveCase: source.GENITIVE_CASE,
			codeName: source.CODE_NAME,
			externalId: source.EXTERNAL_ID
		};
	}
}

import { ApiExpertiseSubType } from "@/api/expertise/types/apiExpertiseSubType";

export interface ExpertiseSubType {
	id: number;
	isActive: 0 | 1;
	code: string;
	sort: number;
	expertiseTypeId: number;
	name: string;
	isLk: 0 | 1;
	isKz: 0 | 1;
	isTranches: 0 | 1;
}

export class ExpertiseSubTypeHelper {
	static map(source: ApiExpertiseSubType): ExpertiseSubType {
		return {
			id: source.ID,
			isActive: source.UF_IS_ACTIVE,
			code: source.UF_CODE,
			sort: source.UF_SORT,
			expertiseTypeId: source.UF_EXPERTISE_TYPE_ID,
			name: source.UF_NAME,
			isLk: source.UF_IS_LK,
			isKz: source.UF_IS_KZ,
			isTranches: source.UF_IS_TRANSHES
		};
	}
}

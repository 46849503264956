import Vue from "vue";
import { isArray } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "@/store/modules/user";
import { getterTypes } from "@/store/modules/user/types";
import { Permissions } from "@/constants/permissions";
import userManager from "@/services/auth/userManager";
import SecurityService from "@/services/auth/securityService";
import PermissionsResolver from "@/api/authorization/permissionsResolver";

const { mapState, mapGetters } = createNamespacedHelpers(namespace);

const securityService = new SecurityService();

const permissionsResolver = new PermissionsResolver();

const authorizationMixin = Vue.extend({
	data() {
		return {
			userManager,
			Permissions,
			permissions: []
		};
	},
	computed: {
		...mapGetters({
			profile: getterTypes.profile
		})
	},
	methods: {
		can(value: any) {
			let can = false;

			if(!value) {
				return true;
			}

			if(isArray(value)) {
				// @ts-ignore
				can = value.some(x => this.permissions.includes(x));
			} else {
				// @ts-ignore
				can = this.permissions.includes(value);
			}

			return can;
		},
		async signIn() {
			await securityService.signIn({});
		},
		async signOut() {
			await securityService.signOut();
		},
		async getToken() {
			return await securityService.getToken();
		},
		async getUser() {
			return await securityService.getUser();
		}
	},
	async created() {
		// @ts-ignore
		this.permissions = await permissionsResolver.resolve();
	}
});


export default authorizationMixin;

export default class ApiCreateRequestResponse {
	id: string;

	constructor(
		id: string = ""
	)
	{
		this.id = id;
	}
}

import urls from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiApp from "@/api/types/app/apiApp";
import { plainToInstance } from "class-transformer";

export class AuthorizationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getApps = async (): Promise<ApiApp[]> => {
		const apps = await this.client.get<ApiApp[]>(urls.apps.getApps);
		return plainToInstance(ApiApp, apps);
	};

	getPermissions = async (): Promise<string[]> => {
		let permissions = await this.client.get<[{ systemName: string, name: string }]>(urls.permissions.get);
		return permissions.map((x) => x.name);
	};

	confirmEmail = async (code: string): Promise<void> => {
		await this.client.get<object>(prepareUrl(urls.auth.confirmEmail, {
			code
		}), {
			withCredentials: true
		});
	};

	confirmPhone = async (code: string): Promise<void> => {
		await this.client.get<object>(prepareUrl(urls.auth.confirmPhone, {
			code
		}), {
			withCredentials: true
		});
	};

	getNewConfirmEmailCode = async (): Promise<void> => {
		await this.client.post<void>(prepareUrl(urls.auth.getCode), {});
	};

	getPhoneConfirmationCode = async (): Promise<void> => {
		await this.client.post<void>(prepareUrl(urls.auth.getPhoneCode), {});
	};

	requestPhoneConfirmation = async (profileId: string): Promise<void> => {
		await this.client.post<void>(urls.auth.requestPhoneConfirmation, {
			profileId
		});
	};
}

import { isEqual, assign } from "lodash";
import BatchService from "@/services/batchService";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import { listingActionTypes } from "@/store/shared/listing/types";
import { pagingActionTypes } from "@/store/shared/paging/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { sortingActionTypes } from "@/store/shared/sorting/types";
import { maxLengths, prepareMaxLengthRule } from "@/utils/validation";
import { mapInstanceActions, mapInstanceState } from "@/utils/vuexMapInstanse";
import { resolveAction, resolveNestedState } from "@/utils/vuexModules";
import Vue from "vue";

const setSearchQueryBatchService = new BatchService(({ interval: 700 }));

export const listMixin = Vue.extend({
	data() {
		return {
			searchTextFieldRef: "search",
			validation: {
				searchQuery: [prepareMaxLengthRule({ maxLength: maxLengths.search })]
			},
			isFilterOpened: false,
			mobileBreakpoint: 880
		};
	},
	computed: {
		...mapInstanceState({
			search: (state: { search: SearchModel }) => state.search,
			items: (state: { listing: ListingModel<any> }) => state.listing.items,
			isItemsLoading: (state: { listing: ListingModel<any> }) => state.listing.isLoadingState,
			listing: (state: { listing: ListingModel<any> }) => state.listing,
			sorting: (state: { sorting: SortingModel<any> }) => state.sorting,
			paging: (state: { paging: PagingModel }) => state.paging,
			filterValues: (state: { filter: any }) => state.filter
		}),
		selectedFilteringParametersCount() {
			return Object.values(this.filterValues).flat().length;
		},
		page: {
			get() {
				// @ts-ignore
				return this.paging.page;
			},
			set(value: number) {
				// @ts-ignore
				this.setPagingPage(value);
			}
		},
		searchQuery: {
			get() {
				// @ts-ignore
				return this.search.query;
			},
			async set(value: String) {
				// @ts-ignore
				if(this.$refs[this.searchTextFieldRef].$refs.input.validate()) {
					setSearchQueryBatchService.push(async () => {
						// @ts-ignore
						await this.setSearchQuery(value);
					});
				}
			}
		},
		isMultiSort() {
			return Array.isArray(this.sorting.type);
		},
		options: {
			get() {
				return {
					// @ts-ignore
					multiSort: this.isMultiSort,
					// @ts-ignore
					sortBy: this.isMultiSort ? this.sorting.type : [this.sorting.type],
					// @ts-ignore
					sortDesc: this.isMultiSort ?
						// @ts-ignore
						this.sorting.order.map((x: any) => x === sortingOrderType.descending) :
						// @ts-ignore
						[this.sorting.order === sortingOrderType.descending]
				};
			},
			set({ sortBy, sortDesc }: {
				sortBy: string | any[],
				sortDesc: string | any[]
			})
			{
				// @ts-ignore
				this.sortItems(null, sortBy, sortDesc);
			}
		},
		footerOptions: {
			get() {
				return { itemsPerPageOptions: [5, 10, 15, 50, -1] };
			}
		},
		isFilterChanged() {
			return !isEqual(this.internalFilterValues, assign({}, this.filterValues));
		},
		isFilterEmpty() {
			// @ts-ignore
			return Object.values(this.filterValues).every(x => !x.length);
		},
		isMobileBreakpoint() {
			return this.$vuetify.breakpoint.width < this.mobileBreakpoint;
		}
	},
	methods: {
		...mapInstanceActions({
			setSearchQuery: searchMixinTypes.actionTypes.setSearchQuery,
			setPagingPage: pagingActionTypes.setPagingPage
		}),
		async setPagingPageSize(value: number) {
			await this.$store.dispatch(resolveAction(this.namespace, pagingActionTypes.setPagingPageSize), value);
		},
		async setSortingType(value: any) {
			await this.$store.dispatch(resolveAction(this.namespace, sortingActionTypes.setSortingType), value);
		},
		async setSortingOrder(value: any) {
			await this.$store.dispatch(resolveAction(this.namespace, sortingActionTypes.setSortingOrder), value);
		},
		async updateListingItems(value: any[]) {
			await this.$store.dispatch(resolveAction(this.namespace, listingActionTypes.updateListingItems), value);
		},
		sortItems(_: any, sortBy: string | any[], sortDesc: string | any[]) {
			let sortingType = sortBy.length ? sortBy[0] : null;
			let sortingOrder = sortDesc.length ? (sortDesc[0] ? sortingOrderType.descending : sortingOrderType.ascending) : null;

			if(this.sorting.type !== sortingType)
				this.setSortingType(sortingType);
			
			if(this.sorting.order !== sortingOrder)
				this.setSortingOrder(sortingOrder);
			
			return this.items;
		}
	},
	watch: {
		filterValues: {
			handler() {
				// @ts-ignore
				if(this.filterValues) this.setInternalFilterValues();
				
				if("boolean" === typeof this.isFilterApplying)
					this.isFilterApplying = false;
			},
			immediate: true,
			deep: true
		},
		isFilterOpened(value: any) {
			// @ts-ignore
			if(!value && this.filterValues) this.setInternalFilterValues();
		}
	}
});

import { ApiCounterpartyEmployeeBase } from "@/api/types/counterparty/apiCounterpartyEmployee";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import ApiLegalPersonCounterpartyPersisted from "@/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";
import ApiLegalPersonIdentifiers from "@/api/types/counterparty/apiLegalPersonIdentifiers";

export class LegalPersonController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	updateLegalPersonCounterpartyEmployee =
		async (counterpartyId: string, employeeId: string, payload: ApiCounterpartyEmployeeBase, scope = AuthorizationScopeType.GLOBAL): Promise<void> => {
			const url = scope === AuthorizationScopeType.GLOBAL
				? urls.counterparty.legalPerson.employee.update.replace(urlTemplateParts.id, counterpartyId).replace(urlTemplateParts.subId, employeeId)
				: urls.counterparty.legalPerson.employee.updateOwn.replace(urlTemplateParts.id, employeeId);

			return await this.client.put<void>(url, payload);
		};

	createLegalPersonCounterpartyEmployee = async (counterpartyId: string,
		payload: ApiCounterpartyEmployeeBase,
		assignToAccount: boolean = true,
		scope = AuthorizationScopeType.GLOBAL): Promise<string> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? prepareUrl(urls.counterparty.legalPerson.employee.create.replace(urlTemplateParts.id, counterpartyId),
				{ assignToAccount })
			: prepareUrl(urls.counterparty.legalPerson.employee.createOwn, { assignToAccount });

		return await this.client.post<string>(url, payload);
	};

	getSuggestion = async (inn?: string, ogrn?: string): Promise<ApiFnsLegalPerson | null> => {
		try {
			let result = await this.client.get<{ counterparty: object }>(prepareUrl(urls.counterparty.legalPerson.getSuggestion, {
				inn, ogrn
			}));
			return plainToInstance(ApiFnsLegalPerson, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkEmployeeExists = async (counterpartyIdentifiers: ApiLegalPersonIdentifiers, employeeEmail: string): Promise<boolean> => {
		const url = prepareUrl(urls.counterparty.legalPerson.checkEmployeeExists);

		const { exists } = await this.client.post<{ exists: boolean }>(url, {
			employerIdentifiers: counterpartyIdentifiers,
			employeeEmail
		});

		return exists;
	};

	getLegalPersonByInnOgrn = async (inn: string, ogrn: string): Promise<ApiLegalPersonCounterpartyPersisted | null> => {
		try {
			return await this.client.get(prepareUrl(urls.counterparty.legalPerson.search, { inn, ogrn }));
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkLegalPersonIsExists = async (inn: string, ogrn: string): Promise<boolean> => {
		const url = prepareUrl(urls.counterparty.legalPerson.checkIsExists, { inn, ogrn });

		const { exists } = await this.client.get<{ exists: boolean }>(url);

		return exists;
	};

	getEntrepreneurMasterAccountProfile = async (counterpartyId: string): Promise<ApiAccountProfilePersistedBase | null> => {
		try {
			const url = urls.counterparty.legalPerson.profile.get.replace(urlTemplateParts.id, counterpartyId);

			let { profilePersisted } = await this.client.get<{ profilePersisted: object }>(url);

			return plainToInstance(ApiAccountProfilePersistedBase, profilePersisted);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	checkMasterAccount = async (inn: string, ogrn: string): Promise<boolean> => {
		const url = urls.counterparty.legalPerson.profile.check;

		let { exists } = await this.client.post<{ exists: boolean }>(url, { inn, ogrnIp: ogrn });

		return exists;
	};

	createEntrepreneurMasterAccountProfile = async (counterpartyId: string, email: string): Promise<string> => {
		const url = urls.counterparty.legalPerson.profile.create.replace(urlTemplateParts.id, counterpartyId);

		return await this.client.post<string>(url, {
			email
		});
	};

	updateEntrepreneurMasterAccountProfile = async (counterpartyId: string, email: string): Promise<string> => {
		const url = urls.counterparty.legalPerson.profile.update.replace(urlTemplateParts.id, counterpartyId);

		return await this.client.put<string>(url, {
			email
		});
	};

}

<template>
	<v-card elevation="0"
			class="frp-card pa-0 d-flex flex-column">
		<v-row class="d-flex flex-column">
			<v-col>
				<v-card-title class="pa-4 justify-space-between" :style="isLoading ? 'padding-bottom: 10px !important' : ''">
					<v-skeleton-loader class="mt-1" v-if="isLoading" type="text" width="160"/>
					<span v-else style="word-break: initial"
						  class="mr-1 text-subtitle-1 font-weight-medium blue--text text--darken-4">
						{{ title || "" }}
					</span>
					
					<slot name="actions"></slot>
				</v-card-title>
				
				<slot name="content"></slot>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCustomTitleLoader from "@/components/loaders/common/FrpCustomTitleLoader.vue";

export default {
	components: { FrpCustomTitleLoader, FrpBtn },
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ""
		}
	}
};
</script>

<style scoped>

</style>

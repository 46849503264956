import baseStepTypes from "@/store/shared/baseStep/types";
import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { formActionTypes } from "@/store/shared/form/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "sign-application-step";

export const getterTypes = {
	...baseStepTypes.getterTypes,
	isSigned: "isSigned"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseStepTypes.actionTypes,
	...baseActionTypes,
	...formActionTypes,
	...baseMixinTypes.actionTypes
};

export const mutationTypes = {
	...baseStepTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_ENCODED_APPLICATION_STRING: "SET_ENCODED_APPLICATION_STRING",
	SET_ENCODED_DETACHED_SIGNATURE_STRING: "SET_ENCODED_DETACHED_SIGNATURE_STRING"
};

const signApplicationStepTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default signApplicationStepTypes;

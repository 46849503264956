import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "agreements-accrued-interest";

export const getterTypes = {
	...listingGetterTypes,
	filteredItems: "filteredItems",
	formattedFilteredItems: "formattedFilteredItems",
	isSelectedItemsEmpty: "isSelectedItemsEmpty",
	selectedItemsLength: "selectedItemsLength",
	nearestPeriod: "nearestPeriod"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	updateListingItems: "updateListingItems",
	updateSelectedItems: "updateSelectedItems",
	fetchDictionaries: "fetchDictionaries",
	downloadReport: "downloadReport",
	sendAgreementsAccruedInterestReport: "sendAgreementsAccruedInterestReport",
	handleAccruedInterestFileUpload: "handleAccruedInterestFileUpload",
	handleAccruedInterestFileSigned: "handleAccruedInterestFileSigned",
	fetchCertificateInfo: "fetchCertificateInfo"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_PERIODS: "SET_PERIODS",
	SET_AGREEMENT_STATUSES: "SET_AGREEMENT_STATUSES",
	SET_FILTER_PERIOD_ID: "SET_FILTER_PERIOD_ID",
	SET_IS_FILTER_PERIOD_ID_CHANGED: "SET_IS_FILTER_PERIOD_ID_CHANGED",
	SET_FILTER_APPLICATION_NUMBER: "SET_FILTER_APPLICATION_NUMBER",
	SET_FILTER_COMPANY_NAME: "SET_FILTER_COMPANY_NAME",
	SET_FILTER_AGREEMENT_STATUS_ID: "SET_FILTER_AGREEMENT_STATUS_ID",
	SET_FILTER_DAYS_MIN: "SET_FILTER_DAYS_MIN",
	SET_FILTER_DAYS_MAX: "SET_FILTER_DAYS_MAX",
	SET_LISTING_ITEM_IS_SELECTED: "SET_LISTING_ITEM_IS_SELECTED",
	SET_IS_ONLY_SELECTED_SHOWN: "SET_IS_ONLY_SELECTED_SHOWN",
	SET_IS_UPDATE_SELECTED_ITEMS_REQUIRED: "SET_IS_UPDATE_SELECTED_ITEMS_REQUIRED",
	RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT: "RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT",
	IS_AGREEMENTS_ACCRUED_INTEREST_REPORT_LOADING: "IS_AGREEMENTS_ACCRUED_INTEREST_REPORT_LOADING",
	SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_REPORT_NUMBER: "SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_REPORT_NUMBER",
	RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT: "RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT",
	SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT: "SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT",
	RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID: "RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID",
	SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID: "SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID",
	SET_IS_REPORT_ERROR: "SET_IS_REPORT_ERROR",
	RESET_ACCRUED_INTEREST_FILE_META: "RESET_ACCRUED_INTEREST_FILE_META",
	SET_ACCRUED_INTEREST_FILE_META: "SET_ACCRUED_INTEREST_FILE_META",
	SET_ACCRUED_INTEREST_FILE_META_IS_LOADING: "SET_ACCRUED_INTEREST_FILE_META_IS_LOADING",
	SET_DIGITAL_SIGNATURE_IS_SIGNED: "SET_DIGITAL_SIGNATURE_IS_SIGNED",
	RESET_CERTIFICATE_INFO: "RESET_CERTIFICATE_INFO",
	SET_CERTIFICATE_INFO: "SET_CERTIFICATE_INFO",
	SET_IS_SIGNED: "SET_IS_SIGNED"
};

const agreementsAccruedInterestTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default agreementsAccruedInterestTypes;

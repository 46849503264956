import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "legal-entity-counterparty";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	addHead: "addHead",
	removeHead: "removeHead",
	destroyHeadModules: "destroyHeadModules",
	fetchHeads: "fetchHeads",
	handleInnInput: "handleInnInput",
	saveFnsUpdate: "saveFnsUpdate",
	downloadSnils: "downloadSnils",
	updateViaFns: "updateViaFns"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_FNS_UPDATE: "SET_FNS_UPDATE",
	SET_IS_FNS_UPDATE_ENABLED: "SET_IS_FNS_UPDATE_ENABLED",
	SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING: "SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING",
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_COUNTERPARTY_INN: "SET_COUNTERPARTY_INN",
	SET_COUNTERPARTY_OGRN: "SET_COUNTERPARTY_OGRN",
	SET_COUNTERPARTY_KPP: "SET_COUNTERPARTY_KPP",
	SET_COUNTERPARTY_OPF: "SET_COUNTERPARTY_OPF",
	SET_COUNTERPARTY_LONG_NAME: "SET_COUNTERPARTY_LONG_NAME",
	SET_COUNTERPARTY_SHORT_NAME: "SET_COUNTERPARTY_SHORT_NAME",
	SET_COUNTERPARTY_LEGAL_ADDRESS: "SET_COUNTERPARTY_LEGAL_ADDRESS",
	SET_COUNTERPARTY_REGISTRATION_DATE: "SET_COUNTERPARTY_REGISTRATION_DATE",
	ADD_HEAD: "ADD_HEAD",
	REMOVE_HEAD: "REMOVE_HEAD",
	SET_IS_EGRUL_LEGAL_ENTITY_LOADING: "SET_IS_EGRUL_LEGAL_ENTITY_LOADING",
	SET_COUNTERPARTY_IS_RFRP: "SET_COUNTERPARTY_IS_RFRP",
	SET_COUNTERPARTY_IS_LEASING_COMPANY: "SET_COUNTERPARTY_IS_LEASING_COMPANY",
	SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY: "SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY",
	SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY: "SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY",
	SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY: "SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY",
	SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED: "SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED"
};

const legalEntityCounterpartyTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default legalEntityCounterpartyTypes;

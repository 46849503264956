export default class ApiSection {
	id: string;
	title: string;

	constructor(
		id: string = "",
		title: string = ""
	)
	{
		this.id = id;
		this.title = title;
	}
}

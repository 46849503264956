import baseMixinTypes from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "counterparty-master-account";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	updateStatus: "updateStatus",
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_ACCOUNT: "SET_ACCOUNT",
	SET_IS_ACTIVE: "SET_IS_ACTIVE",
	SET_COUNTERPARTY_ID: "SET_COUNTERPARTY_ID",
	SET_ACCOUNT_ID: "SET_ACCOUNT_ID",
	SET_IS_STATUS_SAVING: "SET_IS_STATUS_SAVING"
};

const counterpartyMasterAccountTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default counterpartyMasterAccountTypes;

import ApiBodFile from "@/api/bod/types/apiBodFile";
import ApiSection from "@/api/bod/types/apiSection";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";

export default class ApiDocumentListItem {
	id: string;
	createdAt: string;
	title: string;
	section: ApiSection;
	financeSchemes: ApiFinanceScheme[];
	responsibleDepartment: ApiDepartment;
	file: ApiBodFile;
	status: string;
	linkedToFinanceSchemes: boolean;

	constructor(
		id: string = "",
		createdAt: string = "",
		title: string = "",
		section: ApiSection = new ApiSection(),
		financeSchemes: ApiFinanceScheme[] = [],
		responsibleDepartment: ApiDepartment = new ApiDepartment(),
		file: ApiBodFile = new ApiBodFile(),
		status: string = "",
		linkedToFinanceSchemes: boolean = false
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.title = title;
		this.section = section;
		this.financeSchemes = financeSchemes;
		this.responsibleDepartment = responsibleDepartment;
		this.file = file;
		this.status = status;
		this.linkedToFinanceSchemes = linkedToFinanceSchemes;
	}
}

import baseMixinTypes from "@/store/shared/base/types";

export const namespace = "user";
export const relativeNamespace = "user";

export const getterTypes = {
	profile: "profile",
	isMasterAccount: "isMasterAccount",
	isCounterpartyEmployee: "isCounterpartyEmployee"
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	fetchProfiles: "fetchProfiles",
	fetchPermissions: "fetchPermissions",
	fetchUserinfo: "fetchUserinfo"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	SET_IS_USERINFO_LOADING: "SET_IS_USERINFO_LOADING",
	SET_IS_PROFILES_LOADING: "SET_IS_PROFILES_LOADING",
	SET_ACCOUNT: "SET_ACCOUNT",
	SET_PROFILE: "SET_PROFILE",
	SET_PROFILES: "SET_PROFILES",
	SET_PERMISSIONS: "SET_PERMISSIONS"
};

const userTypes = {
	namespace, actionTypes,
	getterTypes
};

export default userTypes;

import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { MutationTree, ActionTree } from "vuex";
import SortingModel from "@/store/shared/sorting/models/sortingModel";

export default class SortingMixinBuilder {
	constructor() {
	}

	build() {
		return {
			mutations: <MutationTree<{ sorting: SortingModel<any> }>>{
				[sortingMutationTypes.SET_SORTING_ORDER](state, value) {
					state.sorting.order = value;
				},
				[sortingMutationTypes.SET_SORTING_TYPE](state, value) {
					state.sorting.type = value;
				}
			},
			actions: <ActionTree<SortingModel<any>, any>>{
				async [sortingActionTypes.setSortingOrder]({ commit, dispatch }, value) {
					commit(sortingMutationTypes.SET_SORTING_ORDER, value);
				},
				async [sortingActionTypes.setSortingType]({ commit, dispatch }, value) {
					commit(sortingMutationTypes.SET_SORTING_TYPE, value);
				}
			}
		};
	}
}

import EmbeddedForm from "@/store/shared/embeddedForm/types/embeddedForm";

export default class EmbeddedPasswordFormState {
	form: EmbeddedForm;
	password: string;
	confirmedPassword: string;

	constructor(
		form: EmbeddedForm,
		password = "",
		confirmedPassword = ""
	)
	{
		this.form = form;
		this.password = password;
		this.confirmedPassword = confirmedPassword;
	}
}

import Vue from "vue";
import ruLang from "vuetify/es5/locale/ru";
import VueI18n from "vue-i18n";
import locales from "@/plugins/i18n/locales";
import pluralizationRules from "@/plugins/i18n/rules/pluralization";

Vue.use(VueI18n);

const ruLocaleAlias = "ru";
const enLocaleAlias = "en";

const messages = {
	ru: Object.assign({}, locales[ruLocaleAlias], ruLang)
};

export default new VueI18n({
	locale: ruLocaleAlias,
	fallbackLocale: ruLocaleAlias,
	messages,
	pluralizationRules
});

import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import { ProfileType } from "@/types/ProfileType";

export default class ApiLegalEntityProfile extends ApiAccountProfileBase {
	inn: string;
	ogrn: string;
	kpp: string;

	constructor(
		type = ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		inn: string = "",
		ogrn: string = "",
		kpp: string = "",
		isActive: boolean = false
	)
	{
		super(type, accountId, title, email, phone, isActive);

		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
	}
}

import ApiLegalEntityHead from "@/api/types/legalEntity/apiLegalEntityHead";
import { Type } from "class-transformer";

export default class ApiLegalEntity {
	inn: string;
	ogrn: string;
	kpp: string;
	opf: string;
	longName: string;
	shortName: string;
	legalAddress: string;
	registrationDate: string;
	@Type(() => ApiLegalEntityHead)
	heads: Array<ApiLegalEntityHead>;

	constructor(inn: string,
		ogrn: string,
		kpp: string,
		opf: string,
		longName: string,
		shortName: string,
		legalAddress: string,
		registrationDate: string,
		heads: ApiLegalEntityHead[])
	{
		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.opf = opf;
		this.longName = longName;
		this.shortName = shortName;
		this.legalAddress = legalAddress;
		this.registrationDate = registrationDate;
		this.heads = heads;
	}
}

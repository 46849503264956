import { ApiTrancheHistoryItemAuthor } from "@/api/tranches/types/apiTrancheHistoryItemAuthor";
import { ApiTrancheHistoryItem } from "@/api/tranches/types/apiTrancheHistoryItem";
import { convertToTimestamp } from "@/utils/dates";

export interface TrancheHistoryItem {
	changeAt: number;
	author: ApiTrancheHistoryItemAuthor;
	trancheNumber: number;
	newStatus: string;
}

export class TrancheHistoryItemMapper {
	static map(source: ApiTrancheHistoryItem): TrancheHistoryItem {
		return {
			changeAt: convertToTimestamp(source.changeAt) as number,
			author: source.author,
			trancheNumber: source.trancheNumber,
			newStatus: source.newStatus
		}
	}
}

export interface ApiManager {
	name: string;
	email: string;
}

export class ApiManagerHelper {
	static getEmpty(): ApiManager {
		return {
			name: "",
			email: ""
		};
	}
}
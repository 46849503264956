export default class ApiFileMeta {
	id: string;
	name: string;
	mimeType: string;
	namespace: string;
	signature: string;

	constructor(id: string, name: string, mimeType: string, namespace: string, signature: string) {
		this.id = id;
		this.name = name;
		this.mimeType = mimeType;
		this.namespace = namespace;
		this.signature = signature;
	}
}

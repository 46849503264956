import urls, { urlTemplateParts } from "@/api/config/urls";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { ApiLoanUser } from "@/api/loan/types/apiLoanUser";

export class LoanAuthorizationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getPermissions = async (): Promise<string[]> => {
		let permissions: string[] = [];
		const location = window.location.pathname;

		const isProblemloaner = location.includes("/problemloaner/project") || location.includes("/problemloaners");

		try {

			if(isProblemloaner)
				permissions = await this.client.get<any[]>(urls.troubledBorrower.permissions.get.replace(urlTemplateParts.id, "common-api"));
			else
				permissions = await this.client.get<any[]>(urls.loan.permissions.get.replace(urlTemplateParts.id, "common-api"));

			return permissions;

		} catch (error) {
			console.error(error);
			alertService.addError(AlertKeys.LOAN_PERMISSIONS_NOT_FOUND);
			return permissions;
		}
	};
	
	getCurrentUser = async (): Promise<ApiLoanUser> => {
		return await this.client.get<ApiLoanUser>(urls.loan.user.getCurrentUser);
	};
}

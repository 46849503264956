import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import ApiAccount from "@/api/types/account/apiAccount";

export default class User {
	accessToken: string;
	profile: ApiAccountProfileBase;
	account: ApiAccount;

	constructor(accessToken: string, profile: ApiAccountProfileBase, account: ApiAccount) {
		this.accessToken = accessToken;
		this.profile = profile;
		this.account = account;
	}
}

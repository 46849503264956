<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$t('dialogs.uploadTrancheDocument.title', { date: formatDate(currentDialogTranche.plannedDate, dateFormat) })"
				max-width="656"
				persistent
				@update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }">
			<v-container v-mutate="onIntersect">
				<v-row>
					<v-col class="px-0">
						<bar-dropzone max-size="25"
									  @file:add="handleUploaded($event, STORAGE_TRANCHE_DOCUMENT_NAMESPACE)"
									  :formats="['pdf']"
									  :file="documentFile"
									  :is-uploading="isFileUploading"
									  @format:is-valid="isValidFileFormat = $event"
									  :type="STORAGE_TRANCHE_DOCUMENT_NAMESPACE">
							<template #file-action>
								<frp-digital-signature-dialog
									v-model="isSignatureDialogOpened"
									:file="documentFile"
									:submit-btn="$t('buttons.choose')"
									@signed="handleSigned($event, STORAGE_TRANCHE_DOCUMENT_NAMESPACE)"
									:meta="meta"
									color="blue" button-elevation="0"
									pdf>
									<template #activator="{ on, attrs }">
										<frp-btn elevation="0" class="mr-1" dark :color="colors.blue.base"
												 v-on="on"
												 v-bind="attrs"
												 :loading="isFileUploading || isSignedUploading"
												 :disabled="!isValidFileFormat">
											{{ $t("buttons.uploadWithSignature") }}
										</frp-btn>
									</template>
								</frp-digital-signature-dialog>
							</template>
						</bar-dropzone>
					</v-col>
				</v-row>
			</v-container>
		</template>

		<template #footer>
			<frp-btn @click="handleClose"
					 :disabled="isFileUploading || isSignedUploading"
					 elevation="0" color="primary" outlined>
				{{ $t("buttons.cancel") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { LoanStorageController } from "@/api/loanStorage";
import ApiFile from "@/api/types/storage/apiFile";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDigitalSignatureDialog from "@/components/digitalSignature/FrpDigitalSignatureDialog.vue";
import BarDropzone from "@/components/dropzone/BarDropzone.vue";
import { RouteNames } from "@/router/tranches/routes";
import AbortService from "@/services/abortService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import storageMapper from "@/store/shared/storage/mapper";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import { STORAGE_TRANCHE_DOCUMENT_NAMESPACE } from "@/constants/storage";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const loanStorageController = new LoanStorageController(abortService);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			STORAGE_TRANCHE_DOCUMENT_NAMESPACE: STORAGE_TRANCHE_DOCUMENT_NAMESPACE,
			namespace,
			formatDate,
			dateFormat,
			isSignatureDialogOpened: false,
			documentFile: null,
			meta: null,
			isFileUploading: false,
			isValidFileFormat: false
		};
	},
	computed: {
		...mapGetters({
			closestConfirmedTranche: getterTypes.closestConfirmedTranche
		}),
		...mapState({
			isSignedUploading: state => state.isDocumentUploading
		}),
		...mapGetters({
			currentDialogTranche: getterTypes.currentDialogTranche
		})
	},
	methods: {
		...mapActions({
			addTrancheDocument: actionTypes.addTrancheDocument
		}),
		...mapMutations({
			setCurrentDialogTrancheNumber: mutationTypes.SET_CURRENT_DIALOG_TRANCHE_NUMBER
		}),
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		},
		handleClose() {
			this.meta = null;
			this.setCurrentDialogTrancheNumber(undefined);
			this.$emit("update:value", false);
		},
		async handleUploaded(file, docType) {
			if(!file) {
				this.documentFile = null;
				this.meta = null;
				return;
			}
			
			this.isFileUploading = true;
			
			this.documentFile = file;
			
			try {
				const { name, type } = file;
				
				let meta = await loanStorageController.createTemperFile(new ApiFile(file, name, docType, type, ""));
				
				this.meta = storageMapper.map(meta, ApiFileMeta, FileMeta);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isFileUploading = false;
			}
		},
		async handleSigned(signature, docType) {
			const { name, type } = this.documentFile;
			
			const res = await this.addTrancheDocument(new ApiFile(this.documentFile, name, docType, type, signature));
			
			if(res)
				this.handleClose();
		}
	},
	created() {
		abortService.initialize();
	},
	components: {
		FrpDigitalSignatureDialog, BarDropzone,
		FrpDialog,
		FrpBtn
	}
};
</script>

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "physical-person-counterparty";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_COUNTERPARTY_DESCRIPTION: "SET_COUNTERPARTY_DESCRIPTION",
	SET_COUNTERPARTY_INN: "SET_COUNTERPARTY_INN",
	SET_COUNTERPARTY_SNILS: "SET_COUNTERPARTY_SNILS",
	SET_COUNTERPARTY_FIRST_NAME: "SET_COUNTERPARTY_FIRST_NAME",
	SET_COUNTERPARTY_LAST_NAME: "SET_COUNTERPARTY_LAST_NAME",
	SET_COUNTERPARTY_MIDDLE_NAME: "SET_COUNTERPARTY_MIDDLE_NAME",
	SET_COUNTERPARTY_IS_RFRP: "SET_COUNTERPARTY_IS_RFRP",
	SET_COUNTERPARTY_IS_LEASING_COMPANY: "SET_COUNTERPARTY_IS_LEASING_COMPANY",
	SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY: "SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY",
	SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY: "SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY",
	SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY: "SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY",
	SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED: "SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED"
};

const physicalPersonCounterpartyTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default physicalPersonCounterpartyTypes;

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import embeddedFormTypes from "@/store/shared/embeddedForm/types";

export const getterTypes = {
	...embeddedFormTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...embeddedFormTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	checkIsEmailUnique: "checkIsEmailUnique",
	setChangeMode: "setChangeMode"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...embeddedFormTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_EMAIL: "SET_EMAIL",
	SET_CONFIRMED_EMAIL: "SET_CONFIRMED_EMAIL",
	SET_IS_EMAIL_UNIQUE: "SET_IS_EMAIL_UNIQUE",
	SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS: "SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS",
	SET_CHANGE_MODE: "SET_CHANGE_MODE"
};

const embeddedEmailFormTypes = {
	actionTypes, getterTypes, mutationTypes
};

export default embeddedEmailFormTypes;

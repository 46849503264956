import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "production-status";

export const getterTypes = {
	...formTypes.getterTypes,
	...listingGetterTypes,
	...snapshotMixinTypes.getterTypes,
	version: "version",
	obligedCompanies: "obligedCompanies",
	debtor: "debtor"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	addEditableActualStatus: "addEditableActualStatus",
	deleteDebtor: "deleteDebtor",
	deleteDebtorStatusHistoryItem: "deleteDebtorStatusHistoryItem",
	deleteEvent: "deleteEvent",
	deleteGoal: "deleteGoal",
	deleteRequirement: "deleteRequirement",
	updateDebtorStatus: "updateDebtorStatus",
	updateDebtorStatusComment: "updateDebtorStatusComment",
	createEvent: "createEvent",
	updateEvent: "updateEvent",
	createGoal: "createGoal",
	updateGoal: "updateGoal",
	createRequirement: "createRequirement",
	updateRequirement: "updateRequirement",
	createDebtor: "createDebtor",
	updateDebtor: "updateDebtor",
	fetchDebtorRequirementHistoryItems: "fetchDebtorRequirementHistoryItems",
	deleteDebtorRequirementHistoryItem: "deleteDebtorRequirementHistoryItem",
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_EDITABLE_EVENT: "SET_EDITABLE_EVENT",
	SET_EDITABLE_EVENT_TYPE_ID: "SET_EDITABLE_EVENT_TYPE_ID",
	RESET_EDITABLE_EVENT_TYPE_ID: "RESET_EDITABLE_EVENT_TYPE_ID",
	SET_EDITABLE_EVENT_DEBTOR_STATUS_HISTORY_ITEM_ID: "SET_EDITABLE_EVENT_DEBTOR_STATUS_HISTORY_ITEM_ID",
	SET_EDITABLE_EVENT_CONTROL_DATE: "SET_EDITABLE_EVENT_CONTROL_DATE",
	SET_EDITABLE_EVENT_FACTUAL_DATE: "SET_EDITABLE_EVENT_FACTUAL_DATE",
	SET_EDITABLE_EVENT_JUDICIAL_DECISION_TYPE_ID: "SET_EDITABLE_EVENT_JUDICIAL_DECISION_TYPE_ID",
	SET_EDITABLE_EVENT_FACTUAL_PENALTY_SUM: "SET_EDITABLE_EVENT_FACTUAL_PENALTY_SUM",
	SET_EDITABLE_REQUIREMENT: "SET_EDITABLE_REQUIREMENT",
	SET_EDITABLE_REQUIREMENT_TYPE_ID: "SET_EDITABLE_REQUIREMENT_TYPE_ID",
	SET_EDITABLE_REQUIREMENT_SUM: "SET_EDITABLE_REQUIREMENT_SUM",
	SET_CREATE_DEBTOR_REQUEST: "SET_CREATE_DEBTOR_REQUEST",
	SET_CREATE_DEBTOR_REQUEST_COMPANY_ID: "SET_CREATE_DEBTOR_REQUEST_COMPANY_ID",
	SET_CREATE_DEBTOR_REQUEST_ROLE_IN_PROJECT: "SET_CREATE_DEBTOR_REQUEST_ROLE_IN_PROJECT",
	SET_CREATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID: "SET_CREATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID",
	SET_EDITABLE_DEBTOR_STATUS_COMMENT: "SET_EDITABLE_DEBTOR_STATUS_COMMENT",
	RESET_EDITABLE_DEBTOR_STATUS: "RESET_EDITABLE_DEBTOR_STATUS",
	SET_EDITABLE_DEBTOR_STATUS: "SET_EDITABLE_DEBTOR_STATUS",
	SET_EDITABLE_DEBTOR_STATUS_TEXT: "SET_EDITABLE_DEBTOR_STATUS_TEXT",
	SET_EDITABLE_DEBTOR_STATUS_ID: "SET_EDITABLE_DEBTOR_STATUS_ID",
	SET_EDITABLE_DEBTOR_STATUS_TYPE_ID: "SET_EDITABLE_DEBTOR_STATUS_TYPE_ID",
	SET_EDITABLE_DEBTOR_STATUS_START_DATE: "SET_EDITABLE_DEBTOR_STATUS_START_DATE",
	SET_EDITABLE_DEBTOR_STATUS_EXPIRATION_DATE: "SET_EDITABLE_DEBTOR_STATUS_EXPIRATION_DATE",
	SET_DEBTOR_STATUS_COMMENT: "SET_DEBTOR_STATUS_COMMENT",
	SET_DEBTOR_REQUIREMENTS: "SET_DEBTOR_REQUIREMENTS",
	SET_DEBTOR_EVENTS: "SET_DEBTOR_EVENTS",
	SET_DEBTOR_STATUS: "SET_DEBTOR_STATUS",
	SET_DEBTOR_STATUS_HISTORY: "SET_DEBTOR_STATUS_HISTORY",
	SET_DEBTOR_REQUIREMENTS_HISTORY: "SET_DEBTOR_REQUIREMENTS_HISTORY",
	SET_DEBTOR_OBLIGED_COMPANY_NAME: "SET_DEBTOR_OBLIGED_COMPANY_NAME",
	REMOVE_DEBTOR: "REMOVE_DEBTOR",
	ADD_DEBTOR: "ADD_DEBTOR",
	UPDATE_DEBTOR: "UPDATE_DEBTOR",
	SET_UPDATE_DEBTOR_REQUEST_COMPANY_ID: "SET_UPDATE_DEBTOR_REQUEST_COMPANY_ID",
	SET_UPDATE_DEBTOR_REQUEST_ROLE_IN_PROJECT: "SET_UPDATE_DEBTOR_REQUEST_ROLE_IN_PROJECT",
	SET_UPDATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID: "SET_UPDATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID"
};

const productionStatusTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default productionStatusTypes;

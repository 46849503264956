<template>
	<frp-card is-loading>
		<template #content>
			<tranches-details-group>
				<tranches-details-item is-loading>
					<frp-text-loader width="100"></frp-text-loader>
				</tranches-details-item>
				
				<tranches-details-item is-loading loading-title-width="50">
					<frp-text-loader width="150"></frp-text-loader>
				</tranches-details-item>
				
				<tranches-details-item is-loading loading-title-width="185">
					<frp-text-loader width="180"></frp-text-loader>
				</tranches-details-item>
				
				<tranches-details-item  is-loading loading-title-width="130">
					<frp-text-loader width="180"></frp-text-loader>
				</tranches-details-item>
				
				<tranches-details-item  is-loading loading-title-width="45">
					<frp-text-loader width="180"></frp-text-loader>
				</tranches-details-item>
			</tranches-details-group>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import TranchesDetailsGroup from "@/components/details/TranchesDetailsGroup.vue";
import TranchesDetailsItem from "@/components/details/TranchesDetailsItem.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";

export default {
	components: {
		FrpTextLoader,
		TranchesDetailsItem,
		TranchesDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>

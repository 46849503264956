import ApiLegalEntityHeadSnils from "@/api/types/counterparty/snils/apiLegalEntityHeadSnils";

export default class ApiLegalEntityHeadSnilsPersisted {
	id: string;
	snils: ApiLegalEntityHeadSnils;
	createdAt: string;
	updatedAt: string;

	constructor(id: string, snils: ApiLegalEntityHeadSnils, createdAt: string, updatedAt: string) {
		this.id = id;
		this.snils = snils;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
	}
}

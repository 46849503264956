import { cloneDeep, pick } from "lodash";

export default class StateSnapshotService {
	values: any

	constructor() {
		this.values = {};
	}

	get(key: string) {
		return cloneDeep(this.values[key]);
	}

	prepareSnapshot(state: any, fields: any) {
		return pick(state, fields);
	}

	set(key: string | number, value: any) {
		this.values[key] = cloneDeep(value);
	}
}

<template>
	<frp-card v-if="isInitialized" :title="$t('titles.trancheSchedule')">
		<template #actions>
			<frp-btn v-if="can(Permissions.TRANCHES_TRANCHE_SCHEDULE_UPDATE) && mode === TrancheScheduleModeTypeEnum.EDIT"
					 outlined
					 dense
					 elevation="0"
					 class="text-none"
					 color="primary"
					 dark
					 :disabled="isFormDisabled"
					 @click="addEditableItemTranche">
				<v-icon color="primary">mdi-plus</v-icon>
				{{ $t("buttons.addTranche") }}
			</frp-btn>
		</template>
		
		<template #content>
			<v-row>
				<v-col class="pt-0">
					<v-form ref="trancheScheduleForm" v-model="trancheScheduleFormValid">
						<v-data-table :headers="headers"
									  :loading-text="$t('tables.loading')"
									  hide-default-footer
									  item-key="key"
									  :items="formattedItems"
									  :items-per-page="-1"
									  :mobile-breakpoint="mobileBreakpoint"
									  :options="options"
									  :item-class="() => 'text-body-2 cy-table-row'"
									  class="tranches-table clickable-rows d-flex flex-column">
							<template #item.number="{ item, index }">
								<span>{{ index + 1 }}</span>
							</template>
							
							<template #item.amount="{ item, index }">
								<span v-if="mode === TrancheScheduleModeTypeEnum.READ || ![ApiTrancheStatusTypeEnum.DRAFT, ApiTrancheStatusTypeEnum.REJECTED].includes(item.status)">
									{{ formatCurrency(item.amount) }}
								</span>
								
								<frp-text-field v-else
												v-model.number="editableItems.find(x => x.index === index).amount"
												:disabled="isFormDisabled"
												dense
												class="tranches-field tranches-field--width"
												required
												type="number"
												hide-details>
								</frp-text-field>
							</template>
							
							<template #item.plannedDate="{ item, index }">
								<span v-if="mode === TrancheScheduleModeTypeEnum.READ || ![ApiTrancheStatusTypeEnum.DRAFT, ApiTrancheStatusTypeEnum.REJECTED].includes(item.status)">
									{{ formatDate(item.plannedDate, dateFormat) }}
								</span>
								
								<frp-date-field v-else
												v-model="editableItems.find(x => x.index === index).plannedDate"
												:disabled="isFormDisabled"
												hide-details
												:min="addDays(Date.now(), 1)"
												width="140px"
												bar-date-field
												required>
								</frp-date-field>
							</template>
							
							<template #item.orderApplication="{ item }">
								<tranches-tranche-application-tranche-schedule-order-application-table-item :tranche-item="item"
																											:key="item.number">
								</tranches-tranche-application-tranche-schedule-order-application-table-item>
							</template>
							
							<template #item.orderDate="{ item }">
								<span>{{ formatDate(item.orderDate, dateFormat) || "" }}</span>
							</template>
							
							<template #item.orderConfirmation="{ item }">
								<span v-if="item.status !== ApiTrancheStatusTypeEnum.DRAFT" class="green--text font-weight-bold">
									{{ $t("content.accepted") }}
								</span>
								
								<frp-btn v-else-if="can(Permissions.TRANCHES_TRANCHE_CONFIRM)"
										 :disabled="confirmableTranche?.number !== item.number || item.plannedDate <= removeTime(new Date()).getTime()"
										 @click="handleConfirmTranche"
										 :loading="isTrancheConfirming && confirmableTranche?.number === item.number"
										 without-padding style="min-width: 40px" color="green" elevation="0" dark>
									<frp-icon src="ico_check-circle-outline" :color="colors.white.base"></frp-icon>
								</frp-btn>
							</template>
							
							<template #item.delete="{ item, index }">
								<frp-btn without-padding height="28" small icon color="blue" dark
										 v-if="item.status === ApiTrancheStatusTypeEnum.DRAFT"
										 :disabled="formattedItems.length <= 1 || isFormDisabled"
										 @click="deleteEditableItemTranche(index)">
									<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
								</frp-btn>
							</template>
							
							<template #foot v-if="formattedItems.length">
								<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
									<tr>
										<template v-if="isMobileBreakpoint">
											<td class="d-flex justify-space-between mt-5">
												<span>{{ $t("content.total") }}</span>
												<span>{{ `${formatCurrency(getSum(formattedItems))} ${$t("common.rub")}` }}</span>
											</td>
										</template>
										
										<template v-else>
											<td>
												<span>{{ $t("content.total") }}</span>
											</td>
											<td></td>
											<td>
												<span>{{ `${formatCurrency(getSum(formattedItems))} ${$t("common.rub")}` }}</span>
											</td>
										</template>
									</tr>
								</tfoot>
							</template>
						</v-data-table>
					</v-form>
				</v-col>
			</v-row>
			
			<loan-confirm-dialog v-if="isTrancheScheduleUnsavedChangesDialogEnabled"
								 @submit="onCancelFormSubmit(TrancheScheduleSnapshotKeysEnum.TRANCHES)"
								 @cancel="setIsTrancheScheduleUnsavedChangesDialogEnabled( false)"
								 :title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
								 :description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
			</loan-confirm-dialog>
			
			<tranches-info-dialog :type="TrancheInfoDialogTypeEnum.TRANCHE_CONFIRM_SUCCESS" v-model="isInfoDialogOpened"/>
		</template>
	</frp-card>
	<tranches-tranche-application-tranche-schedule-loader v-else/>
</template>

<script>
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpCard from "@/components/cards/FrpCard.vue";
import LoanConfirmDialog from "@/components/dialogs/LoanConfirmDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { FileTypes } from "@/constants/fileTypes";
import storeManager from "@/store/manager";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import {
	TrancheScheduleOrderConditionModeTypeEnum
} from "@/store/tranches/modules/application/types/TrancheScheduleOrderConditionModeTypeEnum";
import { TrancheScheduleSnapshotKeysEnum } from "@/store/tranches/modules/application/types/TrancheScheduleSnapshotKeysEnum";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import { TrancheInfoDialogTypeEnum } from "@/types/tranches/TrancheInfoDialogTypeEnum";
import { sumFloat } from "@/utils/number";
import TranchesInfoDialog from "@/views/tranches/dialogs/TranchesInfoDialog.vue";
import TranchesTrancheApplicationTrancheScheduleAcceptTable
	from "@/views/tranches/sections/trancheSchedule/acceptTable/TranchesTrancheApplicationTrancheScheduleAcceptTable.vue";
import TranchesTrancheApplicationTrancheScheduleOrderApplicationTableItem
	from "@/views/tranches/sections/trancheSchedule/orderApplicationTableItem/TranchesTrancheApplicationTrancheScheduleOrderApplicationTableItem.vue";
import TranchesTrancheApplicationTrancheScheduleLoader
	from "@/views/tranches/sections/trancheSchedule/TranchesTrancheApplicationTrancheScheduleLoader.vue";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import { addDays } from "date-fns";
import { cloneDeep } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { formatDate, removeTime } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import { listMixin } from "Mixins/listMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const trancheApplicationDocumentsHelpers = createNamespacedHelpers(storeManager.tranches.trancheApplication.documents);

export default {
	mixins: [colorsMixin, storeModuleBasedPage, listMixin, authorizationMixin],
	data() {
		return {
			namespace,
			FileTypes,
			TrancheScheduleStatusTypeEnum,
			ApiTrancheStatusTypeEnum,
			TrancheScheduleModeTypeEnum,
			TrancheScheduleOrderConditionModeTypeEnum,
			TrancheScheduleSnapshotKeysEnum,
			TrancheInfoDialogTypeEnum,
			formatCurrency,
			formatDate,
			removeTime,
			addDays,
			cloneDeep,
			dateFormat,
			isInfoDialogOpened: false
		};
	},
	computed: {
		...mapGetters({
			confirmableTranche: getterTypes.confirmableTranche,
			isAcceptedTrancheScheduleStatus: getterTypes.isAcceptedTrancheScheduleStatus,
			formattedItems: getterTypes.formattedItems
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			mode: state => state.mode,
			isTrancheConfirming: state => state.isTrancheConfirming,
			orderConditionMode: state => state.orderConditionMode,
			isTrancheScheduleFormValid: state => state.isTrancheScheduleFormValid,
			editableItems: state => state.editableItems,
			editableOrderConditionItems: state => state.editableOrderConditionItems,
			trancheSchedule: state => state.trancheSchedule,
			isTrancheScheduleSaving: state => state.isTrancheScheduleSaving,
			isTrancheScheduleForApprovalByManagerSending: state => state.isTrancheScheduleForApprovalByManagerSending,
			isTrancheScheduleUnsavedChangesDialogEnabled: state => state.isTrancheScheduleUnsavedChangesDialogEnabled
		}),
		headers() {
			const deleteHeader = {
				text: "",
				value: "delete",
				class: "tranches-table-header-text primary--text text--lighten-1",
				width: "1%",
				sortable: false
			};
			
			let allHeaders = [
				{
					text: this.$t("tables.numberSymbol"),
					value: "number",
					class: "tranches-table-header-text",
					width: "5%",
					sortable: false
				},
				{
					text: this.$t("tables.trancheSum"),
					value: "amount",
					class: "tranches-table-header-text",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.plannedOrderDate"),
					value: "plannedDate",
					class: "tranches-table-header-text",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.orderApplication"),
					value: "orderApplication",
					class: "tranches-table-header-text",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.orderDate"),
					value: "orderDate",
					class: "tranches-table-header-text",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.orderConfirmation"),
					value: "orderConfirmation",
					class: "tranches-table-header-text",
					width: "20%",
					sortable: false
				}
			];
			
			if(this.mode === TrancheScheduleModeTypeEnum.EDIT)
				allHeaders.push(deleteHeader);
			
			return allHeaders;
			
		},
		trancheScheduleFormValid: {
			get() {
				return this.isTrancheScheduleFormValid;
			},
			set(value) {
				this.setIsTrancheScheduleFormValid(value);
			}
		},
		isFormDisabled() {
			return this.isTrancheScheduleSaving || this.isTrancheScheduleForApprovalByManagerSending;
		}
	},
	methods: {
		...mapActions({
			rollback: actionTypes.cancelChanges,
			createOrderTranchePaymentApplication: actionTypes.createOrderTranchePaymentApplication,
			addEditableItemTranche: actionTypes.addEditableItemTranche,
			deleteEditableItemTranche: actionTypes.deleteEditableItemTranche,
			confirmTranche: actionTypes.confirmTranche
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			setEditableItems: mutationTypes.SET_EDITABLE_ITEMS,
			setIsTrancheScheduleFormValid: mutationTypes.SET_IS_TRANCHE_SCHEDULE_FORM_VALID,
			setTrancheScheduleSum: mutationTypes.SET_TRANCHE_SCHEDULE_SUM,
			setIsTrancheScheduleUnsavedChangesDialogEnabled: mutationTypes.SET_IS_TRANCHE_SCHEDULE_UNSAVED_CHANGES_DIALOG_ENABLED
		}),
		getSum(items) {
			let sum;
			
			if(this.mode === TrancheScheduleModeTypeEnum.EDIT)
				sum = this.editableItems.map(x => x.amount).reduce((acc, x) => sumFloat(acc, +x), 0);
			else
				sum = items.map(x => x.amount).reduce((acc, x) => sumFloat(acc, +x), 0);
			
			this.setTrancheScheduleSum(sum);
			return sum;
		},
		async onCancelFormSubmit(key) {
			await this.rollback(key);
			
			this.setIsTrancheScheduleUnsavedChangesDialogEnabled(false);
			this.setMode(TrancheScheduleModeTypeEnum.READ);
			
			this.$nextTick(() => {
				this.$refs.trancheScheduleForm.resetValidation();
			});
		},
		async handleConfirmTranche() {
			const res = await this.confirmTranche();
			
			if(res)
				this.isInfoDialogOpened = true;
		}
	},
	watch: {
		mode: {
			async handler(value) {
				if(value === TrancheScheduleModeTypeEnum.EDIT)
					this.$nextTick(() => {
						this.$refs.trancheScheduleForm.resetValidation();
					});
			},
			immediate: true
		}
	},
	components: {
		TranchesInfoDialog,
		TranchesTrancheApplicationTrancheScheduleOrderApplicationTableItem,
		TranchesTrancheApplicationTrancheScheduleAcceptTable,
		LoanConfirmDialog,
		FrpIcon,
		TranchesTrancheApplicationTrancheScheduleLoader,
		FrpCard,
		FrpAutocomplete,
		FrpDateField,
		FrpTextField,
		FrpBtn
	}
};
</script>
<style lang="scss">
.tranches-table-header-text {
  font-size: 0.875rem !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: 0.004em !important;
  line-height: 140% !important;
  color: var(--v-primary-lighten1) !important;
}
</style>
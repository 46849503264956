import User from "@/store/modules/user/types/user";
import IPageState from "@/store/shared/base/types/pageState";

export default class UserState implements IPageState {
	constructor(
		public user: User = new User(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false
	)
	{
	}
}

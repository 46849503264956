import { ApiExpertiseEmployee } from "@/api/expertise/types/apiExpertiseEmployee";

export interface ExpertiseEmployee {
	id: number;
	email: string;
	name: string;
	olkId: string;
	olkEmployeeId: string;
}

export class ExpertiseEmployeeHelper {
	static map(source: ApiExpertiseEmployee): ExpertiseEmployee {
		return {
			id: source.ID,
			email: source.EMAIL,
			name: source.NAME,
			olkId: source.OLK_ID,
			olkEmployeeId: source.OLK_EMPLOYEE_ID
		};
	}
}

<template>
	<div class="tranches-details-item px-4">
		<template v-if="$slots['title-prepend']">
			<slot name="title-prepend"></slot>
		</template>
		
		<template v-if="title">
			<v-row>
				<v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
					<frp-text-btn v-if="link"
								  :to="to"
								  :wrap="wrapLink"
								  class="pa-0 tranches-text-btn"
								  :text="title"
								  @click="$emit('link:click')"
								  color="primary">
					</frp-text-btn>
					
					<span v-else class="tranches-details-item-title text-body-2 primary--text text--darken-4">{{ title }}</span>
				</v-col>
			</v-row>
			
			<v-spacer></v-spacer>
		</template>
		
		<template v-if="isLoading">
			<v-row>
				<v-col>
					<frp-text-loader :width="loadingTitleWidth"></frp-text-loader>
				</v-col>
			</v-row>
			
			<v-spacer></v-spacer>
		</template>
		
		<div :class="{'d-flex flex-column align-end text-end': $vuetify.breakpoint.mdAndDown}">
			<span v-if="text" class="text-subtitle-2 primary--text">
				<slot></slot>
			</span>
			
			<template v-else-if="$slots['content']">
				<slot name="content"></slot>
			</template>
			
			<slot v-else></slot>
		</div>
	</div>
</template>

<script>
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";

export default {
	components: { FrpTextLoader, FrpTextBtn },
	props: {
		title: String,
		text: {
			type: Boolean,
			default: false
		},
		link: {
			type: Boolean,
			default: false
		},
		wrapLink: {
			type: Boolean,
			default: false
		},
		to: Object,
		isLoading: {
			type: Boolean,
			default: false
		},
		loadingTitleWidth: {
			type: [String, Number],
			default: 100
		},
		cols: {
			type: Boolean | String | Number,
			default: false
		},
		sm: {
			type: Boolean | String | Number,
			default: false
		},
		md: {
			type: Boolean | String | Number,
			default: false
		},
		lg: {
			type: Boolean | String | Number,
			default: false
		},
		xl: {
			type: Boolean | String | Number,
			default: false
		}
	}
};
</script>

<style scoped lang="scss">
</style>

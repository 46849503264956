import baseMixinTypes from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "office";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	handleInnInput: "handleInnInput"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_OFFICE: "SET_OFFICE",
	SET_INN: "SET_INN",
	SET_OGRN: "SET_OGRN",
	SET_KPP: "SET_KPP",
	SET_SHORT_NAME: "SET_SHORT_NAME",
	SET_LONG_NAME: "SET_LONG_NAME",
	SET_DESCRIPTION: "SET_DESCRIPTION",
	SET_IS_EGRUL_LEGAL_ENTITY_LOADING: "SET_IS_EGRUL_LEGAL_ENTITY_LOADING"
};

const officeGeneralTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default officeGeneralTypes;

import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import ApiBodFile from "@/api/bod/types/apiBodFile";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiSection from "@/api/bod/types/apiSection";
import { Type } from "class-transformer";

export default class ApiDocument {
	id: string;
	createdAt: string;
	title: string;
	status: string;

	@Type(() => ApiSection)
	section: ApiSection;

	@Type(() => ApiFinanceScheme)
	financeSchemes: ApiFinanceScheme[];

	@Type(() => ApiDepartment)
	responsibleDepartment: ApiDepartment;

	@Type(() => ApiBodUser)
	responsibleUser: ApiBodUser;

	@Type(() => ApiBodFile)
	editableFile: ApiBodFile;

	@Type(() => ApiBodFile)
	nonEditableFile: ApiBodFile;

	linkedToFinanceSchemes: boolean;
	isWeb: boolean;
	webLink: string;

	constructor(
		id: string = "",
		createdAt: string = "",
		title: string = "",
		status: string = "",
		section: ApiSection = new ApiSection(),
		financeSchemes: ApiFinanceScheme[] = [],
		responsibleDepartment: ApiDepartment = new ApiDepartment(),
		responsibleUser: ApiBodUser = new ApiBodUser(),
		editableFile: ApiBodFile = new ApiBodFile(),
		nonEditableFile: ApiBodFile = new ApiBodFile(),
		linkedToFinanceSchemes: boolean = false,
		isWeb: boolean = false,
		webLink: string = ""
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.title = title;
		this.status = status;
		this.section = section;
		this.financeSchemes = financeSchemes;
		this.responsibleDepartment = responsibleDepartment;
		this.responsibleUser = responsibleUser;
		this.editableFile = editableFile;
		this.nonEditableFile = nonEditableFile;
		this.linkedToFinanceSchemes = linkedToFinanceSchemes;
		this.isWeb = isWeb;
		this.webLink = webLink;
	}
}

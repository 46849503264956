export default class ApiApp {
	id: number;
	name: string;
	url: string;
	alias: string;
	group: string | null;
	displayOrder: number;

	constructor(
		id: number = 0,
		name: string = "",
		url: string = "",
		alias: string = "",
		group: string | null,
		displayOrder: number = 0
	)
	{
		this.id = id;
		this.name = name;
		this.url = url;
		this.alias = alias;
		this.group = group;
		this.displayOrder = displayOrder;
	}
}

import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import ApiAccount from "@/api/types/account/apiAccount";
import ApiLegalEntityProfile from "@/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@/api/types/account/profile/apiEntrepreneurProfile";
import ApiForeignOrganizationProfile from "@/api/types/account/profile/apiForeignOrganizationProfile";
import ApiLegalEntityEmployeeProfile from "@/api/types/account/profile/apiLegalEntityEmployeeProfile";
import ApiEntrepreneurEmployeeProfile from "@/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiOfficeEmployeeProfile from "@/api/types/account/profile/apiOfficeEmployeeProfile";

type ProfileType =
	ApiAccountProfileBase
	| ApiLegalEntityProfile
	| ApiEntrepreneurProfile
	| ApiForeignOrganizationProfile
	| ApiLegalEntityEmployeeProfile
	| ApiEntrepreneurEmployeeProfile
	| ApiOfficeEmployeeProfile;

export default class User {
	profile: ProfileType | null;
	account: ApiAccount;
	profiles: ApiAccountProfileBase[];
	isUserinfoLoading: boolean;
	isProfilesLoading: boolean;
	permissions: string[];

	constructor(
		profile: ApiAccountProfileBase | null = null,
		account = new ApiAccount(),
		profiles: ApiAccountProfileBase[] = [],
		isUserinfoLoading: boolean = false,
		isProfilesLoading: boolean = false,
		permissions: string[] = []
	)
	{
		this.profile = profile;
		this.account = account;
		this.profiles = profiles;
		this.isUserinfoLoading = isUserinfoLoading;
		this.isProfilesLoading = isProfilesLoading;
		this.permissions = permissions;
	}
}

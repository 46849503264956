export const masks = {
	legalEntityInn: `##########`,
	inn: `############`,
	kpp: `####XX###`,
	ogrn: `#############`,
	ogrnip: `###############`,
	snils: `###-###-###-##`,
	date: `##.##.####`,
	dateRange: `##.##.####-##.##.####`,
	bankIdentificationCode: `#########`,
	bankAccountNumber: `####################`,
	correspondentAccount: `####################`,
	bik: '#########'
}

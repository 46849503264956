import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "employee-join-application-details";

export const getterTypes = {
	title: "title"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	updateDetails: "updateDetails",
	approveApplication: "approveApplication",
	rejectApplication: "rejectApplication"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_DETAILS: "SET_DETAILS",
	SET_APPLICATION_STATUS: "SET_APPLICATION_STATUS",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_APPROVING: "SET_IS_APPROVING",
	SET_IS_REJECTING: "SET_IS_REJECTING",
	SET_ID: "SET_ID"
};

const employeeJoinApplicationDetailsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default employeeJoinApplicationDetailsTypes;

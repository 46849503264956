import { mutationTypes } from "@/store/shared/embeddedForm/types";
import { MutationTree } from "vuex";
import EmbeddedForm from "@/store/shared/embeddedForm/types/embeddedForm";
import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "@/store/shared/embeddedForm/types/embeddedFormModeType";

export default class EmbeddedFormMixinBuilder {
	constructor() {
	}

	build() {
		return {
			getters: {},
			state: () => new EmbeddedForm(false,
				false,
				false,
				false,
				false,
				false,
				EmbeddedFormEditModeType.UNKNOWN,
				EmbeddedFormModeType.UNKNOWN),
			mutations: <MutationTree<{ form: EmbeddedForm }>>{
				[mutationTypes.SET_IS_FORM_LOADING](state, value) {
					state.form.isLoadingState = value;
				},
				[mutationTypes.SET_IS_FORM_READONLY](state, value) {
					state.form.isReadonly = value;
				},
				[mutationTypes.SET_IS_FORM_SAVING](state, value) {
					state.form.isSaving = value;
				},
				[mutationTypes.SET_IS_FORM_VALID](state, value) {
					state.form.isValid = value;
				},
				[mutationTypes.SET_IS_FORM_DISABLED](state, value) {
					state.form.isDisabled = value;
				},
				[mutationTypes.SET_IS_FORM_INITIALIZED](state, value) {
					state.form.isInitialized = value;
				},
				[mutationTypes.SET_FORM_MODE](state, value) {
					state.form.mode = value;
				},
				[mutationTypes.SET_FORM_EDIT_MODE](state, value) {
					state.form.editMode = value;
				}
			},
			actions: {}
		};
	}
}

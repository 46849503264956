import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyType } from "@/types/CounterpartyType";

export default class ApiForeignOrganizationCounterparty extends ApiCounterpartyBase {
	longName: string;
	shortName: string;
	countryCode: string;
	legalAddress: string;
	description: string;

	constructor(
		type: CounterpartyType = CounterpartyType.FOREIGN_ORGANIZATION,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false,
		longName: string = "",
		shortName: string = "",
		countryCode: string = "",
		legalAddress: string = "",
		description: string = ""
	)
	{
		super(type, isRfrp, isLeasingCompany, isPledgeExpertCompany, isVkmExpertCompany, isFrpAssigneeCompany);

		this.longName = longName;
		this.shortName = shortName;
		this.countryCode = countryCode;
		this.legalAddress = legalAddress;
		this.description = description;
	}
}

import ApiBodFile from "@/api/bod/types/apiBodFile";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import ApiDocument from "@/api/bod/types/apiDocument";
import { Type } from "class-transformer";
import ApiBodPersonAcceptor from "@/api/bod/types/apiBodPersonAcceptor";
import ApiBodDepartmentAcceptor from "@/api/bod/types/apiBodDepartmentAcceptor";
import { AcceptorType } from "@/store/bod/modules/document/types/acceptorType";
import ApiBodAcceptorBase from "@/api/bod/types/apiBodAcceptorBase";

export type ApiAcceptor =
	ApiBodAcceptorBase
	| ApiBodPersonAcceptor
	| ApiBodDepartmentAcceptor

export default class ApiChangeRequest {
	id: string;
	createdAt: string;
	status: string;
	type: string;
	changeWarrant: string;

	@Type(() => ApiBodUser)
	responsibleUser: ApiBodUser;

	@Type(() => ApiBodUser)
	initiator: ApiBodUser;

	description: string;

	@Type(() => ApiBodFile)
	changeWarrantFile: ApiBodFile;

	@Type(() => ApiBodFile)
	editableFile: ApiBodFile;

	@Type(() => ApiBodFile)
	nonEditableFile: ApiBodFile;

	@Type(() => ApiDocument)
	document: ApiDocument;

	webLink: string;
	declineReason: string;

	@Type(() => ApiBodAcceptorBase, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: ApiBodPersonAcceptor, name: AcceptorType.Person },
				{ value: ApiBodDepartmentAcceptor, name: AcceptorType.Department }
			]
		}
	})
	acceptors: ApiAcceptor[];

	constructor(
		id: string = "",
		createdAt: string = "",
		status: string = "",
		type: string = "",
		changeWarrant: string = "",
		responsibleUser: ApiBodUser = new ApiBodUser(),
		initiator: ApiBodUser = new ApiBodUser(),
		description: string = "",
		changeWarrantFile: ApiBodFile = new ApiBodFile(),
		editableFile: ApiBodFile = new ApiBodFile(),
		nonEditableFile: ApiBodFile = new ApiBodFile(),
		document: ApiDocument = new ApiDocument(),
		webLink: string = "",
		declineReason: string = "",
		acceptors: ApiAcceptor[] = []
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.status = status;
		this.type = type;
		this.changeWarrant = changeWarrant;
		this.changeWarrantFile = changeWarrantFile;
		this.responsibleUser = responsibleUser;
		this.initiator = initiator;
		this.description = description;
		this.editableFile = editableFile;
		this.nonEditableFile = nonEditableFile;
		this.document = document;
		this.webLink = webLink;
		this.declineReason = declineReason;
		this.acceptors = acceptors;
	}
}

export interface ApiBorrowerOrganization {
	fullName: string;
	inn: string;
	kpp: string;
	ogrn: string;
	olkId: string;
	shortName: string;
}

export class ApiBorrowerOrganizationHelper {
	static getEmpty(): ApiBorrowerOrganization {
		return {
			fullName: "",
			inn: "",
			kpp: "",
			ogrn: "",
			olkId: "",
			shortName: ""
		};
	}
}
// @ts-nocheck
import Vue from "vue";
import colorsMixin from "@/mixins/colorsMixin";

const alertMixin = Vue.extend({
	mixins: [colorsMixin],
	props: {
		type: {
			type: String
		},
		customBackgroundColor: String,
		colorClass: {
			type: String,
			default: "white--text"
		}
	},
	computed: {
		backgroundColor() {
			if(this.customBackgroundColor)
				return this.customBackgroundColor;

			switch (this.type) {
				case "info":
					return this.colors.blue.base;
				case "error":
					return this.colors.secondary.base;
				case "warning":
					return this.colors.warning.base;
				case "success":
					return this.colors.green.base;
			}
		}
	}
});

export default alertMixin;

import embeddedPasswordModule from "@/store/shared/embeddedPasswordForm";
import { actionTypes, mutationTypes } from "@/store/shared/embeddedPasswordForm/types";
import { ActionTree, MutationTree } from "vuex";
import EmbeddedPasswordFormState from "@/store/shared/embeddedPasswordForm/types/embeddedPasswordFormState";
import { AccountController } from "@/api/account";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import AccountGeneralState from "@/store/modules/account/modules/general/types/accountGeneralState";
import { abortService } from "@/store/shared/embeddedPasswordForm";

const accountController = new AccountController(abortService);

export const namespace = "password";

const actions = <ActionTree<EmbeddedPasswordFormState, any>>{
	async [actionTypes.update]({ dispatch, commit, state, rootState }) {
		let { id: accountId } = resolveNestedState<AccountGeneralState>(rootState, storeManager.account.general.namespace);

		await accountController.updateAccountPassword(accountId, state.password);
	}
};

const accountGeneralPasswordModule = {
	namespace,
	...embeddedPasswordModule,
	actions: {
		...embeddedPasswordModule.actions,
		...actions
	}
};

export default accountGeneralPasswordModule;

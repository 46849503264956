<template>
	<div class="d-flex flex-column" style="row-gap: 10px">
		<div style="min-height: 19px" v-for="value in item">
			{{ value }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Array,
			required: true
		}
	}
};
</script>

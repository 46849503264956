import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import { Type } from "class-transformer";

export default class ApiLegalEntityCounterpartyHeadPersisted {
	id: string;
	@Type(() => ApiLegalEntityCounterpartyHead)
	legalEntityHead: ApiLegalEntityCounterpartyHead;

	constructor(id: string, legalEntityHead: ApiLegalEntityCounterpartyHead) {
		this.id = id;
		this.legalEntityHead = legalEntityHead;
	}
}

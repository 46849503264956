export const mutationTypes = {
	SET_STATE_SNAPSHOT: "SET_STATE_SNAPSHOT",
	ADD_FIELD_TO_SNAPSHOT_OPTION: "ADD_FIELD_TO_SNAPSHOT_OPTION",
	REMOVE_FIELD_FROM_SNAPSHOT_OPTION: "REMOVE_FIELD_FROM_SNAPSHOT_OPTION",
	ROLLBACK_STATE: "ROLLBACK_STATE"
};

export const getterTypes = {
	stateContainsUnsavedChanges: "stateContainsUnsavedChanges"
};

export const actionTypes = {
	cancelChanges: "cancelChanges",
	cancelChangesBase: "cancelChangesBase",
};

const snapshotMixinTypes = {
	actionTypes,
	getterTypes,
	mutationTypes
};

export default snapshotMixinTypes;

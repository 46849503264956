export enum ProfileType {
	UNKNOWN = "Unknown",

	MASTER_ACCOUNT_LEGAL_ENTITY = "LegalEntity",
	MASTER_ACCOUNT_PHYSICAL_PERSON = "Individual",
	MASTER_ACCOUNT_ENTREPRENEUR = "Entrepreneur",
	MASTER_ACCOUNT_FOREIGN_ORGANIZATION = "ForeignOrganization",
	MASTER_ACCOUNT_OFFICE = "Office",

	COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE = "LegalEntityEmployee",
	COUNTERPARTY_ENTREPRENEUR_EMPLOYEE = "EntrepreneurEmployee",
	COUNTERPARTY_PHYSICAL_PERSON_EMPLOYEE = "IndividualEmployee",
	COUNTERPARTY_FOREIGN_ORGANIZATION_EMPLOYEE = "ForeignOrganizationEmployee",

	OFFICE_EMPLOYEE = "OfficeEmployee",
}

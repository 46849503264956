import urls from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";

export class AuthController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	signInWithProfile = async (payload: any, query: string): Promise<any> => {
		let { redirectUri } = await this.client.post<any>(prepareUrl(`${urls.auth.signInWithProfile}${query}`), payload, {
			withCredentials: true
		});

		return redirectUri;
	};
}

<template>
	<v-radio-group hide-details
				   class="pa-0 ma-0 d-flex align-center py-2"
				   :class="{ itemsNoWrap: noWrap }"
				   :value="value"
				   dense
				   :mandatory="mandatory"
				   @change="$emit('update:value', $event)">
		<v-container class="pa-0 mx-0" fluid>
			<v-row :class="{'flex-column': $vuetify.breakpoint.smAndDown}" :style="wrapperStyle">
				<v-col :class="itemClass" md="auto" v-for="({label, value}, i) of items" :key="i">
					<v-radio :value="value" :color="highlightItemValue === value ? highlightItemColor : color">
						<template #label>
								<span :style="`color: ${highlightItemValue === value ? highlightItemColor : ''}`">
									{{ label }}
								</span>
						</template>
					</v-radio>
				</v-col>
			</v-row>
		</v-container>
	</v-radio-group>
</template>

<script>
export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: {
			required: true
		},
		items: {
			required: true,
			type: Array
		},
		noWrap: {
			type: Boolean,
			default: false
		},
		mandatory: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: "secondary"
		},
		highlightItemValue: {
			type: String,
			default: ""
		},
		itemClass: {
			type: String,
			default: "px-2"
		},
		wrapperStyle: {
			type: String,
			default: ""
		},
		highlightItemColor: {
			type: String,
			default: ""
		}
	}
};
</script>
<style lang="scss">
.itemsNoWrap {
  .row {
	flex-wrap: nowrap;
  }
}
</style>


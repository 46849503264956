export enum FileTypes {
	DEFAULT = "application/octet-stream",
	JPG = "image/jpeg",
	PNG = "image/png",
	BD = ".bd*",
	PDF = "application/pdf",
	DOC = "application/msword",
	X_RAR = "application/x-rar",
	RAR = ".rar",
	"7Z" = ".7z",
	ZIP = "application/zip",
	X_ZIP_COMPRESSED = "application/x-zip-compressed",
	X_RAR_COMPRESSED = "application/x-rar-compressed",
	DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	XLS = "application/vnd.ms-excel",
	XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

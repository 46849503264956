import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";

export const namespace = "general";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	recognizeSnils: "recognizeSnils",
	create: "create",
	fetch: "fetch"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_IS_SNILS_RECOGNIZING: "SET_IS_SNILS_RECOGNIZING",
	SET_EMPLOYEE: "SET_EMPLOYEE",
	SET_COUNTERPARTY_ID: "SET_COUNTERPARTY_ID",
	SET_EMPLOYEE_FIRST_NAME: "SET_EMPLOYEE_FIRST_NAME",
	SET_EMPLOYEE_LAST_NAME: "SET_EMPLOYEE_LAST_NAME",
	SET_EMPLOYEE_MIDDLE_NAME: "SET_EMPLOYEE_MIDDLE_NAME",
	SET_EMPLOYEE_IS_ACTIVE: "SET_EMPLOYEE_IS_ACTIVE",
	SET_EMPLOYEE_SNILS: "SET_EMPLOYEE_SNILS",
	SET_EMPLOYEE_POSITION: "SET_EMPLOYEE_POSITION",
	SET_EMPLOYEE_EMAIL: "SET_EMPLOYEE_EMAIL",
	SET_EMPLOYEE_PHONE: "SET_EMPLOYEE_PHONE",
	SET_ID: "SET_ID",
	EMPLOYEE_UPDATED_EVENT: "EMPLOYEE_UPDATED_EVENT",
	SET_EMPLOYEE_HAS_LOAN_AGREEMENTS_ACCESS: "SET_EMPLOYEE_HAS_LOAN_AGREEMENTS_ACCESS",
	SET_EMPLOYEE_SNILS_FILE_META: "SET_EMPLOYEE_SNILS_FILE_META",
	SET_EMPLOYEE_SNILS_FILE_META_IS_LOADING: "SET_EMPLOYEE_SNILS_FILE_META_IS_LOADING",
	SET_IS_SNILS_UPLOAD_AVAILABLE: "SET_IS_SNILS_UPLOAD_AVAILABLE",
	SET_IS_MASTER_ACCOUNT_EXIT_DIALOG_OPENED: "SET_IS_MASTER_ACCOUNT_EXIT_DIALOG_OPENED",
	SET_LAST_SAVED_SNILS_FILE_ID: "SET_LAST_SAVED_SNILS_FILE_ID"
};

const counterpartyEmployeeGeneralTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default counterpartyEmployeeGeneralTypes;

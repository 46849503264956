import baseMixinTypes from "@/store/shared/base/types";

export const namespace = "bar-user";

export const getterTypes = {
	isBarUserAdministrator: "isBarUserAdministrator",
	isBarUserProjectFinancialControlDepartmentManager: "isBarUserProjectFinancialControlDepartmentManager",
	userId: "userId",
	isBarUserBorrower: "isBarUserBorrower",
	isBarUserCuratorsDepartmentManager: "isBarUserCuratorsDepartmentManager",
	isBarUserLawyerManager: "isBarUserLawyerManager"
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	fetchUser: "fetchUser",
	fetchPermissions: "fetchPermissions"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	SET_IS_USER_LOADING: "SET_IS_USER_LOADING",
	SET_IS_PERMISSIONS_LOADING: "SET_IS_PERMISSIONS_LOADING",
	SET_USER: "SET_USER",
	SET_PERMISSIONS: "SET_PERMISSIONS"
};

const userTypes = {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
};

export default userTypes;

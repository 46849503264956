import { Location } from "vue-router/types/router";

export default class Breadcrumb {
	text: string;
	to: Location;
	disabled: boolean;

	constructor(text: string, to: Location, disabled: boolean = false) {
		this.text = text;
		this.to = to;
		this.disabled = disabled;
	}
}

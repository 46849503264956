import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "legal-entity-counterparty-head";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...stateManipulationMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetch: "fetch",
	recognizeSnils: "recognizeSnils"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_HEAD: "SET_HEAD",
	SET_HEAD_POSITION: "SET_HEAD_POSITION",
	SET_HEAD_DESCRIPTION: "SET_HEAD_DESCRIPTION",
	SET_HEAD_FULL_NAME: "SET_HEAD_FULL_NAME",
	SET_HEAD_INN: "SET_HEAD_INN",
	SET_HEAD_SNILS: "SET_HEAD_SNILS",
	SET_IS_HEAD_SNILS_RECOGNIZING: "SET_IS_HEAD_SNILS_RECOGNIZING"
};

const legalEntityCounterpartyHeadTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default legalEntityCounterpartyHeadTypes;

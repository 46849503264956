<template>
	<tranches-content-layout v-if="isInitialized">
		<v-row>
			<v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 7" class="d-flex flex-column" style="row-gap: 24px">
				<tranches-tranche-application-project-info />
				<tranches-tranche-application-tranche-schedule />
				<tranches-tranche-application-tranche-disbursement />
			</v-col>
			<v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5" v-if="trancheSchedule.status === TrancheScheduleStatusTypeEnum.PENDING"
				   class="d-flex flex-column" style="row-gap: 24px">
				<tranches-tranche-application-generation v-if="can(Permissions.TRANCHES_TRANCHE_SCHEDULE_MANAGE_FILES)" />
				<tranches-tranche-application-legal-expertise v-if="canViewExpertises && trancheSchedule.status === TrancheScheduleStatusTypeEnum.PENDING" />
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<tranches-tranche-application-actions></tranches-tranche-application-actions>
		</template>
	</tranches-content-layout>
	
	<tranches-tranche-application-loader v-else></tranches-tranche-application-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import LoanConfirmDialog from "@/components/dialogs/LoanConfirmDialog.vue";
import TranchesContentLayout from "@/components/layouts/TranchesContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import { i18n } from "@/plugins";
import { namespace, actionTypes, mutationTypes, getterTypes } from "@/store/tranches/modules/application/types";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import TranchesTrancheApplicationGeneration from "@/views/tranches/sections/generation/TranchesTrancheApplicationGeneration.vue";
import TranchesTrancheApplicationLegalExpertise from "@/views/tranches/sections/legalExpertise/TranchesTrancheApplicationLegalExpertise.vue";
import TranchesTrancheApplicationProjectInfo from "@/views/tranches/sections/projectInfo/TranchesTrancheApplicationProjectInfo.vue";
import TranchesTrancheApplicationTrancheDisbursement
	from "@/views/tranches/sections/trancheDisbursement/TranchesTrancheApplicationTrancheDisbursement.vue";
import TranchesTrancheApplicationTrancheSchedule
	from "@/views/tranches/sections/trancheSchedule/TranchesTrancheApplicationTrancheSchedule.vue";
import TranchesTrancheApplicationActions from "@/views/tranches/TranchesTrancheApplicationActions.vue";
import TranchesTrancheApplicationLoader from "@/views/tranches/TranchesTrancheApplicationLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [authorizationMixin],
	metaInfo() {
		return {
			title: `${i18n.t("metaTitles.tranches")}`
		};
	},
	data() {
		return {
			TrancheScheduleStatusTypeEnum
		}
	},
	computed: {
		...mapGetters({
			isDraftTrancheScheduleStatus: getterTypes.isDraftTrancheScheduleStatus
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			trancheSchedule: state => state.trancheSchedule,
			canViewExpertises: state => state.canViewExpertises,
		})
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		}),
		...mapMutations({})
	},
	components: {
		TranchesTrancheApplicationTrancheDisbursement,
		TranchesTrancheApplicationLoader,
		TranchesTrancheApplicationActions,
		LoanConfirmDialog,
		FrpBtn,
		TranchesContentLayout,
		TranchesTrancheApplicationProjectInfo,
		TranchesTrancheApplicationTrancheSchedule,
		TranchesTrancheApplicationGeneration,
		TranchesTrancheApplicationLegalExpertise
	},
	async created() {
		await this.initializeStore();
	}
};
</script>


<style scoped>

</style>
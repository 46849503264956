import { actionTypes, mutationTypes } from "@/store/shared/embeddedEmailForm/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import EmbeddedFormMixinBuilder from "@/store/shared/embeddedForm";
import EmbeddedEmailFormState from "@/store/shared/embeddedEmailForm/types/embeddedEmailFormState";
import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "@/store/shared/embeddedForm/types/embeddedFormModeType";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { EmbeddedEmailChangeModeType } from "@/store/shared/embeddedEmailForm/types/embeddedEmailChangeModeType";

export const abortService = new AbortService();

const formMixin = (new EmbeddedFormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new EmbeddedEmailFormState(
			formMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmbeddedEmailFormState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<EmbeddedEmailFormState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }, { email }) {
		await dispatch(actionTypes.initializeBase);

		if(email) {
			commit(mutationTypes.SET_CONFIRMED_EMAIL, email);
			commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.UPDATE);
		} else {
			commit(mutationTypes.SET_FORM_EDIT_MODE, EmbeddedFormEditModeType.CREATE);
		}

		commit(mutationTypes.SET_FORM_MODE, EmbeddedFormModeType.DETAILS);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.save]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			if(state.form.editMode === EmbeddedFormEditModeType.CREATE) {
				await dispatch(actionTypes.create);
			} else if(state.form.editMode === EmbeddedFormEditModeType.UPDATE) {
				await dispatch(actionTypes.update);
			}
			commit(mutationTypes.SET_FORM_MODE, EmbeddedFormModeType.SUCCESS);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.checkIsEmailUnique]() {
	},
	[actionTypes.setChangeMode]({ commit, state }, value) {
		commit(mutationTypes.SET_CHANGE_MODE, value);
	}
};

const mutations = <MutationTree<EmbeddedEmailFormState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_EMAIL](state, value) {
		state.email = value;
	},
	[mutationTypes.SET_CONFIRMED_EMAIL](state, value) {
		state.confirmedEmail = value;
	},
	[mutationTypes.SET_IS_EMAIL_UNIQUE](state, value) {
		state.isEmailUnique = value;
	},
	[mutationTypes.SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS](state, value) {
		state.isEmailUniqueCheckInProgress = value;
	},
	[mutationTypes.SET_CHANGE_MODE](state, value) {
		state.changeMode = value;
	}
};

export {
	state, getters, actions, mutations
};

const embeddedEmailModule = {
	state, getters, actions, mutations, namespaced: true
};

export default embeddedEmailModule;

import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "overdues";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	version: "version"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	fetch: "fetch",
	updateItems: "updateItems",
	createItem: "createItem",
	deleteItem: "deleteItem"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	RESET_NEW_ITEM: "RESET_NEW_ITEM",
	SET_EDITABLE_ITEMS: "SET_EDITABLE_ITEMS",
	SET_MODE: "SET_MODE",
	SET_IS_ITEMS_UPDATING: "SET_IS_ITEMS_UPDATING",
	SET_IS_ITEM_CREATING: "SET_IS_ITEM_CREATING",
	SET_IS_ITEM_DELETING: "SET_IS_ITEM_DELETING",
	SET_NEW_ITEM_SOURCE: "SET_NEW_ITEM_SOURCE",
	SET_NEW_ITEM_START_DATE: "SET_NEW_ITEM_START_DATE",
	SET_NEW_ITEM_QUAERTER: "SET_NEW_ITEM_QUAERTER",
	SET_NEW_ITEM_AMOUNT: "SET_NEW_ITEM_AMOUNT",
	SET_NEW_ITEM_IS_PROBLEM: "SET_NEW_ITEM_IS_PROBLEM",
	SET_NEW_ITEM_COMMENT: "SET_NEW_ITEM_COMMENT"
};

const overduesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default overduesTypes;

import { listingActionTypes, listingMutationTypes, listingGetterTypes } from "@/store/shared/listing/types";
import ListingModel from "@/store/shared/listing/models/listingModel";
import { MutationTree, GetterTree } from "vuex";

export default class ListingMixinBuilder {
	constructor() {
	}

	build() {
		return {
			getters: <GetterTree<{ listing: ListingModel<any> }, any>>{
				[listingGetterTypes.isListingEmpty]: state => {
					if(!state.listing.items)
						return;

					return !state.listing.items.length;
				}
			},
			mutations: <MutationTree<{ listing: ListingModel<any> }>>{
				[listingMutationTypes.SET_LISTING_ITEMS](state, value) {
					state.listing.items = value;
				},
				[listingMutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE](state, value) {
					state.listing.isLoadingState = value;
				}
			},
			actions: {}
		};
	}
}

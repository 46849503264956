import AlertModel from "@/store/modules/alerts/models/alertModel";

export default class AlertsStateModel {
	constructor(
		public errorAlerts: AlertModel[] = [],
		public infoAlerts: AlertModel[] = [],
		public nextAlertId: number = 0
	)
	{
	}
}

import baseMixinTypes from "@/store/shared/base/types";

export const namespace = "tranches-user";

export const getterTypes = {};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	fetchUser: "fetchUser",
	fetchPermissions: "fetchPermissions"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	SET_IS_USER_LOADING: "SET_IS_USER_LOADING",
	SET_IS_PERMISSIONS_LOADING: "SET_IS_PERMISSIONS_LOADING",
	SET_PROFILE: "SET_PROFILE",
	SET_ACCOUNT: "SET_ACCOUNT",
	SET_PERMISSIONS: "SET_PERMISSIONS"
};

const userTypes = {
	namespace,
	actionTypes,
	getterTypes
};

export default userTypes;

import { stringify, parse } from "qs";
import { Dictionary } from "vue-router/types/router";
import { isArray, isBoolean, isNil } from "lodash";

export const stringifyQuery = (query: Dictionary<string>) => {
	return stringify(query, { encode: false, arrayFormat: "comma" });
};

export const parseQuery = (query: string) => {
	return parse(query, { comma: true });
};

export const parseArrayParameter = (value: string | string[], defaultValues?: string[]) => {
	if(isArray(value))
		return value;

	return value ? value.split(",") : defaultValues || [];
};

export const parseBooleanParameter = (value: string | boolean, defaultValue: boolean) => {
	if(isBoolean(value))
		return value;

	return isNil(value) ? defaultValue : value === "true";
};

import { ApiExpertise } from "@/api/expertise/types/apiExpertise";
import { ExpertiseEmployee, ExpertiseEmployeeHelper } from "@/types/expertise/expertiseEmployee";
import { ExpertiseDictionary, ExpertiseDictionaryHelper } from "@/types/expertise/expertiseDictionary";
import { tryParseDateToTime } from "@/utils/dates";
import { dateWithSecondsFormat } from "@/utils/formats";

export interface Expertise {
	id: number;
	createdDate: number;
	createdBy: ExpertiseEmployee;
	expertiseStatusId: number;
	expertiseStatusInfo: ExpertiseDictionary;
	expertiseTypeId: number;
	expertiseTypeInfo: ExpertiseDictionary;
	expertiseSubtypeId: number;
	expertiseSubtypeInfo: ExpertiseDictionary;
	expert?: ExpertiseEmployee;
	expertiseDateStart: number;
	expertiseDateEnd: number;
	managerSetExpert?: ExpertiseEmployee;
	fileUrl: string;
	expertiseUrl: string;
	isDelete: number;
}

export class ExpertiseHelper {
	static map(source: ApiExpertise): Expertise {
		return {
			id: source.ID,
			createdDate: tryParseDateToTime(source.UF_DATE_CREATE, dateWithSecondsFormat, 0),
			createdBy: ExpertiseEmployeeHelper.map(source.UF_CREATED_BY),
			expertiseStatusId: source.UF_EXPERTISE_STATUS_ID,
			expertiseStatusInfo: ExpertiseDictionaryHelper.map(source.EXPERTISE_STATUS_INFO),
			expertiseTypeId: source.UF_EXPERTISE_TYPE_ID,
			expertiseTypeInfo: ExpertiseDictionaryHelper.map(source.EXPERTISE_TYPE_INFO),
			expertiseSubtypeId: source.UF_EXPERTISE_SUBTYPE_ID,
			expertiseSubtypeInfo: ExpertiseDictionaryHelper.map(source.EXPERTISE_SUBTYPE_INFO),
			expert: source.EXPERT && ExpertiseEmployeeHelper.map(source.EXPERT),
			expertiseDateStart: tryParseDateToTime(source.UF_DATE_START_EXPERT, dateWithSecondsFormat, 0),
			expertiseDateEnd: tryParseDateToTime(source.UF_DATE_END_EXPERTISE, dateWithSecondsFormat, 0),
			managerSetExpert: source.MANAGER_SET_EXPERT && ExpertiseEmployeeHelper.map(source.MANAGER_SET_EXPERT),
			fileUrl: source.FILE_URL,
			expertiseUrl: source.EXPERTISE_URL,
			isDelete: source.UF_IS_DELETE
		};
	}
}

export const formActionTypes = {
	save: "save",
	checkIsRecordUnique: "checkIsRecordUnique"
};

export const formGetterTypes = {
	isCompleted: "isCompleted"
};

export const formMutationTypes = {
	SET_IS_FORM_LOADING: "SET_IS_FORM_LOADING",
	SET_IS_FORM_SAVING: "SET_IS_FORM_SAVING",
	SET_IS_FORM_DISABLED: "SET_IS_FORM_DISABLED",
	SET_IS_FORM_INITIALIZED: "SET_IS_FORM_INITIALIZED",
	SET_IS_FORM_VALID: "SET_IS_FORM_VALID",
	SET_IS_FORM_READONLY: "SET_IS_FORM_READONLY",
	SET_IS_RECORD_UNIQUE: "SET_IS_RECORD_UNIQUE",
	SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS: "SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS"
};

export const formTypes = {
	actionTypes: formActionTypes,
	getterTypes: formGetterTypes,
	mutationTypes: formMutationTypes
};

export default formTypes;

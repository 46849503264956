<template>
	<frp-card v-if="isInitialized" :title="$t('titles.legalExpertise')">
		<template #actions>
			<frp-btn dense elevation="0" color="blue" dark @click="isExpertiseDialogOpened = true">
				{{ $t("buttons.appointExpertise") }}
			</frp-btn>
		</template>
		
		<template #content>
			<v-data-table v-if="expertises.length"
						  :headers="headers"
						  :loading-text="$t('tables.loading')"
						  :loading="isExpertisesLoading"
						  hide-default-footer
						  item-key="id"
						  group-by="id"
						  :items="expertises"
						  hide-default-header
						  :items-per-page="-1"
						  :custom-sort="sortGroup"
						  :mobile-breakpoint="mobileBreakpoint"
						  class="kpi-table tranches-expertises-table clickable-rows d-flex flex-column">
				<template #header="{ props: { headers } }">
					<colgroup class="kpi-colgroup">
						<col v-for="header in headers" span="1" :style="`width: ${header.width}`">
					</colgroup>
				</template>
				
				<template #group="{ items, headers }">
					<!--	GROUP	-->
					<tr class="v-row-group__header">
						<td colspan="4" class="py-2 px-2">
							<div class="d-flex align-center" style="cursor: pointer"
								 @click="toggleGroupOpened(items[0].id)">
								<frp-icon width="20" height="16" :color="colors.primary.darken1"
										  src="ico_chevron-up" style="cursor: pointer"
										  v-if="openedGroupIds.includes(items[0].id)">
								</frp-icon>
								<frp-icon width="20" height="16" :color="colors.primary.darken1"
										  src="ico_chevron-down" style="cursor: pointer"
										  v-else>
								</frp-icon>
								<span class="pl-2 primary--text" style="font-size: 12px">
										<frp-link-btn :href="externalUrls.getLkExpertiseUrl(projectInfo.id, items[0].id)"
													  @click.stop
													  style="font-size: inherit"
													  classes="d-inline"
													  target="_blank">
											{{ items[0].expertiseSubtypeInfo.name }}
										</frp-link-btn>
										<span class="mx-1 text-lowercase"> - {{ items[0].expertiseStatusInfo.name }}</span>
										<span v-if="isBoolean(checkIfExpertiseActive(items[0]))">
											-
											<span v-if="checkIfExpertiseActive(items[0])" class="green--text">
												{{ $t("content.expertiseActive") }}
											</span>
											<span v-else class="secondary--text">
												{{ $t("content.expertiseInactive") }}
											</span>
										</span>
									</span>
							</div>
						</td>
					</tr>
					
					<template v-if="openedGroupIds.includes(items[0].id)">
						<!--	HEADER	-->
						<tr>
							<td v-for="(header, i) in headers" :key="header.text"
								class="px-2 py-2 font-weight-bold"
								:class="{ 'pl-5': i === 0, 'pr-5': i === headers.length - 1 }"
								style="font-size: 10px; height: 34px">
								{{ header.text }}
							</td>
						</tr>
						
						<!--	BODY	-->
						<tr v-for="(item, i) in items" :key="item.id + i">
							<td v-for="(header, j) in headers" :key="header.value"
								style="font-size: 10px; height: 34px"
								class="px-2 py-2" :class="{ 'pl-5': j === 0, 'pr-5': j === headers.length - 1 }">
									
								<span v-if="header.value === 'createdBy'">
									{{ formatFullNameStringWithInitials(item.createdBy.name) }}
								</span>
								<span v-else-if="header.value === 'expert'">
										{{ item.expert ? formatFullNameStringWithInitials(item.expert.name) : "-" }}
									</span>
								<span v-else-if="header.value === 'createdDate'">
										{{ formatDate(item.createdDate, dateFormat) }}
									</span>
								<span v-else-if="header.value === 'expertiseDateEnd'">
										{{ item.expertiseDateEnd ? formatDate(item.expertiseDateEnd, dateFormat) : "-" }}
									</span>
								<span v-else>{{ item[header.value] }}</span>
							</td>
						</tr>
					</template>
				</template>
			</v-data-table>
			
			<frp-dialog v-model="isExpertiseDialogOpened" :title="$t('dialogs.trancheLegalExpertise.title')"
						max-width="496">
				<template #content="{ onIntersect }">
					<v-form ref="form" v-model="isFormValid" v-intersect="onIntersect">
						<frp-text-field class="kpi-field"
										disabled
										:label="$t('fields.expertiseType.label')"
										:value="$t('fields.expertiseType.placeholder')">
						</frp-text-field>
						
						<bod-autocomplete item-text="name"
										  item-value="code"
										  class="kpi-field"
										  required
										  :items="expertiseSubTypes"
										  v-model="selectedExpertiseSubType"
										  color="blue"
										  :label="$t('fields.expertiseSubType.label')"
										  :placeholder="$t('fields.expertiseSubType.placeholder')">
						</bod-autocomplete>
					</v-form>
				</template>
				
				<template #footer>
					<frp-btn @click="isExpertiseDialogOpened = false"
							 color="primary"
							 outlined>
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isExpertiseCreating"
							 :disabled="!isFormValid"
							 @click="handleAppoint">
						{{ $t("buttons.appoint") }}
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</frp-card>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpInformationMenu from "@/components/common/FrpInformationMenu.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpRadioGroup from "@/components/fields/FrpRadioGroup.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import TranchesCardContentLayout from "@/components/layouts/TranchesCardContentLayout.vue";
import { externalUrls } from "@/constants/tranches/externalUrls";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace, actionTypes } from "@/store/tranches/modules/application/types";
import { ExpertiseStatusTypeEnum } from "@/types/expertise/ExpertiseStatusTypeEnum";
import { ExpertiseSubTypeEnum } from "@/types/expertise/ExpertiseSubTypeEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatFullNameStringWithInitials } from "@/utils/formatting";
import KpiKpisArrayItem from "@/views/kpi/kpis/KpiKpisArrayItem.vue";
import TranchesTrancheApplicationGenerationLoader
	from "@/views/tranches/sections/generation/TranchesTrancheApplicationGenerationLoader.vue";
import { addMonths } from "date-fns";
import { cloneDeep, isBoolean } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin, listMixin],
	data() {
		return {
			namespace,
			RouteNames,
			externalUrls,
			dateFormat,
			formatDate,
			isBoolean,
			formatFullNameStringWithInitials,
			ExpertiseSubTypeEnum,
			openedGroupIds: [],
			isExpertiseDialogOpened: false,
			isFormValid: false,
			selectedExpertiseSubType: null,
			headers: [
				{
					text: this.$t("tables.expertiseAuthor"),
					value: "createdBy",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.createdDate"),
					value: "createdDate",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.responsibleExpert"),
					value: "expert",
					width: "30%",
					sortable: false
				},
				{
					text: this.$t("tables.endDate"),
					value: "expertiseDateEnd",
					width: "25%",
					sortable: false
				}
			]
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			projectInfo: state => state.projectInfo,
			expertises: state => state.expertises,
			isExpertisesLoading: state => state.isExpertisesLoading,
			isExpertiseCreating: state => state.isExpertiseCreating,
			expertiseTypes: state => state.expertiseTypes,
			expertiseSubTypes: state => state.expertiseSubTypes
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			tryCreateExpertise: actionTypes.tryCreateExpertise
		}),
		sortGroup(items) {
			return cloneDeep(items).sort((a, b) => b.createdDate - a.createdDate);
		},
		toggleGroupOpened(id) {
			if(this.openedGroupIds.includes(id))
				this.openedGroupIds = this.openedGroupIds.filter(x => x !== id);
			else
				this.openedGroupIds.push(id);
		},
		async handleAppoint() {
			const res = await this.tryCreateExpertise(this.selectedExpertiseSubType);
			
			if(res)
				this.isExpertiseDialogOpened = false;
		},
		checkIfExpertiseActive(expertise) {
			if(expertise.expertiseSubtypeInfo.code !== ExpertiseSubTypeEnum.FOR_ISSUING_LOAN_TRANCHE)
				return;
			if(expertise.expertiseStatusInfo.code !== ExpertiseStatusTypeEnum.END)
				return;
			if(!expertise.expertiseDateEnd)
				return;
			
			// Если прошло менее 3 месяцев с даты завершения экспертизы, то она действующая
			return addMonths(expertise.expertiseDateEnd, 3).getTime() > Date.now();
		}
	},
	watch: {
		async isExpertiseDialogOpened(value) {
			if(!value) {
				this.selectedExpertiseSubType = null;
				
				await this.$nextTick();
				this.$refs.form.resetValidation();
			}
		}
	},
	components: {
		BodAutocomplete,
		FrpTextarea, FrpInformationMenu, FrpRadioGroup, FrpTextField,
		KpiKpisArrayItem,
		FrpLinkBtn,
		FrpCard,
		TranchesCardContentLayout,
		TranchesTrancheApplicationGenerationLoader,
		FrpIcon,
		FrpDialog,
		FrpTextBtn,
		FrpBtn
	}
};
</script>

<style scoped>

</style>

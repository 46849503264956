<template>
	<div class="frp-details-item" v-if="value || $slots.default" :class="inline ? '' : 'd-flex flex-column'">
		<span class="text-caption">{{ title }}</span>
		<span v-if="inline">: </span>
		<slot>
			<div class="text-body-2 black--text d-flex align-center justify-space-between">
				<span>{{ value }}</span>
				<slot name="append"></slot>
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	props: {
		inline: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			required: true
		},
		value: {
			type: String,
			default: ""
		}
	}
};
</script>

<style scoped lang="less">
.frp-details-item {
	& + & {
		margin-top: 16px !important;
	}
}
</style>

<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ `${label}${required ? "*" : ""}` }}</span>
		</v-subheader>
		<v-autocomplete :items="internalItems"
						ref="frpAutocomplete"
						:placeholder="placeholder"
						outlined
						:return-object="returnObject"
						:background-color="colors.white.base"
						:clearable="clearable"
						dense
						:error="error"
						:item-text="itemText"
						:item-value="itemValue"
						@input="$emit('update:value', $event)"
						:value="value"
						v-mask="mask"
						:rules="[...defaultRules, ...rules]"
						:class="{
							'frp-dense-field': dense, 
							'frp-multiple-autocomplete': multiple && !chips, 
							'frp-multiple-autocomplete--transparent': transparentMultiple, 
							'frp-multiple-chips-autocomplete': multiple && chips,
							'frp-hidden-input-autocomplete': multiple && !chips
						}"
						:multiple="multiple"
						:hide-details="hideDetails"
						:disabled="disabled"
						:readonly="readonly"
						:chips="chips"
						:deletable-chips="deletableChips"
						v-bind="$attrs"
						v-on="$listeners"
						:loading="loading"
						@change="onChange"
						@update:search-input="query = $event"
						@mousedown="open"
						@focus="open"
						@blur="close"
						:menu-props="{ contentClass, auto, top, maxWidth: menuMaxWidth }"
						:no-data-text="noDataTextResult">
			<template #append>
				<v-fade-transition group hide-on-leave>
					<frp-icon key="up"
							  v-if="isOpened"
							  style="cursor: pointer"
							  :color="colors.primary.base"
							  src="ico_menu-up"
							  @click="close">
					</frp-icon>
					<frp-icon key="down"
							  v-else
							  :style="{ cursor: !disabled ? 'pointer' : 'default' }"
							  :color="colors.primary.base"
							  src="ico_menu-down"
							  @click="open">
					</frp-icon>
				</v-fade-transition>
			</template>
			<template #item="{ item, on, attrs }">
				<v-list-item class="px-0" @click="close" v-on="on" v-bind="attrs">
					<v-tooltip right content-class="frp-tooltip">
						<template v-slot:activator="{ on: onTooltip }">
							<v-list-item-content class="px-4" v-on="hint && onTooltip">
								<div class="d-flex align-center">
									<v-simple-checkbox v-if="multiple" class="mt-1" v-on="on" :value="attrs.inputValue"></v-simple-checkbox>
									<span>{{ itemText ? item[itemText] : item }}</span>
								</div>
							</v-list-item-content>
						</template>
						<span v-if="hint">{{ hint.text[item[hint.key]] }}</span>
					</v-tooltip>
				</v-list-item>
			</template>
			<template v-if="multiple && !chips" #selection="{ item, index }">
				<span v-if="index === 0"
					  class="overflow-hidden" :class="{ 'text-no-wrap': !height }"
					  style="text-overflow: ellipsis" :style="{ height }">
					{{ item[itemText] }}
				</span>
				<span v-if="index === 1" class="text-no-wrap ml-1">
					{{ `(+${value.length - 1})` }}
				</span>
			</template>
		</v-autocomplete>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { requiredAutocompleteValueRule, requiredMultipleAutocompleteRule, requiredRule } from "Utils/validation";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isOpened: false,
			internalItems: [],
			query: "",
		};
	},
	props: {
		rules: {
			type: Array,
			default: () => []
		},
		hint: Object,
		placeholder: String,
		label: String,
		disabled: Boolean,
		returnObject: Boolean,
		required: Boolean,
		clearable: Boolean,
		items: Array,
		value: [Object, String, Number, Array],
		mask: String,
		hideDetails: Boolean,
		dense: Boolean,
		error: Boolean,
		loading: Boolean,
		allowInvalidValues: Boolean,
		itemText: String,
		itemValue: String,
		menuMaxWidth: [String, Number],
		multiple: {
			type: Boolean,
			default: false
		},
		auto: {
			type: Boolean,
			default: false
		},
		transparentMultiple: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		chips: {
			type: Boolean,
			default: false
		},
		deletableChips: {
			type: Boolean,
			default: false
		},
		minQueryLength: Number,
		height: [String, Number],
		color: String,
		top: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		noDataTextResult() {
			if(!this.minQueryLength || this.query?.length >= this.minQueryLength)
				return "$vuetify.noDataText";
			else
				return this.$t("placeholders.request");
		},
		defaultRules() {
			if(this.required) {
				if(this.returnObject)
					return [requiredAutocompleteValueRule(this.itemText)];
				else if(this.multiple)
					return [requiredMultipleAutocompleteRule()];
				else
					return [requiredRule()];
			} else {
				return [];
			}
		},
		contentClass() {
			if(this.color === "blue")
				return "frp-menu frp-blue-menu";
			
			return "frp-menu";
		}
	},
	methods: {
		close() {
			this.isOpened = false;
		},
		open() {
			if(!this.disabled)
				this.isOpened = true;
		},
		updateItems() {
			if(!this.minQueryLength) {
				this.internalItems = this.items;
				return;
			}
			
			if(!this.query || this.query.length < this.minQueryLength)
				this.internalItems = [];
			else
				this.internalItems = this.items.filter(x => x[this.itemText].toLowerCase().includes(this.query.toLowerCase()));
			
			if(this.value) {
				const currentItem = this.items.find(x => x[this.itemValue] === this.value);
				if(currentItem && !this.internalItems.some(x => x[this.itemValue] === currentItem[this.itemValue]))
					this.internalItems.push(currentItem);
			}
			
		},
		onChange(value) {
			this.$emit("change", value);
		}
	},
	watch: {
		items() {
			this.updateItems();
			
			if(!this.allowInvalidValues && !this.multiple && this.value && !this.internalItems.some(x => x[this.itemValue] === this.value))
				this.$emit("update:value", null);
		},
		query() {
			this.updateItems();
		},
		value() {
			this.$emit("update:field-is-valid", this.$refs.frpAutocomplete.valid);
			
			this.updateItems();
		}
	},
	components: {
		FrpIcon
	}
};
</script>
<style scoped lang="scss">
.frp-tooltip {
  background-color: white;
  color: black;
  border: 5px solid red;
}
</style>

import { TrancheScheduleTranche } from "@/store/tranches/modules/application/types/trancheScheduleTranche";
import { convertToZonedIso } from "@/utils/dates";

export interface ApiUpdateTrancheScheduleRequestItem {
	number: number | null;
	amount: number;
	plannedDate: string;
}

export class ApiUpdateTrancheScheduleRequestItemMapper {
	static map(source: TrancheScheduleTranche): ApiUpdateTrancheScheduleRequestItem {
		return {
			number: source.number || null,
			amount: source.amount,
			plannedDate: convertToZonedIso(source.plannedDate) as string
		};
	}
}
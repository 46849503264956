import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "account-profiles";

export const getterTypes = {
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	handleSelectProfile: "handleSelectProfile"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	SET_IS_SELECT_ITEM_HANDLING: "SET_IS_SELECT_ITEM_HANDLING"
};

const accountProfilesTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default accountProfilesTypes;

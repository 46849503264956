import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyType } from "@/types/CounterpartyType";

export default class ApiPhysicalPersonCounterparty extends ApiCounterpartyBase {
	inn: string;
	snils: string | null;
	firstName: string;
	lastName: string;
	middleName: string;
	description: string;

	constructor(
		type: CounterpartyType = CounterpartyType.PHYSICAL_PERSON,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false,
		inn: string = "",
		snils: string = "",
		firstName: string = "",
		lastName: string = "",
		middleName: string = "",
		description: string = "")
	{
		super(type, isRfrp, isLeasingCompany, isPledgeExpertCompany, isVkmExpertCompany, isFrpAssigneeCompany);

		this.inn = inn;
		this.snils = snils;
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleName = middleName;
		this.description = description;
	}
}

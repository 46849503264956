import { Type } from "class-transformer";
import ApiChangeRequestsListItem from "@/api/bod/types/apiChangeRequestsListItem";

export default class ApiChangeRequestsListResponse {
	@Type(() => ApiChangeRequestsListItem)
	items: ApiChangeRequestsListItem[];
	totalCount: number;

	constructor(
		items: ApiChangeRequestsListItem[] = [],
		totalCount: number = 0
	)
	{
		this.items = items;
		this.totalCount = totalCount;
	}
}

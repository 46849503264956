<template>
	<v-chip v-if="isVisible" :color="color" label dark style="pointer-events: none" class="text-uppercase">
		{{ text }}
	</v-chip>
</template>

<script>
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		value: {
			type: [String, ApiTrancheStatusTypeEnum],
			required: true
		}
	},
	computed: {
		text() {
			return this.$t(`aliases.trancheStatusLabel.${this.value}`);
		},
		isVisible() {
			return [ApiTrancheStatusTypeEnum.ACCEPTED, ApiTrancheStatusTypeEnum.REJECTED].includes(this.value);
		},
		color() {
			switch (this.value) {
				case ApiTrancheStatusTypeEnum.ACCEPTED:
					return this.colors.green.base;
				case ApiTrancheStatusTypeEnum.REJECTED:
					return this.colors.secondary.lighten2;
			}
		}
	}
};
</script>

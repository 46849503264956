import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "kpi";

export const getterTypes = {
	staffUsers: "staffUsers",
	periodYears: "periodYears",
	canEditKpi: "canEditKpi"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	fetch: "fetch",
	fetchDictionaries: "fetchDictionaries",
	fetchPermissions: "fetchPermissions",
	initializeSubModules: "initializeSubModules",
	fetchStaffUsers: "fetchStaffUsers"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_ID: "SET_ID",
	SET_IS_FETCH_KPI_LOADING: "SET_IS_FETCH_KPI_LOADING",
	SET_KPI: "SET_KPI",
	SET_PERMISSIONS: "SET_PERMISSIONS",
	SET_IS_PERMISSIONS_LOADING: "SET_IS_PERMISSIONS_LOADING",
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_PERIODS: "SET_PERIODS",
	SET_STAFF_USERS: "SET_STAFF_USERS",
	SET_IS_STAFF_USERS_LOADING: "SET_IS_STAFF_USERS_LOADING"
};

const kpiTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default kpiTypes;

import { EmbeddedFormEditModeType } from "@/store/shared/embeddedForm/types/embeddedFormEditModeType";
import { EmbeddedFormModeType } from "@/store/shared/embeddedForm/types/embeddedFormModeType";


export default class EmbeddedForm {
	isLoadingState: boolean;
	isSaving: boolean;
	isDisabled: boolean;
	isValid: boolean;
	isInitialized: boolean;
	isReadonly: boolean;
	editMode: EmbeddedFormEditModeType;
	mode: EmbeddedFormModeType;

	constructor(isLoadingState: boolean,
		isSaving: boolean,
		isDisabled: boolean,
		isValid: boolean,
		isInitialized: boolean,
		isReadonly: boolean,
		editMode: EmbeddedFormEditModeType,
		mode: EmbeddedFormModeType)
	{
		this.isLoadingState = isLoadingState;
		this.isSaving = isSaving;
		this.isDisabled = isDisabled;
		this.isValid = isValid;
		this.isInitialized = isInitialized;
		this.isReadonly = isReadonly;
		this.editMode = editMode;
		this.mode = mode;
	}
}

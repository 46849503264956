import EmbeddedForm from "@/store/shared/embeddedForm/types/embeddedForm";
import { EmbeddedEmailChangeModeType } from "@/store/shared/embeddedEmailForm/types/embeddedEmailChangeModeType";

export default class EmbeddedEmailFormState {
	form: EmbeddedForm;
	email: string;
	confirmedEmail: string;
	isEmailUnique: boolean;
	isEmailUniqueCheckInProgress: boolean;
	changeMode: EmbeddedEmailChangeModeType;

	constructor(
		form: EmbeddedForm,
		email = "",
		confirmedEmail = "",
		isEmailUnique = true,
		isEmailUniqueCheckInProgress = false,
		changeMode = EmbeddedEmailChangeModeType.CHANGE_EMAIL
	)
	{
		this.form = form;
		this.email = email;
		this.confirmedEmail = confirmedEmail;
		this.isEmailUnique = isEmailUnique;
		this.isEmailUniqueCheckInProgress = isEmailUniqueCheckInProgress;
		this.changeMode = changeMode;
	}
}

import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "assignment-compensation";

export const getterTypes = {
	...formTypes.getterTypes,
	...listingGetterTypes,
	formattedAssignmentCompensationProjects: "formattedAssignmentCompensationProjects",
	formattedAssignmentAgreements: "formattedAssignmentAgreements",
	filteredAssignmentCompanies: "filteredAssignmentCompanies",
	formattedAssignmentCompanies: "formattedAssignmentCompanies"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchLastQuarter: "fetchLastQuarter",
	fetchDictionaries: "fetchDictionaries",
	setDefaultFilterValues: "setDefaultFilterValues",
	calculateAssignmentCompensation: "calculateAssignmentCompensation",
	createOrderForSubmissionToAccounting: "createOrderForSubmissionToAccounting",
	sendOrderDocumentToAccountingForApprove: "sendOrderDocumentToAccountingForApprove",
	addInterest: "addInterest"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_LAST_QUARTER: "SET_LAST_QUARTER",
	SET_ASSIGNMENT_AGREEMENTS: "SET_ASSIGNMENT_AGREEMENTS",
	SET_ASSIGNEE_COMPANIES: "SET_ASSIGNEE_COMPANIES",
	RESET_NEW_INTEREST_ITEM: "RESET_NEW_INTEREST_ITEM",
	SET_NEW_INTEREST_ITEM_PROJECT_ID: "SET_NEW_INTEREST_ITEM_PROJECT_ID",
	SET_NEW_INTEREST_ITEM_DATE: "SET_NEW_INTEREST_ITEM_DATE",
	SET_NEW_INTEREST_ITEM_PREFERENTIAL_AMOUNT: "SET_NEW_INTEREST_ITEM_PREFERENTIAL_AMOUNT",
	SET_NEW_INTEREST_ITEM_COMPENSABLE_AMOUNT: "SET_NEW_INTEREST_ITEM_COMPENSABLE_AMOUNT",
	SET_PROJECT_PREFERENTIAL_AMOUNT: "SET_PROJECT_PREFERENTIAL_AMOUNT",
	SET_PROJECT_COMPENSABLE_AMOUNT: "SET_PROJECT_COMPENSABLE_AMOUNT",
	SET_PROJECT_PERIOD_DAYS: "SET_PROJECT_PERIOD_DAYS",
	SET_PROJECT_COMPENSATION_AMOUNT: "SET_PROJECT_COMPENSATION_AMOUNT",
	SET_FILTER_ASSIGNMENT_PERIOD_START_DATE: "SET_FILTER_ASSIGNMENT_PERIOD_START_DATE",
	SET_FILTER_ASSIGNMENT_PERIOD_END_DATE: "SET_FILTER_ASSIGNMENT_PERIOD_END_DATE",
	SET_FILTER_ASSIGNMENT_AGREEMENT_ID: "SET_FILTER_ASSIGNMENT_AGREEMENT_ID",
	SET_FILTER_ASSIGNEE_ID: "SET_FILTER_ASSIGNEE_ID",
	SET_IS_ASSIGNMENT_COMPENSATION_CALCULATING: "SET_IS_ASSIGNMENT_COMPENSATION_CALCULATING",
	SET_IS_ORDER_FOR_SUBMISSION_TO_ACCOUNTING_CREATING: "SET_IS_ORDER_FOR_SUBMISSION_TO_ACCOUNTING_CREATING",
	SET_IS_ORDER_DOCUMENT_TO_ACCOUNTING_FOR_APPROVE_SENDING: "SET_IS_ORDER_DOCUMENT_TO_ACCOUNTING_FOR_APPROVE_SENDING",
	RESET_IS_REPORT_APPROVING_HAS_ERROR: "RESET_IS_REPORT_APPROVING_HAS_ERROR",
	SET_IS_REPORT_APPROVING_HAS_ERROR: "SET_IS_REPORT_APPROVING_HAS_ERROR",
	SET_ORDER_NUMBER_FOR_SUBMISSION_TO_ACCOUNTING: "SET_ORDER_NUMBER_FOR_SUBMISSION_TO_ACCOUNTING"
};

const assignmentCompensationTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default assignmentCompensationTypes;

<template>
	<v-skeleton-loader :height="height" :width="width" :type="type" :class="classes"/>
</template>

<script>
export default {
	props: {
		height: {
			type: [String, Number],
			default: 16
		},
		width: {
			type: [String, Number],
			default: 200
		},
		type: {
			type: String,
			default: "image"
		},
		classes: {
			type: String,
			default: "rounded-lg"
		}
	}
};
</script>

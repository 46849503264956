import { ApiAccountProfileBase } from "@/api/types/account/profile/apiAccountProfileBase";
import { ProfileType } from "@/types/ProfileType";

export default class ApiEntrepreneurEmployeeProfile extends ApiAccountProfileBase {
	inn: string;
	number: number;
	snils: string;
	externalId: string;
	employerInn: string;
	employerOgrn: string;

	constructor(
		type = ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE,
		number = 0,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		inn: string = "",
		snils: string = "",
		isActive: boolean = false,
		externalId = "",
		employerInn = "",
		employerOgrn = ""
	)
	{
		super(type, accountId, title, email, phone, isActive);

		this.inn = inn;
		this.number = number;
		this.snils = snils;
		this.externalId = externalId;
		this.employerInn = employerInn;
		this.employerOgrn = employerOgrn;
	}
}

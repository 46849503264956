import NotDefinedOrNullException from "@/exceptions/notDefinedOrNullException";
import { i18n } from "@/plugins";
import alertService from "@/store/modules/alerts/services/alertService";

const TOKEN = "access token";

export default class LoanLocalStorageService {
	constructor() {
		if(localStorage.getItem(TOKEN)) {
			this.token = localStorage.getItem(TOKEN)
		}
	}

	get token() {
		return localStorage.getItem(TOKEN);
	}

	set token(value) {
		localStorage.setItem(TOKEN, value);
	}
}

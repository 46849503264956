import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue } from "@automapper/core";
import { classes } from "@automapper/classes";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";

const mapper = createMapper({
	strategyInitializer: classes()
});

const storageProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiFileMeta, FileMeta,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.name, mapFrom(x => x.name)),
		forMember(d => d.mimeType, mapFrom(x => x.mimeType)),
		forMember(d => d.namespace, mapFrom(x => x.namespace)),
		forMember(d => d.signature, mapFrom(x => x.signature)),
		forMember(d => d.isLoading, fromValue(false))
	);
};

addProfile(mapper, storageProfile);

export default mapper;

<template>
	<div class="d-flex">
		<v-skeleton-loader height="14" width="120" type="image" class="mr-2"/>
		<v-skeleton-loader height="14" width="120" type="image" class="mr-2"/>
		<v-skeleton-loader height="14" width="120" type="image"/>
	</div>
</template>

<script>
export default {};
</script>

import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import "reflect-metadata";
import { Transform, TransformationType, Type } from "class-transformer";
import { isNumber } from "lodash";
import { CounterpartyType } from "@/types/CounterpartyType";

export default class ApiLegalEntityCounterparty extends ApiCounterpartyBase {
	inn: string;
	ogrn: string;
	kpp: string;
	opf: string;
	longName: string;
	shortName: string;
	legalAddress: string;
	@Transform(({ value }) => {
		return isNumber(value) ? new Date(value).toISOString() : value;
	})
	registrationDate: string;
	@Type(() => ApiLegalEntityCounterpartyHead)
	heads: ApiLegalEntityCounterpartyHead[];
	description: string;

	constructor(
		type: CounterpartyType = CounterpartyType.LEGAL_ENTITY,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false,
		inn: string = "",
		ogrn: string = "",
		kpp: string = "",
		opf: string = "",
		longName: string = "",
		shortName: string = "",
		legalAddress: string = "",
		registrationDate: string = "",
		heads: ApiLegalEntityCounterpartyHead[] = [],
		description: string = ""
	)
	{
		super(type, isRfrp, isLeasingCompany, isPledgeExpertCompany, isVkmExpertCompany, isFrpAssigneeCompany);

		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.opf = opf;
		this.longName = longName;
		this.shortName = shortName;
		this.legalAddress = legalAddress;
		this.registrationDate = registrationDate;
		this.heads = heads;
		this.description = description;
	}
}

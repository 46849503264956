import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "documents";

export const getterTypes = {
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchResponsibleUsers: "fetchResponsibleUsers",
	fetchDepartments: "fetchDepartments",
	fetchFinanceSchemes: "fetchFinanceSchemes",
	fetchSections: "fetchSections",
	copyLink: "copyLink"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_TEXT: "SET_FILTER_TEXT",
	SET_FILTER_FINANCE_SCHEMES_IDS: "SET_FILTER_FINANCE_SCHEMES_IDS",
	SET_FILTER_DEPARTMENT_IDS: "SET_FILTER_DEPARTMENT_IDS",
	SET_FILTER_RESPONSIBLE_USER_IDS: "SET_FILTER_RESPONSIBLE_USER_IDS",
	SET_FILTER_SECTION_IDS: "SET_FILTER_SECTION_IDS",
	SET_FILTER_INITIATOR_IDS: "SET_FILTER_INITIATOR_IDS",
	SET_FILTER_ACTIVE_ONLY: "SET_FILTER_ACTIVE_ONLY",
	SET_FILTER_IS_WEB: "SET_FILTER_IS_WEB",
	RESET_FILTER: "RESET_FILTER",
	SET_IS_USERS_LOADING: "SET_IS_USERS_LOADING",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_SECTIONS_LOADING: "SET_IS_SECTIONS_LOADING",
	SET_IS_FINANCE_SCHEMES_LOADING: "SET_IS_FINANCE_SCHEMES_LOADING",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_USERS: "SET_USERS",
	SET_SECTIONS: "SET_SECTIONS",
	SET_FINANCE_SCHEMES: "SET_FINANCE_SCHEMES"
};

const documentsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default documentsTypes;

import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyType } from "@/types/CounterpartyType";

export default class ApiLegalPersonCounterparty extends ApiCounterpartyBase {
	inn: string;
	ogrnIp: string;
	fullName: string;
	description: string;
	registrationDate: string;

	constructor(
		type: CounterpartyType = CounterpartyType.LEGAL_PERSON,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false,
		inn: string = "",
		ogrnIp: string = "",
		fullName: string = "",
		description: string = "",
		registrationDate: string = ""
	)
	{
		super(type, isRfrp, isLeasingCompany, isPledgeExpertCompany, isVkmExpertCompany, isFrpAssigneeCompany);

		this.inn = inn;
		this.ogrnIp = ogrnIp;
		this.fullName = fullName;
		this.description = description;
		this.registrationDate = registrationDate;
	}
}

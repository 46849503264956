import baseStepTypes from "@/store/shared/baseStep/types";
import baseMixinTypes from "@/store/shared/base/types";
import { formMutationTypes, formActionTypes } from "@/store/shared/form/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "select-counterparty-step";

export const getterTypes = {
	...baseStepTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseStepTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formActionTypes,
	fetchCounterparty: "fetchCounterparty",
	resetFetchedInfo: "resetFetchedInfo"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...baseStepTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	SET_INN: "SET_INN",
	SET_IS_COUNTERPARTY_LOADING: "SET_IS_COUNTERPARTY_LOADING",
	SET_COUNTERPARTY: "SET_COUNTERPARTY",
	SET_COUNTERPARTY_TYPE: "SET_COUNTERPARTY_TYPE",
	REMOVE_COUNTERPARTY: "REMOVE_COUNTERPARTY",
	SET_IS_COUNTERPARTY_ALREADY_EXISTS: "SET_IS_COUNTERPARTY_ALREADY_EXISTS",
	SET_IS_MASTER_ACCOUNT_ALREADY_EXISTS: "SET_IS_MASTER_ACCOUNT_ALREADY_EXISTS",
	CLEAR_ERRORS: "CLEAR_ERRORS",
	ADD_ERROR: "ADD_ERROR",
};

const selectCounterpartyStepTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default selectCounterpartyStepTypes;

import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiBarUser } from "@/api/bar/types/apiBarUser";
import { ApiBankAccountInfoPersisted } from "@/api/bar/types/bankAccountInfo/apiBankAccountInfoPersisted";
import { ApiGetBankAccountsParameters } from "@/api/bar/types/apiGetBankAccountsParameters";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiBankAccountItems } from "@/api/bar/types/apiBankAccountItems";
import { ApiBankAccountApplicationItems } from "@/api/bar/types/apiBankAccountApplicaionItems";
import { ApiGetBankAccountApplicationsParameters } from "@/api/bar/types/apiGetBankAccountApplicationsParameters";
import { ApiBankAccountApplicationInfoPersisted } from "@/api/bar/types/bankAccountApplicationInfo/apiBankAccountApplicationInfoPersisted";
import { ApiBankAccountApplicationMessagePersisted } from "@/api/bar/types/apiBankAccountApplicationMessagePersisted";
import { ApiBankAccountApplicationMessage } from "@/api/bar/types/apiBankAccountApplicationMessage";
import { ApiDocumentResponse } from "@/api/bar/types/apiDocumentResponse";
import { ApiApplication } from "@/api/bar/types/apiApplication";
import { ApiStatusHistoryItem } from "@/api/bar/types/apiStatusHistoryItem";
import { ApiBankAccountApplicationLegalDepartmentInfo } from "@/api/bar/types/apiBankAccountApplicationLegalDepartmentInfo";
import { ApiBankAccountApplicationAccount } from "@/api/bar/types/apiBankAccountApplicationAccount";
import {
	ApiChangeBankAccountApplicationInfoRequest
} from "@/api/bar/types/bankAccountApplicationInfo/apiChangeBankAccountApplicationInfoRequest";
import { ApiChangeBankAccountInfoRequest } from "@/api/bar/types/bankAccountInfo/apiChangeBankAccountInfoRequest";
import { ApiBankAccountTypeHistory } from "@/api/bar/types/apiBankAccountTypeHistory";
import ApiUpdateDocumentStatusRequest from "@/api/bar/types/apiUpdateDocumentStatusRequest";
import ApiUpdateDocumentCommentRequest from "@/api/bar/types/apiUpdateDocumentCommentRequest";
import { ApiAccountUsageItem } from "@/api/bar/types/apiAccountUsageItem";
import { ApiSignerEnum } from "@/api/bar/types/ApiSignerEnum";
import { ApiDownloadBankApplicationTemplateParameters } from "@/api/bar/types/apiDownloadBankApplicationTemplateParameters";
import { ApiGetFileResponse } from "@/api/types/storage/apiGetFileResponse";

export class BarController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getPermissions = async (): Promise<string[]> => {
		return await this.client.get<string[]>(urls.bar.permissions.get);
	};
	
	getCurrentUser = async (): Promise<ApiBarUser> => {
		return await this.client.get<ApiBarUser>(urls.bar.user.getCurrentUser);
	};
	
	getBankAccountApplicationsItems = async (parameters: ApiGetBankAccountApplicationsParameters): Promise<ApiBankAccountApplicationItems> => {
		const url = prepareUrl(urls.bar.bankAccountApplications.getBankAccountApplications, parameters);
		return await this.client.get<ApiBankAccountApplicationItems>(url);
	};
	getBankAccountApplicationStatusHistoryItems = async (bankAccountApplicationId: string): Promise<ApiStatusHistoryItem[]> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplicationStatusHistoryItems.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiStatusHistoryItem[]>(url);
	};

	createBankAccountApplication = async (bankAccountApplicationInfo: ApiChangeBankAccountApplicationInfoRequest): Promise<ApiBankAccountApplicationInfoPersisted> => {
		return await this.client.post<ApiBankAccountApplicationInfoPersisted>(urls.bar.bankAccountApplications.createBankAccountApplication,
			bankAccountApplicationInfo);
	};
	
	getAccountUsage = async (bankAccountApplicationInfo: ApiChangeBankAccountApplicationInfoRequest): Promise<ApiAccountUsageItem[]> => {
		return await this.client.post<ApiAccountUsageItem[]>(urls.bar.bankAccountApplications.getAccountUsage,
			bankAccountApplicationInfo);
	};
	
	getAccountUsagesById = async (bankAccountApplicationId: string): Promise<ApiAccountUsageItem[]> => {
		return await this.client.get<ApiAccountUsageItem[]>(
			urls.bar.bankAccountApplications.getAccountUsagesById.replace(urlTemplateParts.id, bankAccountApplicationId));
	};
	
	updateBankAccountApplication = async (bankAccountApplicationId: string,
		bankAccountApplicationInfo: ApiChangeBankAccountApplicationInfoRequest): Promise<void> => {
		const url = urls.bar.bankAccountApplications.updateBankAccountApplication.replace(urlTemplateParts.id, bankAccountApplicationId);
		await this.client.put(url, bankAccountApplicationInfo);
	};
	
	generateInformationalLetter = async (bankAccountApplicationId: string): Promise<Blob> => {
		const url = urls.bar.bankAccountApplications.generateInformationalLetter.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<Blob>(url, { responseType: "blob" }, true);
	};
	
	getInformationalLetterDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.getFileInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setInformationalLetterDocumentFile = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.setFile.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	updateInformationalLetterDocumentStatus = async (bankAccountApplicationId: string,
		parameters: ApiUpdateDocumentStatusRequest): Promise<void> => {
		const url = prepareUrl(urls.bar.bankAccountApplications.documents.informationalLetter.updateStatus.replace(urlTemplateParts.id,
			bankAccountApplicationId), parameters);
		return await this.client.put(url, {});
	};
	
	updateInformationalLetterDocumentComment = async (bankAccountApplicationId: string,
		payload: ApiUpdateDocumentCommentRequest): Promise<void> => {
		const url = urls.bar.bankAccountApplications.documents.informationalLetter.updateComment.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.put(url, payload);
	};
	
	getDirectWithdrawalAgreementDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.getFileInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setDirectWithdrawalAgreementDocumentFile = async (bankAccountApplicationId: string,
		tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.setFile.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	updateDirectWithdrawalAgreementDocumentStatus = async (bankAccountApplicationId: string,
		parameters: ApiUpdateDocumentStatusRequest): Promise<void> => {
		const url = prepareUrl(
			urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.updateStatus.replace(urlTemplateParts.id,
				bankAccountApplicationId), parameters);
		return await this.client.put(url, {});
	};
	
	updateDirectWithdrawalAgreementDocumentComment = async (bankAccountApplicationId: string,
		payload: ApiUpdateDocumentCommentRequest): Promise<void> => {
		const url = prepareUrl(
			urls.bar.bankAccountApplications.documents.directWithdrawalAgreement.updateComment.replace(urlTemplateParts.id,
				bankAccountApplicationId));
		return await this.client.put(url, payload);
	};
	
	getPaymentAcceptAgreementDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.getFileInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setPaymentAcceptAgreementDocumentFile = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.setFile.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	updatePaymentAcceptAgreementDocumentStatus = async (bankAccountApplicationId: string,
		parameters: ApiUpdateDocumentStatusRequest): Promise<void> => {
		const url = prepareUrl(urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.updateStatus.replace(urlTemplateParts.id,
			bankAccountApplicationId), parameters);
		return await this.client.put(url, {});
	};
	
	updatePaymentAcceptAgreementDocumentComment = async (bankAccountApplicationId: string,
		payload: ApiUpdateDocumentCommentRequest): Promise<void> => {
		const url = prepareUrl(urls.bar.bankAccountApplications.documents.paymentAcceptAgreement.updateComment.replace(urlTemplateParts.id,
			bankAccountApplicationId));
		return await this.client.put(url, payload);
	};
	
	getBankAccountApplicationLegalDepartmentInfo = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.getLegalDepartmentInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationLegalDepartmentInfo>(url);
	};
	
	getBankAccountApplicationLegalDepartmentRequestDocuments = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse[]> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.getDocuments.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse[]>(url, {});
	};
	
	sendBankAccountApplicationLegalDepartmentRequest = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.sendLegalDepartmentRequest.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post(url, {});
	};
	
	setBankAccountApplicationLegalDepartmentResponsiblePerson = async (bankAccountApplicationId: string,
		legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<void> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.setResponsiblePerson.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	acceptBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string,
		legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.accept.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	declineBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string,
		legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.decline.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	resetStatusesForBankAccountApplicationLegalDepartment = async (bankAccountApplicationId: string,
		legalDepartmentInfo: ApiBankAccountApplicationLegalDepartmentInfo): Promise<ApiBankAccountApplicationLegalDepartmentInfo> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.resetStatuses.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.post(url, legalDepartmentInfo);
	};
	
	uploadLegalDepartmentDocumentFileInfo = async (bankAccountApplicationId: string, tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.legalDepartment.sendDocumentFileInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post(url, { tempFileId });
	};
	
	getMessages = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationMessagePersisted[]> => {
		const url = urls.bar.bankAccountApplications.messenger.getMessages.replace(urlTemplateParts.id, bankAccountApplicationId);
		
		return await this.client.get<ApiBankAccountApplicationMessagePersisted[]>(url);
	};
	
	sendMessage = async (bankAccountApplicationId: string,
		message: ApiBankAccountApplicationMessage): Promise<ApiBankAccountApplicationMessagePersisted> => {
		const url = urls.bar.bankAccountApplications.messenger.sendMessage.replace(urlTemplateParts.id, bankAccountApplicationId);
		
		return await this.client.post<ApiBankAccountApplicationMessagePersisted>(url, message);
	};
	
	deleteMessage = async (bankAccountApplicationId: string, messageId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.messenger.deleteMessage.replace(urlTemplateParts.id, bankAccountApplicationId).replace(
			urlTemplateParts.subId,
			messageId);
		
		await this.client.delete(url);
	};
	
	deleteBankAccountApplicationDocument = async (bankAccountApplicationId: string, documentId: string): Promise<void> => {
		const url = urls.bar.bankAccountApplications.deleteBankAccountApplicationDocument.replace(urlTemplateParts.id,
			bankAccountApplicationId).replace(urlTemplateParts.subId, documentId);
		return await this.client.delete(url);
	};
	
	getBankAccountItems = async (parameters: ApiGetBankAccountsParameters): Promise<ApiBankAccountItems> => {
		return await this.client.get<ApiBankAccountItems>(prepareUrl(urls.bar.bankAccounts.getBankAccounts, parameters));
	};
	
	getBankAccountApplication = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationInfoPersisted> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplication.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationInfoPersisted>(url);
	};
	
	getBankAccountApplicationAccounts = async (bankAccountApplicationId: string): Promise<ApiBankAccountApplicationAccount[]> => {
		const url = urls.bar.bankAccountApplications.getBankAccountApplicationAccounts.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiBankAccountApplicationAccount[]>(url);
	};
	
	getBankAccount = async (bankAccountId: string): Promise<ApiBankAccountInfoPersisted> => {
		const url = urls.bar.bankAccounts.getBankAccount.replace(urlTemplateParts.id, bankAccountId);
		return await this.client.get<ApiBankAccountInfoPersisted>(url);
	};
	
	getApplicationsForBankAccount = async (accountNumber: string): Promise<ApiApplication[]> => {
		const url = urls.bar.bankAccounts.getBankAccountApplications.replace(urlTemplateParts.id, accountNumber);
		return await this.client.get<ApiApplication[]>(url);
	};
	
	getBankAccountAgreementTypeHistory = async (id: string, loanApplicationNumber: string): Promise<ApiBankAccountTypeHistory[]> => {
		const url = urls.bar.bankAccounts.getBankAccountAgreementTypeHistory.replace(urlTemplateParts.id, id)
						.replace(urlTemplateParts.subId, loanApplicationNumber);
		return await this.client.get<ApiBankAccountTypeHistory[]>(url);
	};
	
	createBankAccount = async (bankAccountInfo: ApiChangeBankAccountInfoRequest): Promise<ApiBankAccountInfoPersisted | any> => {
		return await this.client.post<ApiBankAccountInfoPersisted>(urls.bar.bankAccounts.createBankAccount, bankAccountInfo);
	};
	
	updateBankAccount = async (bankAccountId: string,
		bankAccountInfo: ApiChangeBankAccountInfoRequest): Promise<ApiBankAccountInfoPersisted> => {
		const url = urls.bar.bankAccounts.updateBankAccount.replace(urlTemplateParts.id, bankAccountId);
		return await this.client.put<ApiBankAccountInfoPersisted>(url, bankAccountInfo);
	};
	
	checkBankAccount = async (parameters: { accountNumber: string, loanApplicationNumber?: string }): Promise<boolean> => {
		const url = prepareUrl(urls.bar.bankAccounts.getBankAccounts, parameters);
		const { items } = await this.client.get<ApiBankAccountItems>(url, {});
		
		return Boolean(items.length);
	};
	
	checkBankAccountPayments = async (accountNumber: string): Promise<{ hasPayments: boolean }> => {
		const url = urls.bar.bankAccounts.checkBankAccountPayments.replace(urlTemplateParts.id, accountNumber);
		return await this.client.get<{ hasPayments: boolean }>(url, {});
	};
	
	updateStatusToReturnToDSP = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.status.returnToDSP.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	updateStatusToReturnToUFKP = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.status.returnToUFKP.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	updateStatusToConnect = async (bankAccountApplicationId: string): Promise<void> => {
		const url = urls.bar.status.toConnect.replace(urlTemplateParts.id, bankAccountApplicationId);
		return await this.client.put(url, {});
	};
	
	getAccountCorrectionAgreementDocumentFileInfo = async (bankAccountApplicationId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.accountCorrectionAgreement.getFileInfo.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.get<ApiDocumentResponse>(url);
	};
	
	setAccountCorrectionAgreementDocumentFile = async (bankAccountApplicationId: string,
		tempFileId: string): Promise<ApiDocumentResponse> => {
		const url = urls.bar.bankAccountApplications.documents.accountCorrectionAgreement.setFile.replace(urlTemplateParts.id,
			bankAccountApplicationId);
		return await this.client.post<ApiDocumentResponse>(url, { tempFileId });
	};
	
	updateAccountCorrectionAgreementDocumentStatus = async (bankAccountApplicationId: string,
		parameters: ApiUpdateDocumentStatusRequest): Promise<void> => {
		const url = prepareUrl(
			urls.bar.bankAccountApplications.documents.accountCorrectionAgreement.updateStatus.replace(urlTemplateParts.id,
				bankAccountApplicationId), parameters);
		return await this.client.put(url, {});
	};
	
	updateAccountCorrectionAgreementDocumentComment = async (bankAccountApplicationId: string,
		payload: ApiUpdateDocumentCommentRequest): Promise<void> => {
		const url = prepareUrl(
			urls.bar.bankAccountApplications.documents.accountCorrectionAgreement.updateComment.replace(urlTemplateParts.id,
				bankAccountApplicationId));
		return await this.client.put(url, payload);
	};
	
	downloadBankApplicationTemplate = async (bankAccountApplicationId: string,
		parameters: ApiDownloadBankApplicationTemplateParameters) => {
		const url = prepareUrl(urls.bar.bankAccountApplications.downloadBankApplicationTemplate.replace(urlTemplateParts.id,
			bankAccountApplicationId), parameters);
		
		return await this.client.get<ApiGetFileResponse>(
			url, { responseType: "blob" }, true);
	};
}

import { isArray, isObject } from "lodash";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { urlTemplateParts } from "@/api/config/urls";

export const processApiPayload = (payload: any) => {
	for (let property in payload) {
		if(payload.hasOwnProperty(property)) {
			let value = payload[property];
			if(value === "") {
				delete payload[property];
			} else if(isObject(value)) {
				processApiPayload(value);
			} else if(isArray(value)) {
				value.forEach((el) => {
					if(isObject(el)) {
						processApiPayload(el);
					}
				});
			}
		}
	}
};

export const filterApiPayload = (payload: any, values: any[]) => {
	for (let property in payload) {
		if(payload.hasOwnProperty(property)) {
			let value = payload[property];
			if(values.includes(value)) {
				delete payload[property];
			} else if(isObject(value)) {
				processApiPayload(value);
			} else if(isArray(value)) {
				value.forEach((el) => {
					if(isObject(el)) {
						processApiPayload(el);
					}
				});
			}
		}
	}
};

export const getFileUrl = (path: string, id: string) => {
	return prepareUrl(path.replace(urlTemplateParts.id, id));
};


<template>
	<router-view :key="$route.meta.key || $route.name"/>
</template>

<script>
export default {}
</script>

<style scoped>

</style>

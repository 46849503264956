import userManager, { oidcClient } from "@/services/auth/userManager";
import { signInConfig } from "@/services/auth/signInConfig";
import User from "@/services/auth/types/user";
import { plainToInstance } from "class-transformer";
import jwtDecode from "jwt-decode";
import AbortService from "@/services/abortService";
import { AuthController } from "@/api/auth";

const abortService = new AbortService();
abortService.initialize();
const authController = new AuthController(abortService);

export default class SecurityService {
	constructor() {
	}

	async getUser(): Promise<User> {
		try {
			let user = await userManager.getUser();

			if(user == null) {
				await this.signinSilent();

				user = await userManager.getUser();

				if(!user)
					throw new Error();
			}

			return plainToInstance(User, {
				accessToken: user.access_token,
				profile: user.profile.profile,
				account: user.profile.account
			});
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	async selectProfile(profileId: string, payload: any) {
		try {
			let user = await userManager.getUser();

			let authorizationEndpoint = await userManager.metadataService.getAuthorizationEndpoint();

			let request = await oidcClient.createSigninRequest({
				...user,
				id_token_hint: user?.id_token
			});

			let query = request.url.replace(authorizationEndpoint, "");

			await authController.signInWithProfile({
				profileId,
				signedPayload: payload
			}, query);

			// TODO Посмотреть подробнее
			await userManager.removeUser();
			await userManager.clearStaleState();

			await userManager.signinSilent();
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	async signinSilent() {
		return await userManager.signinSilent();
	}

	async signIn(params = {}, state = {})
	{
		try {
			await userManager.signinRedirect({
				extraQueryParams: {
					...signInConfig
				},
				state
			});
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	async signOut() {
		try {
			let resp = await userManager.signoutRedirect();
			console.log("signed out", resp);
		} catch (e) {
			console.error(e);
		}
	}

	async getToken() {
		try {
			let user = await userManager.getUser();

			return user ? user.access_token : null;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
}

export default class SnapshotOptions {
	key: string;
	/** Поля, которые будут сохранены в слепке */
	fields: string[];

	constructor({ key, fields }: { key: string, fields: string[] }) {
		this.key = key;
		this.fields = fields;
	}
}

import PermissionsResolver from "@/api/authorization/permissionsResolver";
import { Permissions } from "@/constants/permissions";

const permissionsResolver = new PermissionsResolver();

export default class PermissionsService {
	constructor() {
	}

	async check(requiredPermissions: Permissions[], any = true): Promise<boolean> {
		if(!requiredPermissions.length)
			return true;
		
		const userPermissions = await permissionsResolver.resolve();

		if(any) {
			return requiredPermissions.some(x => userPermissions.includes(x));
		} else {
			return requiredPermissions.every(x => userPermissions.includes(x));
		}
	}
}

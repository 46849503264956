import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "document";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	users: "users",
	isCreateUnpublishRequestPageWithWeb: "isCreateUnpublishRequestPageWithWeb"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	fetch: "fetch",
	fetchHistory: "fetchHistory",
	fetchUsers: "fetchUsers",
	fetchDepartments: "fetchDepartments",
	fetchSections: "fetchSections",
	fetchFinanceSchemes: "fetchFinanceSchemes",
	copyLink: "copyLink",
	acceptRequest: "acceptRequest",
	rejectRequest: "rejectRequest",
	createNewDocumentRequest: "createNewDocumentRequest",
	updateDocument: "updateDocument",
	createDocumentUnpublishRequest: "createDocumentUnpublishRequest",
	createRollbackDocumentRequest: "createRollbackDocumentRequest",
	createDocumentUpdateRequest: "createDocumentUpdateRequest",
	updateRequest: "updateRequest",
	handleAddDocumentFile: "handleAddDocumentFile",
	handleAddRequestFile: "handleAddRequestFile",
	handleAddChangeWarrantFile: "handleAddChangeWarrantFile",
	handleAddDocumentChangeWarrantFile: "handleAddDocumentChangeWarrantFile"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	SET_DOCUMENT_NAME: "SET_DOCUMENT_NAME",
	SET_DOCUMENT_SECTION: "SET_DOCUMENT_SECTION",
	SET_DOCUMENT_FINANCE_SCHEMES: "SET_DOCUMENT_FINANCE_SCHEMES",
	SET_DOCUMENT_RESPONSIBLE_DEPARTMENT: "SET_DOCUMENT_RESPONSIBLE_DEPARTMENT",
	SET_DOCUMENT_RESPONSIBLE_USER: "SET_DOCUMENT_RESPONSIBLE_USER",
	SET_DOCUMENT_EDITABLE_FILE: "SET_DOCUMENT_EDITABLE_FILE",
	SET_DOCUMENT_NON_EDITABLE_FILE: "SET_DOCUMENT_NON_EDITABLE_FILE",
	SET_DOCUMENT_LINKED_TO_FINANCE_SCHEMES: "SET_DOCUMENT_LINKED_TO_FINANCE_SCHEMES",
	SET_DOCUMENT_IS_WEB: "SET_DOCUMENT_IS_WEB",
	SET_DOCUMENT_WEB_LINK: "SET_DOCUMENT_WEB_LINK",
	SET_DOCUMENT_CHANGE_WARRANT: "SET_DOCUMENT_CHANGE_WARRANT",
	SET_DOCUMENT_CHANGE_WARRANT_FILE_META: "SET_DOCUMENT_CHANGE_WARRANT_FILE_META",
	SET_DOCUMENT_CHANGE_WARRANT_FILE_IS_LOADING: "SET_DOCUMENT_CHANGE_WARRANT_FILE_IS_LOADING",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_SECTIONS: "SET_SECTIONS",
	SET_FINANCE_SCHEMES: "SET_FINANCE_SCHEMES",
	SET_USERS: "SET_USERS",
	SET_DOCUMENT: "SET_DOCUMENT",
	SET_CHANGE_WARRANTS: "SET_CHANGE_WARRANTS",
	SET_IS_USERS_LOADING: "SET_IS_USERS_LOADING",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_SECTIONS_LOADING: "SET_IS_SECTIONS_LOADING",
	SET_IS_FINANCE_SCHEMES_LOADING: "SET_IS_FINANCE_SCHEMES_LOADING",
	SET_REQUEST: "SET_REQUEST",
	SET_REQUEST_CHANGE_WARRANT: "SET_REQUEST_CHANGE_WARRANT",
	SET_REQUEST_RESPONSIBLE_USER: "SET_REQUEST_RESPONSIBLE_USER",
	SET_REQUEST_INITIATOR: "SET_REQUEST_INITIATOR",
	SET_REQUEST_DESCRIPTION: "SET_REQUEST_DESCRIPTION",
	SET_REQUEST_EDITABLE_FILE: "SET_REQUEST_EDITABLE_FILE",
	SET_REQUEST_NON_EDITABLE_FILE: "SET_REQUEST_NON_EDITABLE_FILE",
	SET_CHANGE_WARRANT_FILE: "SET_CHANGE_WARRANT_FILE",
	SET_CHANGE_WARRANT_FILE_META: "SET_CHANGE_WARRANT_FILE_META",
	SET_CHANGE_WARRANT_FILE_IS_LOADING: "SET_CHANGE_WARRANT_FILE_IS_LOADING",
	SET_REQUEST_STATUS: "SET_REQUEST_STATUS",
	SET_REQUEST_WEB_LINK: "SET_REQUEST_WEB_LINK",
	SET_IS_ACCEPTING: "SET_IS_ACCEPTING",
	SET_IS_REJECTING: "SET_IS_REJECTING",
	SET_IS_ACCEPT_BUTTONS_HIDDEN: "SET_IS_ACCEPT_BUTTONS_HIDDEN",
	SET_IS_DOCUMENT_FORM_SAVING: "SET_IS_DOCUMENT_FORM_SAVING",
	SET_IS_REQUEST_FORM_SAVING: "SET_IS_REQUEST_FORM_SAVING",
	SET_DOCUMENT_PERMISSIONS: "SET_DOCUMENT_PERMISSIONS",
	SET_REQUEST_PERMISSIONS: "SET_REQUEST_PERMISSIONS",
	SET_IS_FINANCE_SCHEMES_LOADED: "SET_IS_FINANCE_SCHEMES_LOADED",
	SET_DECLINE_REASON: "SET_DECLINE_REASON",
	SET_SAVED_DOCUMENT_IS_WEB: "SET_SAVED_DOCUMENT_IS_WEB"
};

const documentTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default documentTypes;

<template>
	<v-row class="d-flex justify-space-between"
		   :class="{'flex-column': $vuetify.breakpoint.mdAndDown}">
		<v-col class="pt-0 px-8 d-flex align-end"
			   :class="$vuetify.breakpoint.mdAndDown ? 'pb-5' : 'pb-10'">
			<span style="white-space: nowrap"
				  class="text-body-2 font-weight-bold blue--text text--darken-4">
				{{ $t("titles.trancheSchedulePlan") }}
			</span>
		</v-col>
		<v-col class=" pt-0 px-8 pb-6">
			<v-simple-table class="tranches-tranche-schedule-accept-table">
				<template #default>
					<template v-if="$vuetify.breakpoint.mdAndDown">
						<tr>
							<td class="d-flex justify-space-between mt-5">
								<span class="text-body-2 primary--text font-weight-bold">{{ $t("tables.projectManager") }}</span>
								
								<div v-if="isProjectManagerRole" class="d-flex align-center flex-column justify-end">
									<frp-btn :outlined="!isAcceptedTrancheScheduleStatus"
											 :disabled="isAcceptedTrancheScheduleStatus || isDraftTrancheScheduleStatus"
											 dense
											 :class="{'tranches-tranche-schedule-accepted-accept-button': isAcceptedTrancheScheduleStatus}"
											 :color="colors.success"
											 elevation="0"
											 class="mb-2"
											 @click="handleActionInTrancheSchedule(TrancheScheduleAcceptOrRejectDialogMode.ACCEPT)">
										{{ $t("buttons.accepted") }}
									</frp-btn>
									<frp-btn :outlined="!isDraftTrancheScheduleStatus"
											 dense
											 :class="{'tranches-tranche-schedule-rejected-reject-button': isDraftTrancheScheduleStatus}"
											 :disabled="isAcceptedTrancheScheduleStatus || isDraftTrancheScheduleStatus"
											 :color="colors.error"
											 elevation="0"
											 class="px-6"
											 style="margin-left: 0 !important;"
											 @click="handleActionInTrancheSchedule(TrancheScheduleAcceptOrRejectDialogMode.REJECT)">
										{{ $t("buttons.rejected") }}
									</frp-btn>
								</div>
								<span v-else class="tranches-center-table-field">
									{{ $t(`aliases.trancheSchedulePlanStatus.${trancheSchedule.status}`) }}
								</span>
							</td>
							<td class="d-flex justify-space-between mt-5">
								<span class="text-body-2 primary--text font-weight-bold">{{ $t("tables.projectManager") }}</span>
								
								<span class="tranches-center-table-field">
									{{ formatDate(trancheSchedule.acceptDate, dateFormat) || "" }}
								</span>
							</td>
						</tr>
					</template>
					
					<template v-else>
						<thead>
						<tr>
							<th class="pr-10 text-body-2 primary--text font-weight-bold text-center">{{ $t("tables.projectManager") }}</th>
							<th class="text-body-2 primary--text font-weight-bold text-center">{{ $t("tables.date") }}</th>
						</tr>
						</thead>
						<tbody>
						<tr class="text-body-2 primary--text">
							<td class="pr-10">
								<div v-if="isProjectManagerRole" class="d-flex align-center justify-center">
									<frp-btn :outlined="!isAcceptedTrancheScheduleStatus"
											 :class="{'tranches-tranche-schedule-accepted-accept-button': isAcceptedTrancheScheduleStatus}"
											 :disabled="isAcceptedTrancheScheduleStatus || isDraftTrancheScheduleStatus"
											 dark
											 dense
											 elevation="0"
											 :color="colors.green.base"
											 @click="handleActionInTrancheSchedule(TrancheScheduleAcceptOrRejectDialogMode.ACCEPT)">
										{{ $t("buttons.accepted") }}
									</frp-btn>
									<frp-btn :outlined="!isDraftTrancheScheduleStatus"
											 dense
											 :class="{'tranches-tranche-schedule-rejected-reject-button': isDraftTrancheScheduleStatus}"
											 :disabled="isAcceptedTrancheScheduleStatus || isDraftTrancheScheduleStatus"
											 :color="colors.error"
											 elevation="0"
											 class="px-6"
											 @click="handleActionInTrancheSchedule(TrancheScheduleAcceptOrRejectDialogMode.REJECT)">
										{{ $t("buttons.rejected") }}
									</frp-btn>
								</div>
								<span v-else class="tranches-center-table-field">
									{{ $t(`aliases.trancheSchedulePlanStatus.${trancheSchedule.status}`) }}
								</span>
							</td>
							<td>
								<span class="tranches-center-table-field">
									{{ formatDate(trancheSchedule.acceptDate, dateFormat) || "" }}
								</span>
							</td>
						</tr>
						</tbody>
					</template>
				</template>
			</v-simple-table>
		</v-col>
		
		<loan-confirm-dialog v-if="isAcceptOrRejectTrancheScheduleDialogEnabled"
							 :loading="isTrancheScheduleAccepting || isTrancheScheduleRejecting"
							 :submit-btn="actionTrancheScheduleAcceptTableSubmitBtnText"
							 :cancel-btn="$t('buttons.cancellation')"
							 @submit="handleSubmitActionInTrancheSchedule"
							 @cancel="isAcceptOrRejectTrancheScheduleDialogEnabled = false"
							 :title="actionTrancheScheduleAcceptTableTitleText">
		</loan-confirm-dialog>
	</v-row>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import LoanConfirmDialog from "@/components/dialogs/LoanConfirmDialog.vue";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import { TrancheScheduleStatusTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleStatusTypeEnum";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { listMixin } from "Mixins/listMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { namespace, getterTypes, actionTypes } from "@/store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const TrancheScheduleAcceptOrRejectDialogMode = {
	ACCEPT: "ACCEPT",
	REJECT: "REJECT"
};

export default {
	mixins: [colorsMixin, storeModuleBasedPage, listMixin, authorizationMixin],
	data() {
		return {
			namespace,
			TrancheScheduleModeTypeEnum,
			TrancheScheduleStatusTypeEnum,
			TrancheScheduleAcceptOrRejectDialogMode,
			formatDate,
			dateFormat,
			isAcceptOrRejectTrancheScheduleDialogEnabled: false,
			actionTrancheScheduleAcceptTable: null
		};
	},
	computed: {
		...mapGetters({
						  isAcceptedTrancheScheduleStatus: getterTypes.isAcceptedTrancheScheduleStatus,
						  isDraftTrancheScheduleStatus: getterTypes.isDraftTrancheScheduleStatus
					  }),
		...mapState({
						isInitialized: state => state.isInitialized,
						mode: state => state.mode,
						isTrancheScheduleFormValid: state => state.isTrancheScheduleFormValid,
						editableItems: state => state.editableItems,
						trancheSchedule: state => state.trancheSchedule,
						isTrancheScheduleSaving: state => state.isTrancheScheduleSaving,
						isTrancheScheduleForApprovalByManagerSending: state => state.isTrancheScheduleForApprovalByManagerSending,
						isTrancheScheduleAccepting: state => state.isTrancheScheduleAccepting,
						isTrancheScheduleRejecting: state => state.isTrancheScheduleRejecting
					}),
		isProjectManagerRole() {
			return this.can([this.Permissions.TRANCHES_TRANCHE_REJECT, this.Permissions.TRANCHES_TRANCHE_ACCEPT]);
		},
		status() {
			return this.trancheSchedule.status;
		},
		actionTrancheScheduleAcceptTableSubmitBtnText() {
			switch (this.actionTrancheScheduleAcceptTable) {
				case TrancheScheduleAcceptOrRejectDialogMode.ACCEPT:
					return this.$t("buttons.setAccept");
				case TrancheScheduleAcceptOrRejectDialogMode.REJECT:
					return this.$t("buttons.setReject");
				default:
					return "";
			}
		},
		actionTrancheScheduleAcceptTableTitleText() {
			switch (this.actionTrancheScheduleAcceptTable) {
				case TrancheScheduleAcceptOrRejectDialogMode.ACCEPT:
					return this.$t("dialogs.acceptTrancheSchedule.title");
				case TrancheScheduleAcceptOrRejectDialogMode.REJECT:
					return this.$t("dialogs.rejectTrancheSchedule.title");
				default:
					return "";
			}
		}
	},
	methods: {
		...mapActions({
						  acceptTrancheSchedule: actionTypes.acceptTranche,
						  rejectTrancheSchedule: actionTypes.rejectTranche
					  }),
		async handleActionInTrancheSchedule(action) {
			this.actionTrancheScheduleAcceptTable = action;
			
			this.isAcceptOrRejectTrancheScheduleDialogEnabled = true;
		},
		async handleSubmitActionInTrancheSchedule() {
			switch (this.actionTrancheScheduleAcceptTable) {
				case TrancheScheduleAcceptOrRejectDialogMode.ACCEPT:
					await this.acceptTrancheSchedule();
					break;
				case TrancheScheduleAcceptOrRejectDialogMode.REJECT:
					await this.rejectTrancheSchedule();
					break;
				default:
					this.isAcceptOrRejectTrancheScheduleDialogEnabled = false;
					break;
			}
			
			this.isAcceptOrRejectTrancheScheduleDialogEnabled = false;
			this.actionTrancheScheduleAcceptTable = null;
		}
	},
	components: {
		LoanConfirmDialog,
		FrpBtn
	}
};
</script>
<style lang="scss">
.tranches-tranche-schedule-accept-table {
  th {
	border-bottom: none !important;
  }

  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	background: transparent !important;
  }
}

.tranches-tranche-schedule-accepted-accept-button {
  &.frp-btn {
	&.v-btn:not(.v-btn--icon) {
	  &:not(.v-btn--outlined):not(.v-btn--text) {
		&:disabled {
		  border: 1px solid var(--v-green-base);
		  background-color: var(--v-green-base) !important;
		  color: var(--v-white-base) !important;
		}
	  }
	}
  }
}

.tranches-tranche-schedule-rejected-reject-button {
  &.frp-btn {
	&.v-btn:not(.v-btn--icon) {
	  &:not(.v-btn--outlined):not(.v-btn--text) {
		&:disabled {
		  border: 1px solid var(--v-error-base);
		  background-color: var(--v-error-base) !important;
		  color: var(--v-white-base) !important;
		}
	  }
	}
  }
}
</style>
<template>
	<div class="d-flex flex-column justify-center" :class="[dense ? '' : 'mb-6']">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		dense: {
			default: false,
			type: Boolean
		}
	}
};
</script>

<style scoped>

</style>

import { TrancheScheduleHistoryItem, TrancheScheduleHistoryItemMapper } from "@/types/tranches/trancheScheduleHistoryItem";
import { TrancheHistoryItem, TrancheHistoryItemMapper } from "@/types/tranches/trancheHistoryItem";
import { ApiTrancheScheduleHistory } from "@/api/tranches/types/apiTrancheScheduleHistory";

export interface TrancheScheduleHistory {
	trancheScheduleHistory: TrancheScheduleHistoryItem[];
	trancheHistory: TrancheHistoryItem[];
}

export class TrancheScheduleHistoryMapper {
	static map(source: ApiTrancheScheduleHistory): TrancheScheduleHistory {
		return {
			trancheScheduleHistory: source.trancheScheduleHistory.map(x => TrancheScheduleHistoryItemMapper.map(x)),
			trancheHistory: source.trancheHistory.map(x => TrancheHistoryItemMapper.map(x))
		}
	}
}

import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingGetterTypes, listingActionTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "vacation-plans";

export const getterTypes = {
	...listingGetterTypes,
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	allowedNewVacationDaysCount: "allowedNewVacationDaysCount",
	yearValues: "yearValues",
	currentUser: "currentUser",
	currentUserPermissions: "currentUserPermissions",
	canAcceptOwnPlan: "canAcceptOwnPlan",
	holidays: "holidays",
	editablePlan: "editablePlan",
	currentFilterEmployee: "currentFilterEmployee",
	vacationsDaysCount: "vacationsDaysCount",
	hasLongVacation: "hasLongVacation",
	isVacationDaysEnough: "isVacationDaysEnough",
	editableVacationSiblingVacations: "editableVacationSiblingVacations",
	editableVacationParentVacation: "editableVacationParentVacation",
	currentUserVersions: "currentUserVersions",
	isAllVacationsHaveApplications: "isAllVacationsHaveApplications"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	setReadMode: "setReadMode",
	updateDefaultRoute: "updateDefaultRoute",
	fetchUserVersions: "fetchUserVersions",
	fetchYears: "fetchYears",
	fetchDepartments: "fetchDepartments",
	fetchEmployees: "fetchEmployees",
	editDraft: "editDraft",
	createNewDraft: "createNewDraft",
	createFirstDraft: "createFirstDraft",
	deleteDraft: "deleteDraft",
	sendToReview: "sendToReview",
	saveDraft: "saveDraft",
	saveAndApproveDraft: "saveAndApproveDraft",
	approvePlans: "approvePlans",
	approvePlan: "approvePlan",
	declinePlan: "declinePlan",
	withdrawPlan: "withdrawPlan",
	exportPlans: "exportPlans"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_SAVING_AND_APPROVING: "SET_IS_SAVING_AND_APPROVING",
	SET_CURRENT_USER_PLANS: "SET_CURRENT_USER_PLANS",
	SET_MODE: "SET_MODE",
	REMOVE_CURRENT_PLAN: "REMOVE_CURRENT_PLAN",
	SET_CURRENT_PLAN_STATE: "SET_CURRENT_PLAN_STATE",
	SET_FILTER_YEAR: "SET_FILTER_YEAR",
	SET_FILTER_MONTH: "SET_FILTER_MONTH",
	SET_FILTER_DEPARTMENT_IDS: "SET_FILTER_DEPARTMENT_IDS",
	SET_FILTER_EMPLOYEE_IDS: "SET_FILTER_EMPLOYEE_IDS",
	SET_FILTER_VERSION_ID: "SET_FILTER_VERSION_ID",
	SET_FILTER_VIEW: "SET_FILTER_VIEW",
	SET_FILTER_IS_ALL_EMPLOYEES: "SET_FILTER_IS_ALL_EMPLOYEES",
	SET_FILTER: "SET_FILTER",
	SET_IS_YEARS_LOADING: "SET_IS_YEARS_LOADING",
	SET_IS_DEPARTMENTS_LOADING: "SET_IS_DEPARTMENTS_LOADING",
	SET_IS_EMPLOYEES_LOADING: "SET_IS_EMPLOYEES_LOADING",
	SET_YEARS: "SET_YEARS",
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	SET_EMPLOYEES: "SET_EMPLOYEES",
	SET_EDITABLE_VACATION_INDEX: "SET_EDITABLE_VACATION_INDEX",
	SET_EDITABLE_VACATION: "SET_EDITABLE_VACATION",
	SET_EDITABLE_VACATION_START_DATE: "SET_EDITABLE_VACATION_START_DATE",
	SET_EDITABLE_VACATION_END_DATE: "SET_EDITABLE_VACATION_END_DATE",
	SET_EDITABLE_VACATION_PREVIOUS_VACATION_ID: "SET_EDITABLE_VACATION_PREVIOUS_VACATION_ID",
	RESET_EDITABLE_VACATION: "RESET_EDITABLE_VACATION",
	SET_EDITABLE_PLAN_ID: "SET_EDITABLE_PLAN_ID",
	SET_IS_WITHDRAWING: "SET_IS_WITHDRAWING",
	SET_IS_DRAFT_DELETING: "SET_IS_DRAFT_DELETING",
	SET_IS_SENDING_TO_REVIEW: "SET_IS_SENDING_TO_REVIEW",
	SET_IS_APPROVING: "SET_IS_APPROVING",
	SET_IS_DECLINING: "SET_IS_DECLINING",
	SET_IS_EXPORTING: "SET_IS_EXPORTING",
	SET_NEW_VACATIONS: "SET_NEW_VACATIONS",
	REMOVE_NEW_VACATIONS_ITEM: "REMOVE_NEW_VACATIONS_ITEM",
	ADD_NEW_VACATIONS_ITEM: "ADD_NEW_VACATIONS_ITEM",
	SET_PARENT_VACATIONS: "SET_PARENT_VACATIONS",
	SET_ACCEPT_PLAN_REQUEST: "SET_ACCEPT_PLAN_REQUEST",
	RESET_ACCEPT_PLAN_REQUEST: "RESET_ACCEPT_PLAN_REQUEST",
	SET_ACCEPT_PLAN_REQUEST_EMPLOYEE_ID: "SET_ACCEPT_PLAN_REQUEST_EMPLOYEE_ID",
	SET_ACCEPT_PLAN_REQUEST_PLAN_ID: "SET_ACCEPT_PLAN_REQUEST_PLAN_ID",
	SET_ACCEPT_PLAN_REQUEST_REASON: "SET_ACCEPT_PLAN_REQUEST_REASON",
	ADD_ITEMS_PLAN: "ADD_ITEMS_PLAN",
	SET_ITEMS_PLAN: "SET_ITEMS_PLAN",
	SET_IS_NEW_VACATION_CREATING: "SET_IS_NEW_VACATION_CREATING"
};

const vacationPlansTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default vacationPlansTypes;

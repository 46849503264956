<template>
	<v-container fluid
				 class="px-8 pb-6" 
				 :class="{'pt-2': title || $slots['title-append']}">
		<v-row class="mt-0 fill-height">
			<v-col>
				<div v-if="title || $slots['title-append']"
					 class="d-flex align-center mb-4">
					<span class="bar-title">{{ title || "" }}</span>
					<v-spacer></v-spacer>
					<slot name="title-append"></slot>
				</div>
				
				<slot></slot>
				
				<slot name="footer">
					<tranches-footer>
						<slot name="footer-pagination"></slot>
						<slot name="footer-actions"></slot>
					</tranches-footer>
				</slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TranchesBreadcrumbs from "@/components/common/TranchesBreadcrumbs.vue";
import TranchesFooter from "@/components/layouts/TranchesFooter.vue";
import { namespace } from "Store/bar/modules/breadcrumbs/types";

import { createNamespacedHelpers } from "vuex";

const tranchesBreadcrumbsHelpers = createNamespacedHelpers(namespace);

export default {
	props: {
		title: String
	},
	data() {
		return {
			namespace
		};
	},
	computed: {
		...tranchesBreadcrumbsHelpers.mapState({
			breadcrumbs: state => state.items
		})
	},
	components: { TranchesBreadcrumbs, TranchesFooter }
};
</script>

<style scoped lang="scss">
.bar-title {
	font-size: 18px;
	font-family: "Ubuntu", sans-serif;
}

.bar-main {
	margin-bottom: 52px;
}
</style>

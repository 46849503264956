import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import { Type } from "class-transformer";
import ApiCounterpartyBase from "@/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyType } from "@/types/CounterpartyType";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";

type Counterparty =
	ApiLegalEntityCounterparty
	| ApiPhysicalPersonCounterparty
	| ApiForeignOrganizationCounterparty
	| ApiLegalPersonCounterparty
	| ApiCounterpartyBase

export default class ApiCounterpartyPersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;

	@Type(() => ApiCounterpartyBase, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: ApiForeignOrganizationCounterparty, name: CounterpartyType.FOREIGN_ORGANIZATION },
				{ value: ApiLegalPersonCounterparty, name: CounterpartyType.LEGAL_PERSON },
				{ value: ApiPhysicalPersonCounterparty, name: CounterpartyType.PHYSICAL_PERSON },
				{ value: ApiLegalEntityCounterparty, name: CounterpartyType.LEGAL_ENTITY }
			]
		}
	})
	counterparty: Counterparty;


	constructor(id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		counterparty: Counterparty = new ApiCounterpartyBase())
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.counterparty = counterparty;
	}
}

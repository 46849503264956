import embeddedEmailModule from "@/store/shared/embeddedEmailForm";
import embeddedEmailFormTypes, { actionTypes, mutationTypes } from "@/store/shared/embeddedEmailForm/types";
import { ActionTree, MutationTree } from "vuex";
import EmbeddedEmailFormState from "@/store/shared/embeddedEmailForm/types/embeddedEmailFormState";
import { AccountController } from "@/api/account";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import AccountGeneralState from "@/store/modules/account/modules/general/types/accountGeneralState";
import { validateEmail } from "@/utils/validator";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import BatchService from "@/services/batchService";
import { abortService } from "@/store/shared/embeddedEmailForm";
import { EmbeddedEmailChangeModeType } from "@/store/shared/embeddedEmailForm/types/embeddedEmailChangeModeType";
import AccountState from "@/store/modules/account/types/accountState";

const accountController = new AccountController(abortService);

export const namespace = "email";

const checkUniquenessBatchService = new BatchService(({ interval: 500 }));

const actions = <ActionTree<EmbeddedEmailFormState, any>>{
	async [actionTypes.update]({ dispatch, commit, state, rootState }) {
		let { id: accountId } = resolveNestedState<AccountGeneralState>(rootState, storeManager.account.general.namespace);

		if(state.changeMode === EmbeddedEmailChangeModeType.CHANGE_EMAIL) {
			await accountController.updateAccountEmail(accountId, state.email);
		} else if(state.changeMode === EmbeddedEmailChangeModeType.CHANGE_CREDENTIALS) {
			await accountController.generateAndSendCredentials(accountId, state.email, state.email);
		}
	},
	async [actionTypes.checkIsEmailUnique]({ state, commit, rootState }) {
		if(!validateEmail(state.email)) {
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE, true);
			return;
		}

		try {
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS, true);

			let { id } = resolveNestedState<AccountState>(rootState, storeManager.account.namespace);

			let exists = null;

			if(id)
				exists = await accountController.checkEmailById(id, state.email);
			else
				exists = await accountController.checkEmail(state.email);

			commit(mutationTypes.SET_IS_EMAIL_UNIQUE, !exists);
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE_CHECK_IN_PROGRESS, false);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_EMAIL_UNIQUE, true);
		}
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.account.general.email.namespace, embeddedEmailFormTypes.mutationTypes.SET_EMAIL):
			{
				let emailState = resolveNestedState<EmbeddedEmailFormState>(state, storeManager.account.general.email.namespace);

				if(emailState.email === emailState.confirmedEmail)
					return;

				checkUniquenessBatchService.push(async () => {
					await dispatch(resolveAction(storeManager.account.general.email.namespace, actionTypes.checkIsEmailUnique));
				});
				break;
			}

			default:
				break;
		}
	});
};

const accountGeneralEmailModule = {
	namespace,
	...embeddedEmailModule,
	actions: {
		...embeddedEmailModule.actions,
		...actions
	},
	subscribe
};

export default accountGeneralEmailModule;

export default class ApiAccount {
	login: string;
	email: string;
	isActive: boolean;
	isEmailConfirmed: boolean;

	constructor(login = "", email = "", isActive = false, isEmailConfirmed = false) {
		this.login = login;
		this.email = email;
		this.isActive = isActive;
		this.isEmailConfirmed = isEmailConfirmed;
	}
}

<template>
	<v-container fluid class="ma-0 pa-0 px-md-8 px-3 d-flex justify-end align-end flex-column frp-alerts"
				 :style="$vuetify.breakpoint.lgAndUp ? `width: calc(100% - ${substractionWidth}px)` : ''">
		<frp-alerts-snackbar :text="alert.text"
							 @close="onCloseInfoAlert(alert.id)"
							 type="info"
							 icon="ico_info"
							 :class="{ 'mt-2': !index }"
							 :timeout="alert.timeout"
							 v-for="(alert, index) in infoAlerts"
							 :key="`info-${alert.id}`">
		</frp-alerts-snackbar>
		<frp-alerts-snackbar :text="alert.text"
							 @close="onCloseErrorAlert(alert.id)"
							 type="error"
							 icon="ico_error"
							 :class="{ 'mt-2': !index }"
							 :timeout="alert.timeout"
							 v-for="(alert, index) in errorAlerts"
							 :key="`error-${alert.id}`">
		</frp-alerts-snackbar>
	</v-container>
</template>

<script>
import FrpAlertsSnackbar from "Components/alerts/FrpAlertsSnackbar";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/modules/alerts";
import alertTypes from "Store/modules/alerts/types";

const { mapState, mapMutations } = createNamespacedHelpers(namespace);

export default {
	props: {
		substractionWidth: {
			type: String,
			default: "0"
		}
	},
	computed: {
		...mapState({
			errorAlerts: state => state.errorAlerts,
			infoAlerts: state => state.infoAlerts
		})
	},
	methods: {
		...mapMutations({
			onCloseErrorAlert: alertTypes.mutationTypes.REMOVE_ERROR_ALERT,
			onCloseInfoAlert: alertTypes.mutationTypes.REMOVE_INFO_ALERT
		})
	},
	components: {
		FrpAlertsSnackbar
	}
};
</script>

<style scoped lang="scss">
.frp-alerts {
  height: calc(100vh - 88px);
  mask-image: linear-gradient(to top, white 90%, transparent);
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 99999;
  row-gap: 8px;
  padding-bottom: 30px !important;
}
</style>

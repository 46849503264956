import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";

export class SnapshotStateBuilder {
	options: SnapshotOptions;

	constructor({ options }: { options: SnapshotOptions }) {
		this.options = options;
	}

	build() {
		return {
			...this.options
		};
	}
}

export enum PaymentOrderType {
	UNKNOWN = "Unknown",
	SCHEDULE_MAIN_DEBT_REPAYMENT = "ScheduledMainDebtRepayment",
	SCHEDULE_MAIN_DEBT_INTEREST_REPAYMENT = "ScheduledMainDebtInterestRepayment",
	OVERDUE_MAIN_DEBT_REPAYMENT = "OverdueMainDebtRepayment",
	OVERDUE_MAIN_DEBT_INTEREST_REPAYMENT = "OverdueMainDebtInterestRepayment",
	OVERDUE_MAIN_DEBT_PENALTY_REPAYMENT = "OverdueMainDebtPenaltyRepayment",
	OVERDUE_MAIN_DEBT_INTEREST_PENALTY_REPAYMENT = "OverdueMainDebtInterestPenaltyRepayment",
	LEGAL_PENALTY_REPAYMENT = "LegalPenaltyRepayment"
}

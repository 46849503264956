import { ApiManager, ApiManagerHelper } from "@/api/tranches/types/apiManager";
import { ApiAgreement } from "@/api/tranches/types/apiAgreement";
import { convertToTimestamp } from "@/utils/dates";

export interface Agreement {
	documentNumber: string;
	exactFrpSum: number;
	manager: ApiManager;
	signingDate: number;
	supervisor: ApiManager;
}

export class AgreementHelper {
	static map(source: ApiAgreement): Agreement {
		return {
			...source,
			signingDate: convertToTimestamp(source.signingDate) as number
		};
	}
	
	static getEmpty(): Agreement {
		return {
			documentNumber: "",
			exactFrpSum: 0,
			manager: ApiManagerHelper.getEmpty(),
			signingDate: 0,
			supervisor: ApiManagerHelper.getEmpty()
		};
	}
}
<template>
	<frp-card is-loading>
		<template #content>
			<tranches-content-layout>
				<v-row class="d-flex justify-space-between align-center mt-n6 ml-n4">
					<v-col cols="6" class="d-flex flex-column align-start pa-0">
						<frp-text-loader height="14" width="100%"/>
						<frp-text-loader class="mt-3" height="14" width="50%"/>
					</v-col>
					<v-col cols="auto" class="pa-0">
						<frp-btn-loader width="155" height="40"/>
					</v-col>
				</v-row>
			</tranches-content-layout>
		</template>
	</frp-card>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import TranchesContentLayout from "@/components/layouts/TranchesContentLayout.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: { FrpCard, TranchesContentLayout, FrpBtnLoader, FrpTextLoader }
};
</script>

<style scoped>

</style>

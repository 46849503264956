import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "bank-account-application-documents";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	selectableElectronicDocumentStatuses: "selectableElectronicDocumentStatuses",
	selectablePaperDocumentStatuses: "selectablePaperDocumentStatuses",
	hasAccounts: "hasAccounts"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	getDocuments: "getDocuments",
	updateDocumentStatus: "updateDocumentStatus",
	uploadDocumentFileInfo: "uploadDocumentFileInfo",
	downloadFile: "downloadFile",
	updateDocumentComment: "updateDocumentComment"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_IS_DOCUMENTS_LOADING: "SET_IS_DOCUMENTS_LOADING",
	SET_DOCUMENT_FILE_INFO_ITEMS: "SET_DOCUMENT_FILE_INFO_ITEMS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STORED_FILE_ID: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STORED_FILE_ID",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING",
	SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_COMMENT_CHANGING: "SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_COMMENT_CHANGING",
	RESET_DOCUMENT_FILE_META: "RESET_DOCUMENT_FILE_META",
	SET_DOCUMENT_FILE_META_IS_LOADING: "SET_DOCUMENT_FILE_META_IS_LOADING",
	SET_DOCUMENT_FILE_META: "SET_DOCUMENT_FILE_META",
	SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING: "SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING",
	SET_IS_SIGNED: "SET_IS_SIGNED",
	DOCUMENT_UPLOADED: "DOCUMENT_UPLOADED",
	DOCUMENT_STATUS_UPDATED: "DOCUMENT_STATUS_UPDATED"
};

const bankAccountApplicationDocumentsTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountApplicationDocumentsTypes;

<template>
	<div class="tranches-details-group d-flex flex-column justify-center">
		<slot></slot>
	</div>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>

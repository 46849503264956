export default class ApiFnsLegalPerson {
	inn: string;
	ogrn: string;
	opf: string;
	fullName: string;
	registrationDate: string;

	constructor(
		inn: string,
		ogrn: string,
		opf: string,
		fullName: string,
		registrationDate: string
	) {
		this.inn = inn;
		this.ogrn = ogrn;
		this.fullName = fullName;
		this.opf = opf;
		this.registrationDate = registrationDate;
	}
}

import baseMixinTypes from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "confirm-phone";

export const getterTypes = {
	...formTypes.getterTypes
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	confirmPhone: "confirmPhone",
	getNewCode: "getNewCode"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	SET_PHONE: "SET_PHONE",
	SET_MODE: "SET_MODE",
	SET_CODE: "SET_CODE",
	SET_CODE_ERROR: "SET_CODE_ERROR"
};

const confirmPhoneTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default confirmPhoneTypes;

import { resolveComponentNamespace } from "@/utils/vuexModules";

function isPlainObject(obj: object) {
	return Object.prototype.toString.call(obj) === "[object Object]";
}

export function getModuleState(state: any, ns: string) {
	return ns.split("/")
			 .reduce((s, p) => s[p], state);
}


export function mapInstanceState(mappers: any, getNamespace = resolveComponentNamespace) {
	if(Array.isArray(mappers)) {
		return mappers.reduce((acc, name) => Object.assign(acc, {
			// Note: Do not use an arrow function as we need to capture `this` at runtime
			[name]() {

				// @ts-ignore
				const moduleState = getModuleState(this.$store.state, getNamespace(this));
				return moduleState[name];
			}
		}), {});
	}

	// istanbul ignore else
	if(isPlainObject(mappers)) {
		return Object.entries(mappers)
					 .reduce((acc, [name, mapper]) => Object.assign(acc, {
						 // Note: Do not use an arrow function as we need to capture `this` at runtime
						 [name]() {
							 // @ts-ignore
							 const moduleState = getModuleState(this.$store.state, getNamespace(this));
							 // @ts-ignore
							 return mapper.call(this, moduleState);
						 }
					 }), {});
	}

	// istanbul ignore next
	console.warn("mapInstanceState requires an array or object as the second argument");
	// istanbul ignore next
	return {};
}

export function mapInstanceGetters(mappers: any, getNamespace = resolveComponentNamespace) {
	if(Array.isArray(mappers)) {
		// @ts-ignore
		return mappers.reduce((acc, name) => Object.assign(acc, {
			// Note: Do not use an arrow function as we need to capture `this` at runtime
			// @ts-ignore
			[name]() {
				// @ts-ignore
				return this.$store.getters[`${getNamespace(this)}/${name}`];
			}
		}), {});
	}

	// istanbul ignore else
	if(isPlainObject(mappers)) {
		return Object.entries(mappers)
					 .reduce((acc, [alias, name]) => Object.assign(acc, {
						 // Note: Do not use an arrow function as we need to capture `this` at runtime
						 // @ts-ignore
						 [alias]() {
							 // @ts-ignore
							 return this.$store.getters[`${getNamespace(this)}/${name}`];
						 }
					 }), {});
	}

	// istanbul ignore next
	console.warn("mapInstanceGetters requires an array or object as the second argument");
	// istanbul ignore next
	return {};
}


export function mapInstanceMutations(mutations: any, getNamespace = resolveComponentNamespace) {
	if(Array.isArray(mutations)) {
		return mutations.reduce((acc, name) => Object.assign(acc, {
			// @ts-ignore
			[name](payload) {
				// @ts-ignore
				return this.$store.commit(`${getNamespace(this)}/${name}`, payload);
			}
		}), {});
	}

	// istanbul ignore else
	if(isPlainObject(mutations)) {
		return Object.entries(mutations)
					 .reduce((acc, [alias, name]) => Object.assign(acc, {
						 // @ts-ignore
						 [alias](payload) {
							 // @ts-ignore
							 return this.$store.commit(`${getNamespace(this)}/${name}`, payload);
						 }
					 }), {});
	}

	// istanbul ignore next
	console.warn("mapInstanceMutations requires an array or object as the second argument");
	// istanbul ignore next
	return {};
}

export function mapInstanceActions(actions: any, getNamespace = resolveComponentNamespace) {
	if(Array.isArray(actions)) {
		return actions.reduce((acc, name) => Object.assign(acc, {
			// @ts-ignore
			[name](payload) {
				// @ts-ignore
				return this.$store.dispatch(`${getNamespace(this)}/${name}`, payload);
			}
		}), {});
	}

	// istanbul ignore else
	if(isPlainObject(actions)) {
		return Object.entries(actions)
					 .reduce((acc, [alias, name]) => Object.assign(acc, {
						 // @ts-ignore
						 [alias](payload) {
							 // @ts-ignore
							 return this.$store.dispatch(`${getNamespace(this)}/${name}`, payload);
						 }
					 }), {});
	}

	// istanbul ignore next
	console.warn("mapInstanceActions requires an array or object as the second argument");
	// istanbul ignore next
	return {};
}
